import   React                                 from 'react';

import   classNames                            from 'classnames';

import   styles                                from './leaflet_terms_of_service.module.scss';

import { Leaflet                            }  from '../leaflet/leaflet';
import { LeafletTermsOfService_Informations }  from '../legal_data/legal_data';

export const LeafletTermsOfService = () => {

  return (

        <>

          <div className={classNames(styles.root)}>

            <Leaflet block = {LeafletTermsOfService_Informations} />
        
          </div>
        
        </>

            
  );
};

export default LeafletTermsOfService;
