import   React                          ,  
       { useState                       ,
	     useEffect                      ,
		 useRef                         }  from 'react';

import { Link                           }  from 'react-router-dom';
import { useNavigate                   }  from 'react-router-dom';
		 
import { Dispatch                       }  from 'redux';
import { useDispatch                    }  from 'react-redux';

import   classNames                        from 'classnames';

import   styles                            from './product_thumbnail.module.scss';

import { BackgroundGradient             }  from "../../../../ui/background-gradient";

import { Product                        }  from '../../../../../datas/productManagment/actionTypes';

import { initCart                       , 
         addItem                        , 
         removeItem                     , 
         changeQuantity                 }  from '../../../../../datas/cartManagment/actionCreators';

import { initAuthentication             }  from '../../../../../datas/authenticationManagment/actionCreators';
import { initUser                       }  from '../../../../../datas/userManagment/actionCreators';

import { addItemToUserCart              ,
         removeItemFromUserCart         ,
         changeQuantityOfItemInUserCart }  from '../../../../../network/api_cart';
		 
		 
import { WWW_URLS                       , 
         INTERNAL_LINKS                 }  from '../../../../../network/urls';

import { isTokenValid                   }  from '../../../../../network/utils'; 

import { useTypedSelector               }  from '../../../../../datas/useTypeSelector';

import { Window                         }  from '../../../../widget/window/window';

import { postEvent                      }  from '../../../../../network/api_events_stats';


export interface ProductThumbnailProps {

  product:           Product,
  showSheet:         (product:Product) => void;
  showVariantChoice: (product:Product) => void;
  updateShop:        () => void;
    showAlertWindowDeprecatedToken : () => void;

}

export const ProductThumbnail = ({ product           ,
                                   showSheet         ,
								   showVariantChoice ,
								   updateShop        ,
								   showAlertWindowDeprecatedToken}: ProductThumbnailProps) => {

  const dispatch: Dispatch<any> = useDispatch();
  const navigate  = useNavigate();

  const { catalog        } = useTypedSelector ( (state) => state.productManagment );
  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
  const { cart           } = useTypedSelector ( (state) => state.cartManagment );
  const { user           } = useTypedSelector ( (state) => state.userManagment );

  const [ alertWindowVisible , setAlertWindowVisible ] = useState ( false );
  const [ version            , setVersion            ] = useState (   0   );

  let heightDiv:number = 0;
  let widthDiv:number  = 0;
  let topDiv:number    = 0;
  let leftDiv:number   = 0;
  let scrollY:number   = 0;
  
  useEffect(() => {

  }, [version]);
  
  const searchImage = (): string => {
  
    let imgFile = "";
	
	for ( let c = 0; c < product.characteristics.length; c++)
	 {
	  if ( product.characteristics[c].kind === "Photo" )
	   {
	    imgFile = product.characteristics[c].value;
		break;
	   }
	 }
	 
    if ( imgFile === "" )
     {
	  for ( let v = 0; v < product.variants.length; v++)
	   {
  	    for ( let c = 0; c < product.variants[v].characteristics.length; c++)
	     {
	      if ( product.variants[v].characteristics[c].kind === "Photo" )
	       {
	        imgFile = product.variants[v].characteristics[c].value;
		    break;
	       }
	     }
	  
        if ( imgFile !== "" )
         {
	      break;
	     }	  
	   }
     }
	 
    return imgFile
  }
  
  const urlImage = `${WWW_URLS.ProductThumbnail}${searchImage()}`;
  
  
  const addOneProductToCurrentCart = async () => {
  
    let deprecatedToken: boolean = false;
  
  	if ( authentication.login !== "" )
	 {
	  if ( isTokenValid ( authentication.token ) === false )
	   {
	    deprecatedToken = true
	    showAlertWindowDeprecatedToken ()
	   }
     }
  
    if ( !deprecatedToken )
	 {
      // Dans ce cas de figure, on suppose qu'il n'y a qu'un seul variant
 	  // Si la personne est connecté, on regarde s'il y a un prix associé à son statut
	 
	  const productCode: string        = product.code;
	 
	  if ( product.variants !== null && product.variants.length > 0 )
	        {
	         const productVariantCode: string = product.variants[0].productVariantCode;

  	         if ( product.variants[0].prices !== null && product.variants[0].prices.length > 0 )
	               {
	                const priceCode: string = user.status === "Premium" ? product.variants[0].prices[1].code : product.variants[0].prices[0].code;
	                const quantity: number  = getQuantity ( productCode , productVariantCode ) + 1;
	 
	                if ( quantity === 1 )
	                      {
	                       dispatch ( addItem ( productCode        ,
                                                productVariantCode ,
			   	  	                            priceCode          ,
                                                quantity           ) )
								 
			               if ( authentication.login !== "" )
			                {
			                 await addItemToUserCart ( authentication.login ,
			                                           authentication.token ,
					     		 		               cart.purchaseId      ,
			                                           productCode          ,
                                                       productVariantCode   ,
					                                   priceCode            ,
                                                       quantity             )
			                }
		                  }
  	                 else {
	                       dispatch ( changeQuantity ( productCode        ,
                                                       productVariantCode ,
			 	   	                                   quantity           ) )
										
   			               if ( authentication.login !== "" )
			                {
			                 await changeQuantityOfItemInUserCart ( authentication.login ,
			                                                        authentication.token ,
				     				             		            cart.purchaseId      ,
			                                                        productCode          ,
                                                                    productVariantCode   ,
					                                                quantity             )
			                }
		                  }
	               }
              else {
	                console.log ("PAS DE PRIX !!")
		           }
	        }
       else {
	         console.log ("PAS DE VARIANT !!")
		    }
			 
	  setAlertWindowVisible (true)
	
      updateShop ()
	 }
  }
  
  const removeOneProductToCurrentCart = async () => {
  
    let deprecatedToken: boolean = false;
  
  	if ( authentication.login !== "" )
	 {
	  if ( isTokenValid ( authentication.token ) === false )
	   {
	    deprecatedToken = true
	    showAlertWindowDeprecatedToken ()
	   }
     }
  
    if ( !deprecatedToken )
	 {
      // Dans ce cas de figure, on suppose qu'il n'y a qu'un seul variant
	  // Si la personne est connecté, on regarde s'il y a un prix associé à son statut
	 
	  const productCode: string        = product.code;
	  const productVariantCode: string = product.variants[0].productVariantCode;
	  const priceCode: string          = product.variants[0].prices[0].code;
	  const quantity: number           = getQuantity ( productCode , productVariantCode ) - 1;

	  if ( quantity <= 0 )
	        {
	         dispatch ( removeItem ( productCode        ,
                                     productVariantCode ) )
									
		 	 if ( authentication.login !== "" )
		 	  {
			   await removeItemFromUserCart ( authentication.login ,
			                                  authentication.token ,
				 						      cart.purchaseId      ,
			                                  productCode          ,
                                              productVariantCode   )
			  }
		    }
	   else {
	         dispatch ( changeQuantity ( productCode        ,
                                         productVariantCode ,
					                     quantity           ) )
										  
			 if ( authentication.login !== "" )
			  {
			   await changeQuantityOfItemInUserCart ( authentication.login ,
			                                          authentication.token ,
										              cart.purchaseId      ,
			                                          productCode          ,
                                                      productVariantCode   ,
					                                  quantity             )
			  }
		    }
	 }
  }


 
  const getQuantity = ( productCode:        string ,
	                    productVariantCode: string ): number => {
	
	let quantity: number = 0;
	
    for ( let i=0 ; i<cart.items.length; i++)
	 {
	  if ( cart.items[i].productCode === productCode && cart.items[i].productVariantCode === productVariantCode )
       {
	    quantity = cart.items[i].quantity
	   }	  
 	 }
	
	return quantity
  }

  const getPrice = ( productCode:        string ,
	                 productVariantCode: string ,
					 priceNumber:        number ):number => {
	
	let priceId: number = 0;
	
    // for ( let i=0 ; i<card.items; i++)
	 // {
	  // if ( card.items[i].productId === productId && card.items[i].productVariantId === productVariantId )
       // {
	    // priceId = card.items[i].quantity
	   // }	  
 	 // }
	
	return priceId
  }

  const handleClick = () => {

    let deprecatedToken: boolean = false;
  
  	if ( authentication.login !== "" )
	 {
	  if ( isTokenValid ( authentication.token ) === false )
	   {
	    deprecatedToken = true
	    showAlertWindowDeprecatedToken ()
	   }
     }
  
    if ( !deprecatedToken )
	 {
      const eventMessage: string = `Shop-ProductThumbnail-Button02:\"Ajouter au panier\":${product.name}`

      postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

      if ( product.variants.length === 1 )
            {	
             addOneProductToCurrentCart ()
            }
	   else {
             showVariantChoice ( product )
	        }
     }
  }

  const handleShowSheet = () => {
  
    let deprecatedToken: boolean = false;
  
  	if ( authentication.login !== "" )
	 {
	  if ( isTokenValid ( authentication.token ) === false )
	   {
	    deprecatedToken = true
	    showAlertWindowDeprecatedToken ()
	   }
     }
  
    if ( !deprecatedToken )
	 {
      const eventMessage: string = `Shop-ProductThumbnail-Button01:\"+infos\":${product.name}`

      postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

      showSheet ( product )
	 }
  }

  const closeAlertWindowWithCancelButton = () => {
  
    setAlertWindowVisible ( false );
  }

  const closeAlertWindowWithValidButton = () => {
  
  
    setAlertWindowVisible ( false );
	
    navigate ( `${INTERNAL_LINKS.Cart}`)
  }


  return (

          <>

              {
	           alertWindowVisible &&
	           ( < Window message        = { <><span className = {classNames(styles.bold_text)}>Votre produit a été ajouté à votre panier.</span><br/><br/>Voir le panier ?<br/></> }
                   messageType           = "normal"
                   onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                   onClickOnValidButton  = {closeAlertWindowWithValidButton}
                   cancelButtonName      = "NON"
                   validButtonName       = "OUI"   /> )
	          }
			  
            <div className = {classNames(styles.root)}>
		 
              <BackgroundGradient className="rounded-[22px] p-4 sm:p-5 bg-black dark:bg-zinc-900">
	  
	            <div className={classNames(styles.product_image)}>
                 
				  <img src       = { urlImage }
                       alt       = { urlImage }
                       className = "object-contain" />
					   
                </div>
				
	            <div className={classNames(styles.product_name)}>
                 {/*
  				  <p className="text-base sm:text-xl text-white mt-4 mb-2 dark:text-neutral-200 text-center">
                   {product.name} 
                  </p>
				  */}
				  <div className="text-base sm:text-xl text-white mt-4 mb-2 dark:text-neutral-200 text-center  font-bold">
				  <div dangerouslySetInnerHTML={{ __html: product.name }} /> 
				  </div>
                </div>

	            <div className={classNames(styles.product_name)}>
				  <div className="text-base sm:text-xl text-white mt-4 mb-2 dark:text-neutral-200 text-center">
				  <div dangerouslySetInnerHTML={{ __html: product.catchword }} /> 
				  </div>
                </div>
				
	            <div className={classNames(styles.infos)}>
			    <p className="text-base text-decoration-line: underline font-bold sm:text-xl text-white mt-4 mb-2 dark:text-neutral-200 text-center"
				   onClick={handleShowSheet}>
                  + d'infos
                </p>
               </div>
			   
	            <div className={classNames(styles.prices)}>

	             <div className={classNames(styles.line_1)}>
				  <div className={classNames(styles.normal_price)}>
				   { product.variants[0].prices.length > 0 ? `${(product.variants[0].prices[0].priceToPay/100).toFixed(2)} €`: "" }
	              </div>
	             </div>

                 {
				   product.variants[0].prices.length > 0
				   &&
				   (
				    <>
	                  <div className={classNames(styles.line_2)}>
				       <div className={classNames(styles.text)}>
				        Avec abonnement premium : { `${(product.variants[0].prices[1].priceToPay/100).toFixed(2)} €` }
  	                   </div>
	                  </div>
					  <br/>
					</>  
                    )
			     }
				
	            </div>
		
		        { 
				  product.variants[0].prices.length > 0
				  &&
				  (
		           <div className={classNames(styles.btn)}>
           
		             <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
		                     onClick   = {handleClick} >
			 
  			           <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                       <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[#FFFF00] px-3 py-1 text-xl font-medium text-black backdrop-blur-3xl">
                         Ajouter au panier
                       </span>
          
    		         </button>
          
		           </div>
				  )
				}
      
	          </BackgroundGradient>		 
		 
            </div>

          </>
           
         );

};


export default ProductThumbnail;
