import   React                       , 
       { useState                    }  from 'react';

import   classNames                     from 'classnames';

import   styles                         from './forgotten_password.module.scss';

import { Trigger                     }  from '../../../../widget/trigger/trigger';
import { Field                       }  from '../../../../widget/field/field';
import { WidgetFrame                 }  from '../../../../widget/widget_frame/widget_frame';
import { Validation                  ,
         checkEmail                  }  from '../../../../../datas/authenticationManagment/validators';
		 
import { getUserPasswordResetRequest }  from '../../../../../network/api_user';
		 
export interface WindowForgottenPasswordProps {
  onClickOnCloseButton: (() => void)
}

export const WindowForgottenPassword = ({ onClickOnCloseButton }: WindowForgottenPasswordProps) => {

  const [ email            , setEmail            ] = useState('');
  const [ messageEmail     , setMessageEmail     ] = useState ('');
  
  const [ showMessageError , setShowMessageError ] = useState(false);
  const [ showMessageAck   , setShowMessageAck   ] = useState(false);
  
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
	
	const retour: Validation = checkEmail  ( e.target.value ) 
	
	setMessageEmail ( retour.comment );
  };

  const handleClickAccept = () => {
  
	const retour: Validation = checkEmail  ( email ) 

	setMessageEmail ( retour.comment );

    if ( retour.code === 0 )
     {    
      makePasswordResetRequest (email);
	 }
  };

  const handleClickRefuse = () => {

    onClickOnCloseButton();
  };

  const handleClickClose = () => {

    onClickOnCloseButton();
  };

  const makePasswordResetRequest = async (email: string) => {

    //const response = 
	
	await getUserPasswordResetRequest ( email )
		
    // Quelque soit le cas de figure, on dit qu'on envoie qqch
	
    setShowMessageAck   ( true  );
	setShowMessageError ( false );
	
    /*
					
	switch ( response.code)
     {
	  case  1 : setShowMessageAck   ( true  );
	            setShowMessageError ( false );
			    break;
			   
	  case -1 : // Même si l'appel de l'API a échoué car le mail n'existe pas, on
	            // fait croire que c'est ok pour ne pas donner d'indice aux pirates
	  
	            setShowMessageAck   ( true  );
	            setShowMessageError ( false );
			    break;

	  case -2 : setShowMessageAck   ( false );
	            setShowMessageError ( true  );
			    break;
     }
	 
	*/
  }
  
  return (
           <>
			
            <div className={classNames(styles.window)}>
			  
			  <p  className={classNames(styles.text)}>Veuillez saisir votre adresse e-mail</p>
			
			  <br/>
			
  	          <WidgetFrame name               = "Adresse E-mail"
                           error              = {messageEmail}
                           labelTextPosition  = "left"
						   labelBlockPosition = "top"  
						   classNameName      = {classNames(styles.text2)} >

			    <Field type           = "email"
                       placeholder    = ""
                       value          = {email}
                       onChange       = {handleEmailChange} />              
                         
              </WidgetFrame>
			   
			  <br/>
			   
              { 
                ( showMessageAck ) 
				
				?
				
                ( 
				  <div  className   = {classNames(styles.block_buttons)}>
				  
                  <Trigger name            = "Fermer" 
                           onClick         = {handleClickClose}
                           classNameNormal = {classNames(styles.button)}/>

				   <br/>
                   <p className   = {classNames(styles.message_ack)}>
                    Si votre adresse e-mail est référencée, vous recevrez un message pour modifier votre mot de passe.
                   </p>
                  

                  </div>				  
				)
                
				:
				
				(
				  <div  className   = {classNames(styles.block_buttons)}>

                  <Trigger name            = "Annuler" 
                           onClick         = {handleClickRefuse}
                           classNameNormal = {classNames(styles.button)}/>

                  <Trigger name            = "Envoyer" 
                           onClick         = {handleClickAccept}
                           classNameNormal = {classNames(styles.button)}/>
				
                  </div>				  
				)
				
              }

              { 
                ( showMessageError ) && 
                ( 
				  <div>
                   <p className = {classNames(styles.message_error)}>
                    Le serveur a rencontré une erreur interne lors de votre demande de réinitialisation.
                   </p>
                  </div> 
				)  
              }
					   
            </div>

		   </>
         );
};

export default WindowForgottenPassword;
