import    React    ,
       {  useState , useEffect}  from "react";
		 

export interface ToggleProps {
  checked?:        boolean | undefined;                     // disponible ou non
  readOnly?:       boolean | undefined;                     // Editable ou non
  id?:             number  | undefined
  onChange?:       (newState: boolean,
                    id?: number | undefined) => void | undefined; 
  form?:           string;
}

export const Toggle = ({ checked  ,
                         readOnly ,
                         onChange ,
                         form     ,
						 id       }: ToggleProps ) => {

  const [ state , setState  ] = useState ( checked );

  useEffect(() => {
  
    setState(checked);
	
  }, [checked,state]); 
  const handleclick = () => {
  
   if (   ( typeof ( readOnly ) === "undefined" ) ||
        ( ( typeof ( readOnly ) !== "undefined" ) && ( readOnly === false ) ) )
    {
     if ( typeof ( onChange ) !== "undefined" )
      {
	   onChange ( !state , id );
	  }

     setState ( !state );
    }    
  }
  
  const renderInput = (): JSX.Element => {
  
         if ( typeof (form) === "undefined" || form === "checkbox" )
		  {
		   return <input type     = "checkbox" 
		                 checked  = {state}
                         onChange = {handleclick}/>
		  }
	
	else if ( form === "radio button" )
	      {
		   return <input type     = "radio" 
		                 checked  = {state}
                         onChange = {handleclick}/>
		  }

	else if ( form === "switch" )
	      {
		    if ( state ) 
                  {            
                   return <svg fill    = "#00FF00" 
					     	   width   = "30px" 
						       height  = "30px" 
						       viewBox = "0 0 24 24" 
						       onClick = {handleclick} 
						       xmlns   = "http://www.w3.org/2000/svg" >
						  
						    <path d="m24 11.617c0 3.763-3.05 6.814-6.813 6.817h-10.371c-3.765 0-6.817-3.052-6.817-6.817s3.052-6.817 6.817-6.817h10.366c3.763 0 6.814 3.05 6.817 6.813zm-6.817-4.545c-2.507.001-4.54 2.034-4.54 4.542s2.033 4.542 4.542 4.542 4.542-2.033 4.542-4.542c0-1.252-.507-2.386-1.327-3.208-.822-.824-1.959-1.334-3.215-1.334-.001 0-.001 0-.002 0z"/>

					      </svg>
				  }
			 else {
                   return <svg fill    = "#FF0000" 
						       width   = "30px" 
							   height  = "30px" 
							   viewBox = "0 0 24 24" 
							   onClick = {handleclick} 
							   xmlns   = "http://www.w3.org/2000/svg" >
							   
							<path d="m0 11.617c0-3.763 3.05-6.814 6.813-6.817h10.371c3.765 0 6.817 3.052 6.817 6.817s-3.052 6.817-6.817 6.817h-10.366c-3.763 0-6.814-3.05-6.817-6.813zm6.817 4.543c2.507-.002 4.539-2.035 4.539-4.542 0-2.509-2.034-4.542-4.542-4.542s-4.542 2.034-4.542 4.542c0 1.254.508 2.389 1.329 3.21.822.823 1.958 1.332 3.214 1.332h.003z"/>
							
						  </svg>
				  }
		  }
	
	else  {
	       return <></>;
	      }
  };
  
  return (
  
          <>
 
           { renderInput () }
		   
          </>
  );

};

export default Toggle;
