import   React            ,
       { useState         ,
	     useEffect        }   from 'react';

import { useNavigate      }  from 'react-router-dom';

import { Dispatch                }  from 'redux';
import { useDispatch             }  from 'react-redux';

import   classNames          from 'classnames';
 
import   styles              from './account_creation.module.scss';

import { GlobalMenu       }  from '../../menu/global/global';
import { Footer           }  from '../../menu/footer/footer';
import { TopShifter       }  from '../../menu/top_shifter/top_shifter';
import { CookiesManager   }  from '../../cookie/manager';

import { Registration     }  from './blocs/registration/registration';
import { OfferChoice      }  from './blocs/offer_choice/offer_choice';

import { Tab2                }  from '../../widget/tab/tab2';
import { Panel               }  from '../../widget/tab/panel';
import { Window              }  from '../../widget/window/window';
import { AnimatedWaitingIcon }  from '../../widget/animated_waiting_icon/animated_waiting_icon';


import { useTypedSelector }  from '../../../datas/useTypeSelector';

import { postEvent        }  from '../../../network/api_events_stats';

import { postUser         ,
         postUserAuthentication ,         
		 getUserInfos           ,
		 patchUserDiscoverOfferChoiceAtCreation }  from '../../../network/api_user';

import { setLogin                , 
         setToken                ,
		 initAuthentication      }  from '../../../datas/authenticationManagment/actionCreators';

import { updateEmail            , 
         updatePassword         , 
         updatePassword2        , 
         updateFirstname        , 
         updateLastname         , 
         updateSex              , 
         updateBirthday         , 
         updateRgpdConsent      ,
		 updateStatus           ,
		 updateId               ,
		 setUser                ,
		 initUser               }  from '../../../datas/userManagment/actionCreators';
		 

		 
import { INTERNAL_LINKS   }  from '../../../network/urls';

export interface AccountCreationProps {

  withOfferChoice: boolean
}

export const AccountCreation = ({ withOfferChoice }: AccountCreationProps) => {

  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch()

  const { user } = useTypedSelector((state) => state.userManagment);

  const [ activePanelNumber , setActivePanelNumber  ] = useState ( 0 );
  const [ showWindow        , setShowWindow         ] = useState ( false );
  const [ windowMessage     , setWindowMessage      ] = useState ( <></> );
  const [ showWaitingIcon   , setShowWaitingIcon    ] = useState ( false );

  let login: string   = ""
  let token: string   = ""

  useEffect(() => {

     window.scroll(0, 0);

  }, [activePanelNumber] ); 

  useEffect(() => {

    postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , "" );
	 
	window.scroll(0, 0);
	
  }, [] );


 const handleStateOfRegistrationPanel = (state:number) => {
 
   const eventMessage: string = "Button:\"Suivant\":withOfferChoice=" + (withOfferChoice?"true":"false") 
                                + ":user=" + user.email     + "," 
								           + user.firstname + "," 
										   + user.lastname  + "," 
										   + user.sex       + "," 
										   + user.birthday

   postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , "" )

   if ( state === 1 )
    {
	 if ( withOfferChoice )
	       {
		    setActivePanelNumber (1);
		   }
	  else {
	        makeDiscoverOfferChoice ()
	       }
	}
 }
  
  /*
  const finalizeRegistration = async () => {
  
    const code = await makeRegistration ()
	
	if ( code === 1 )
	      {
           navigate (`${INTERNAL_LINKS.AccountCreationSuccess}`);
		  }
	 else {
	 	   setWindowMessage ( <>Problème lors de la création du compte</> );
           setShowWindow    ( true );
	      }
  }
  
  /*
  const makeRegistration = async (): Promise<number> => {
 
      setShowWaitingIcon (true);
	 
      const response = await postUser ( user.email     ,
                                        user.password  ,
								        user.firstname ,
										user.lastname  ,
										user.sex       ,
										user.birthday  )

 
	  setShowWaitingIcon (false);
			
	 return response.code;
   }  
  */



  const makeDiscoverOfferChoice = async () => {
  
   const user_id = await makeRegistration ()
  
  					  console.log ("makeDiscoverOfferChoice ", user_id, login, token)

   const result = await patchUserDiscoverOfferChoiceAtCreation ( token   ,
                                                                 user_id )

  					  console.log ("makeDiscoverOfferChoice ", result)

   if ( result.code === 1 )
         {
          dispatch ( initUser   () );
          dispatch ( initAuthentication (true) );

          navigate ( `${INTERNAL_LINKS.AccountCreationSuccess}` );
	     }
	else {
 	      setWindowMessage ( <>Problème lors du passage en mode Découverte du compte</> );
          setShowWindow    ( true );
	     }
  }


  const makeRegistration = async (): Promise<number> => {
 
   let user_id: number = 0

      setShowWaitingIcon (true);
	 
      const response01 = await postUser ( user.email     ,
                                          user.password  ,
										  user.firstname ,
										  user.lastname  ,
										  user.sex       ,
										  user.birthday  )

      console.log (response01)

      if ( response01.code === 1 )
	        {
             // Le compte a pu être créé, on va se connecter automatiquement dessus afin de continuer le processus d'abonnement
             // avec un token JWT (car la création d'un paiment est à accès restreint)

             const authenticationResult = await postUserAuthentication ( user.email    ,
                                                                         user.password )


      console.log (authenticationResult)

	         if ( authenticationResult.code === 1 )
                   {
				    token = authenticationResult.token
				    login = user.email
					
                    dispatch ( setLogin ( user.email                 ) );
                    dispatch ( setToken ( authenticationResult.token ) );
				
				    const userInfos = await getUserInfos ( user.email ,
                                                           authenticationResult.token )
														   
      console.log (userInfos)

					if ( userInfos.code === 1 )
                     {		
                      user_id = userInfos.infos.id
					 
                      dispatch ( updateId ( userInfos.infos.id ) )
					  
					  console.log ("C'est ok ", user_id, login, token)
                     }

                   }
              else {
	                setShowWaitingIcon (false);
			 
                    setWindowMessage ( <>Problème lors de la connexion automatique au nouveau compte</> );
                    setShowWindow    ( true );
                   }
	        }
	   else {
	         setShowWaitingIcon (false);

		     setWindowMessage ( <>Problème lors de la création du compte</> );
             setShowWindow    ( true );
	        }
			
	 return user_id;
   }

  
  const handleCloseButtonClick = () => {

    setWindowMessage (<></>);
    setShowWindow	 (false)

//	  dispatch ( initUser   () );
//	  dispatch ( initAuthentication (true) );
//      navigate ( `${INTERNAL_LINKS.Home}` );
  }
  
 return (
 
   <>

    <GlobalMenu/>
 	<CookiesManager />
    <TopShifter />

    <div className={classNames(styles.root)}>
	
	    { ( showWaitingIcon ) && ( <AnimatedWaitingIcon/>) }

        {
		 showWindow
		 &&
         (
		  < Window message               = { windowMessage          }
			       onClickOnCloseButton  = { handleCloseButtonClick }
			       opaqueBackgroundColor = "#333333"/>
		 )
		}

     <div className={classNames(styles.contain)}>
      <Tab2 activePanelNumber = {activePanelNumber} >
       <Panel name="Création de mon compte"><Registration getState        = { handleStateOfRegistrationPanel }
	                                                      withOfferChoice = { withOfferChoice                } /></Panel>
       <Panel name="Choix de l'offre"      ><OfferChoice                                                           /></Panel>
      </Tab2>
     </div>
    </div>

    <Footer />

   </>
 
  );
};

export default AccountCreation;

