import   React                       ,  
       { useState                    ,
	     useEffect                   }  from 'react';
		 
import { useNavigate                 }  from 'react-router-dom';
		 
import { Dispatch                    }  from 'redux';
import { useDispatch                 }  from 'react-redux';
		 
import   classNames                     from 'classnames';

import   styles                         from './account_closure.module.scss';

import { Window                      }  from '../../../../widget/window/window';
import { WidgetFrame                 }  from '../../../../widget/widget_frame/widget_frame';

import { useTypedSelector            }  from '../../../../../datas/useTypeSelector';

import { patchUserAccountClosure     }  from '../../../../../network/api_user';

import { initAuthentication          }  from '../../../../../datas/authenticationManagment/actionCreators';
import { initUser                    }  from '../../../../../datas/userManagment/actionCreators';

import { INTERNAL_LINKS              }  from '../../../../../network/urls';

export const AccountClosure = () => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
  const { user           } = useTypedSelector ( (state) => state.userManagment );

  const [ showWindow     , setShowWindow     ] = useState ( false );
  const [ windowMessage  , setWindowMessage  ] = useState ( <></> );

  const [ showWindow2    , setShowWindow2    ] = useState ( false );
  const [ windowMessage2 , setWindowMessage2 ] = useState ( <></> );

  const [ reinitNeed     , setReinitNeed     ] = useState ( false );

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  const switchWidthLimit = 870;

  const handleResize = () => {
    setIsSmallScreen ( window.innerWidth <= switchWidthLimit );
  };

  useEffect(() => {

    if ( authentication.login === "" || 
         authentication.token === "" )
     {
	  dispatch ( initUser () )

      navigate (`${INTERNAL_LINKS.Login}`);
     }

  }, []);

  
  const makeAccountClosure = async () => {
 

    const response = await patchUserAccountClosure ( authentication.token ,
	                                                 user.id              )
													  
	switch ( response.code )
     {	
	  case 1    : setWindowMessage2 ( <>La demande a été envoyée, vous allez recevoir un message confirmant la fermeture de votre compte.</>);
	              setShowWindow2    (true)
	     
  	              setReinitNeed (true);

                  console.log ("scroll")

                  window.scrollTo({
                    top: document.body.scrollHeight / 2
                  }); 

		          break;
			   
	  case -102 : setWindowMessage2 ( <>Votre session a expiré, veuillez vous reconnecter.</>);
	              setShowWindow2    (true)

  	              setReinitNeed (true);
	     
		          break;
				  
	  default :   setWindowMessage2 ( <>Une erreur réseau s'est produite lors de votre demande</> );
	              setShowWindow2   (true)
	 }

   }

  const handleAccountClosureButtonClick = () => {

    setWindowMessage (<>Etes-vous sûr(e) de vouloir fermer votre compte</>);
	setShowWindow    (true)
	
    console.log ("scroll A ", (document.body.scrollHeight / 2) )

    window.scrollTo({
      top: (document.body.scrollHeight / 2) - 400
    }); 
  }

  const handleValidButtonClick = () => {

    setWindowMessage (<></>)
	setShowWindow    (false)

    makeAccountClosure ()
  }

  const handleCancelButtonClick = () => {

    setWindowMessage (<></>)
	setShowWindow    (false)
  }

  const handleCloseButtonClick2 = () => {

    setWindowMessage2 (<></>)
	setShowWindow2    (false)

    if ( reinitNeed )
     {
	  dispatch ( initUser   () );
	  dispatch ( initAuthentication (true) );
      navigate ( `${INTERNAL_LINKS.Home}` );
     }
  }


  return (
            
           <div className = { classNames(styles.root) } >

             {
			    showWindow  &&
                (
				  < Window message               = { windowMessage           }
				           onClickOnValidButton  = { handleValidButtonClick  }
				           onClickOnCancelButton = { handleCancelButtonClick } />
				)				
		     }
			   
             {
			    showWindow2  &&
                (
				  < Window message              = { windowMessage2           }
				           onClickOnCloseButton = { handleCloseButtonClick2 } />
				)
		     }

		     <p className = { classNames(styles.title) } >
               FERMETURE DU COMPTE
             </p>

			 <br/>

             <div className={classNames(styles.button)}>

				<button className = "px-6 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                        onClick   = {handleAccountClosureButtonClick}>
                   Demander la fermeture du compte
                </button>

             </div>

           </div>
           
         );
};


export default AccountClosure;
