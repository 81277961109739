import   axios                       from 'axios';

import { getUserAgentInformations ,
         getAppInformations       }  from './utils';	

import { API_URLS                 }  from './urls';

export interface PostRgpdConsentLogResult {
  code:    number,
  comment: string
}

export const postRgpdConsentLog = async ( allCookiesConsent:        number ,
                                          functionalCookiesConsent: number ,
                                          analysisCookiesConsent:   number ,
                                          marketingCookiesConsent:  number ): Promise<PostRgpdConsentLogResult> => {

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();
	
    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json',
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };
	
	const allCookiesConsentStr        = `${allCookiesConsent}`;
	const functionalCookiesConsentStr = `${functionalCookiesConsent}`;
	const analysisCookiesConsentStr   = `${analysisCookiesConsent}`;
	const marketingCookiesConsentStr  = `${marketingCookiesConsent}`;
	
    console.log ( "postRgpdConsentLog, on envoie " );
	console.log ( " - allCookiesConsentStr        : ", allCookiesConsentStr );
    console.log ( " - functionalCookiesConsentStr : ", functionalCookiesConsentStr );
	console.log ( " - analysisCookiesConsentStr   : ", analysisCookiesConsentStr );
	console.log ( " - marketingCookiesConsentStr  : ", marketingCookiesConsentStr );

	
	var result:PostRgpdConsentLogResult = {
      code:    0,
      comment: "",
    };
		
    try {

        const response = await axios.post (
        
                                            `${API_URLS.postRgpdConsentLog}`,
                              
                                            { 
                                              allCookiesConsentStr        ,
											  functionalCookiesConsentStr ,
											  analysisCookiesConsentStr   ,
                                              marketingCookiesConsentStr                 											 
                                            },
                                            
                                            {
                                              headers: headers
                                            }
                                          );

        console.log ( "postRgpdConsentLog " , response );

		if ( response.status === 201 )
		      { 
			   result = {
		         code:            1 ,
		         comment: "Informations de consentement RGPD créées" ,
		       };
			  }
		 else {
			   result = {
		         code:     -101 ,
		         comment: `Erreur lors de la création des informations de consentement RGPD ${response.status}`,
		       };
			  }

    } 
    catch (error) {

        console.log ( "postRgpdConsentLog " , error );

        result = {
		  code:    -201,
		  comment: "Erreur réseau",
		}; 
    }

	return result;
};
