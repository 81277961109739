import   React        from 'react';
	   
import   classNames   from 'classnames';

import   styles       from './top_shifter.module.scss';

import '../../../config.module.scss';

export const TopShifter = () => {

/*
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  const element: HTMLElement | null = document.getElementById('globalMenu');
  const elementStyle                = element !== null ? window.getComputedStyle(element) : null;
  const menuWidthLimitStr           = elementStyle !== null ? elementStyle.getPropertyValue('--menuWidthLimit') : "10000";
  const menuWidthLimit              = 1050; // parseInt( menuWidthLimitStr );

/*
  useEffect(() => {

    const handleResize = () => {
      setIsSmallScreen ( window.innerWidth <= menuWidthLimit );
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
	
  }, [isSmallScreen]); 

  
  return (
           <>
                  { 
                    ( 
                      isSmallScreen 
                    ) 
                    ?
                    ( 
                      <div className={classNames(styles.top_shifter_whithout_header)}>
                      </div>
                    )  
                    :
                    ( 
                      <div className={classNames(styles.top_shifter_whith_header)}>
                      </div>
                    ) 
                  }
		   </>
          
         );
*/

  return (
           <>
                      <div className={classNames(styles.top_shifter_whith_header)}>
                      </div>
		   </>
          
         );	 
};

export default TopShifter;
