import   React        from 'react';
import   classNames   from 'classnames';

import   styles       from './window.module.scss';

export interface WindowProps {
  message:                 JSX.Element
  messageType?:            string
  onClickOnCloseButton?:   (() => void) | undefined;
  onClickOnCancelButton?:  (() => void) | undefined;
  onClickOnValidButton?:   (() => void) | undefined; 
  closeButtonName?:        string;
  cancelButtonName?:       string;
  validButtonName?:        string;
  opaqueBackgroundColor?:  string;  // Nouvelle propriété
}

export const Window = ({
  message,
  messageType,
  onClickOnCloseButton,
  onClickOnCancelButton,
  onClickOnValidButton,
  closeButtonName,
  cancelButtonName,
  validButtonName,
  opaqueBackgroundColor // Nouvelle propriété
}: WindowProps) => {

  const handleCloseButtonClick = () => {
    if (typeof onClickOnCloseButton !== "undefined") {
      onClickOnCloseButton();
    }
  };

  const handleCancelButtonClick = () => {
    if (typeof onClickOnCancelButton !== "undefined") {
      onClickOnCancelButton();
    }
  };

  const handleValidButtonClick = () => {
    if (typeof onClickOnValidButton !== "undefined") {
      onClickOnValidButton();
    }
  };

  const generateClassName = (): string => {
    if (typeof messageType === "undefined") {
      return classNames(styles.whiteMessage);
    } else if (messageType === "normal") {
      return classNames(styles.whiteMessage);
    } else if (messageType === "alert") {
      return classNames(styles.redMessage);
    } else if (messageType === "ack") {
      return classNames(styles.greenMessage);
    } else {
      return classNames(styles.whiteMessage);
    }
  };

  const windowStyle = opaqueBackgroundColor
    ? { backgroundColor: opaqueBackgroundColor }
    : {};

  return (
    <div
      className={classNames(styles.window)}
      style={windowStyle} // Application de la couleur de fond
    >
      <div className={classNames(generateClassName(), styles.message)}>
        {message}
      </div>

      <br />

      <div className={classNames(styles.buttons_block)}>
        {typeof onClickOnCloseButton !== "undefined" && (
          <button
            className="mx-5 px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
            onClick={handleCloseButtonClick}
          >
            {typeof closeButtonName === "undefined" ? "Fermer" : closeButtonName}
          </button>
        )}

        {typeof onClickOnCancelButton !== "undefined" && (
          <button
            className="mx-5 px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
            onClick={handleCancelButtonClick}
          >
            {typeof cancelButtonName === "undefined" ? "Annuler" : cancelButtonName}
          </button>
        )}

        {typeof onClickOnValidButton !== "undefined" && (
          <button
            className="mx-5 px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
            onClick={handleValidButtonClick}
          >
            {typeof validButtonName === "undefined" ? "Valider" : validButtonName}
          </button>
        )}
      </div>
    </div>
  );
};

export default Window;
