import { Link       } from 'react-router-dom';

import   classNames   from 'classnames';

import   styles       from './legal_data.module.scss';


interface Section
 {
  title:   JSX.Element ,
  content: JSX.Element
 }
 
export interface Block
 {
  title:        JSX.Element,
  introduction: JSX.Element,
  sections:     Section [],
  lastPart:     JSX.Element
 }


export const LeafletTermsOfService_Informations: Block = 
 {
  title: 
  <>
  CGU – Conditions générales d’utilisation
  </> ,
  introduction: 
  <>
  </> ,
  sections: 
  [
   {
    title:   
    <>
     1. Application
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Le présent site Web (ci-après « le Site Web ») est mis à disposition 
      par la société Lovelight Factory SARL (ci-après «Lovelight Factory »). 
      N'hésitez pas à parcourir ce document. L'accès au site et son utilisation 
      sont soumis aux Conditions d'utilisation répertoriées ci-dessous, ainsi 
      qu'à toute loi pertinente applicable. La présente page expose également 
      quelques informations légales supplémentaires. En accédant au Site Web et/ou 
      en l'utilisant, vous vous engagez à vous conformer aux présentes Conditions 
      d'utilisation. Veuillez lire attentivement ces Conditions d'utilisation. 
      Les données personnelles que vous pourriez être amené à nous communiquer via 
      le Site Web seront traitées conformément à notre Politique de confidentialité.
     </p>
    </>
   },
   {
    title:   
    <>
     2. Restrictions d’utilisation afférentes aux droits de propriété intellectuelle
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Le Site Web contient de nombreux éléments qui sont protégés par les droits 
      d'auteur européens et internationaux et les autres droits intellectuels de la 
      propriété, tels que (mais sans s'y limiter) les marques commerciales, les noms 
      commerciaux, les logos, les photographies, les vidéos, la musique, les textes 
      et la conception graphique du Site Web de Lovelight Factory (dénommés ci-après 
      le Contenu). Sans aller à l'encontre des éventuelles restrictions découlant de 
      la législation en vigueur, les restrictions d'utilisation du Site Web suivantes 
      sont appliquées pour protéger certains droits :

      <ul>
       <li>
        Aucune partie du Site Web ne peut être copiée électroniquement ou par un 
        autre procédé, sauf dans la mesure où cela s'avère nécessaire pour le 
        consulter via Internet et utiliser ses services de manière efficace. La 
        reproduction de toute partie du Site Web sur un autre site est expressément 
        interdite.
       </li>
       <li>
        Les marques commerciales, les noms commerciaux, les marques de service et 
        les logos de Lovelight Factory tels qu'utilisés sur le Site Web ne peuvent 
        en aucun cas être affichés ou utilisés sans le consentement préalable écrit 
        de Lovelight Factory.
       </li>
       <li>
        Lovelight Factory vous autorise à utiliser le Site Web et son Contenu pour 
        votre usage personnel uniquement, à condition que vous conserviez tel quel 
        l'ensemble des mentions de copyright et de propriété, et interdit toute 
        utilisation à des fins commerciales.
       </li>
       <li>
        En principe, des liens hypertextes pointant vers le Site Web peuvent être 
        créés, à condition qu'ils pointent vers la page d'accueil du présent Site Web. 
        La création de liens hypertextes profonds ou de liens d'insertion (« in-line links ») 
        vers d'autres parties du Site Web est interdite, sauf en cas d'accord de Lovelight Factory.</li>
       <li>
        La consultation du Site Web par des moyens automatisés (avec des « robots » 
        par exemple) afin d'y récupérer des informations de manière systématique ou 
        de créer des liens fonctionnels entre le Contenu du Site Web et un autre 
        site n'est pas autorisée.
       </li>
       <li>
        La définition d'un lien hypertexte de sorte qu'un internaute voie le Site 
        Web dans le cadre d'un autre site est interdite.
       </li>
      </ul>
     </p>
    </>
   },
   {
    title:   
    <>
     3. Marques commerciales
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      « Lovelight Factory » est une marque commerciale française déposée de Lovelight 
      Factory SARL . Les autres marques commerciales, logos et marques de service 
      figurant sur le Site Web peuvent être des marques, déposées ou non, de Lovelight 
      Factory.
     </p>
    </>
   },
   {
    title:   
    <>
     4. Utilisation non acceptable
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Est interdite toute utilisation du Site Web ou de son Contenu pouvant gêner 
      les autres utilisateurs, enfreindre leurs droits, entraver de quelque manière 
      que ce soit le bon fonctionnement du Site Web ou avoir un effet défavorable 
      sur les informations présentées sur le Site Web et/ou sur le logiciel 
      contrôlant son fonctionnement. L'utilisation du site Web sous un faux nom ou 
      par le contournement de toute mesure de sécurité mise en place pour les parties 
      du Site Web dont l'accès est restreint n'est pas autorisée. Est interdite toute 
      utilisation du Site Web ou de son Contenu pouvant raisonnablement être 
      considérée comme constituant ou encourageant un comportement qui pourrait être 
      qualifié de délit pénal, donnant lieu à des poursuites civiles ou s'avérant de 
      quelque manière que ce soit contraire à la législation d'un pays.
     </p>
    </>
   },
   {
    title:   
    <>
     5. Liens vers des sites web tiers
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Le présent Site Web peut contenir des liens hypertextes vers d'autres sites Web 
      hors du domaine [http://lovelight.tv/]. D'autres conditions d'utilisation, 
      indiquées dans les pages consultées, s'appliquent à ces liens hypertextes. 
      Tous les liens vers des sites Web tiers sont fournis uniquement pour votre 
      confort et l'utilisation que vous en faites est à vos risques et périls. 
      Lovelight Factory n'est pas responsable du contenu des pages ainsi consultées.
     </p>
    </>
   },
   {
    title:   
    <>
     6. Responsabilité
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Lovelight Factory et toutes les autres parties concernées par la création, la 
      production ou la publication du Site Web déclinent toute responsabilité en cas 
      de perte, de dommages ou de frais, ou de dommages et intérêts directs, 
      accessoires, consécutifs, particuliers ou exemplaires découlant de votre 
      utilisation du Site Web ou des services (y compris la responsabilité en cas de 
      retard ou d'inexactitudes lors de la transmission d'informations, ou en cas 
      d'interruption, de suspension ou d'arrêt du site Web), à moins que de tels 
      dommages et intérêts soient le résultat d'une malveillance de la part de 
      Lovelight Factory.

      Lovelight Factory ne saurait être tenu pour responsable des dommages ou des 
      virus pouvant affecter votre équipement informatique suite à la consultation 
      ou l'utilisation du Site Web ou de son Contenu.

      Aucune stipulation des présentes Conditions d'utilisation ne saurait exclure 
      ni limiter la responsabilité de Lovelight Factory, qui ne peut être exclue ou 
      limitée selon la législation en vigueur.
     </p>
    </>
   },
   {
    title:   
    <>
     7. Garanties
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      L’utilisation que vous faites du Site Web et la manière dont vous le parcourez 
      dépendent de votre responsabilité. Sauf spécification expresse, le Site Web dans 
      son ensemble est fourni « tel quel » et « sous réserve de disponibilité ».
      Lovelight Factory rejette formellement toute garantie, expresse ou implicite, y 
      compris, mais sans s'y limiter, toute garantie implicite de qualité 
      satisfaisante, d'adéquation à un usage spécifique ou de non-violation du droit 
      d'auteur, dans la mesure permise par la loi.
      
      Un grand soin a été apporté au Contenu et à l'exactitude des informations 
      présentées sur le Site Web. Toutefois, ne garantit en aucun cas que le Site Web 
      et les informations qu'il contient répondent aux exigences des utilisateurs, ni 
      que le site Web sera exempt d'interruptions, de retards, de failles de sécurité 
      ou d'erreurs.  n'offre également aucune garantie quant aux résultats obtenus 
      suite à l'utilisation du Site Web.
      
      Tout téléchargement ou autre procédé permettant d'obtenir des documents et/ou 
      des données via l'utilisation du Site Web se fait à votre propre appréciation 
      et à vos risques et périls
     </p>
    </>
   },
   {
    title:   
    <>
     8. Indemnisation
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Vous êtes responsable du contenu de votre fichier d'image placé sur le serveur 
      de ou de tout autre document distribué via le Site Web ou les services. Vous 
      assumez également la responsabilité de toutes les obligations découlant de 
      votre utilisation du Site Web ou des services. Vous vous engagez à indemniser 
      et ses sociétés affiliées en cas de perte, de dommage, d'obligation ou de 
      plainte, ainsi que de menace ou d'introduction d'une action en justice contre 
      ou ses sociétés affiliées et découlant de l'utilisation que vous faites du 
      Site Web ou des services.
     </p>
    </>
   },
   {
    title:   
    <>
     9. Responsabilité relative au nom d’utilisateur et au mot de passe
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Lorsque vous fournissez des informations pour l'utilisation ou la demande d'un 
      nom d'utilisateur et d'un mot de passe, vous vous engagez à fournir des données 
      exactes, à jour et complètes, telles que requises par les formulaires 
      d'inscription rendus accessibles par via le Site Web ou un autre canal. Si les 
      informations que vous fournissez sont fausses, inexactes, obsolètes ou 
      incomplètes, peut résilier ou suspendre votre accès à la section en question 
      du Site Web.
      
      Le nom d'utilisateur et le mot de passe que vous recevez pour accéder à certains 
      services fournis via le Site Web sont confidentiels et ne peuvent être ni 
      divulgués ni partagés avec un tiers, ni utilisés à des fins non autorisées. 
      Il vous appartient de veiller à la confidentialité du nom d'utilisateur et du mot 
      de passe qui vous sont communiqués et la responsabilité des activités qui sont 
      effectuées avec ce nom d'utilisateur et ce mot de passe vous revient intégralement.
      
      Vous reconnaissez que l'utilisation conjointe du mot de passe et du nom 
      d'utilisateur représente votre autorisation de vous connecter sur le site. 
      Vous acceptez d'être lié à toute action enregistrée par l'utilisation de votre 
      nom d'utilisateur et de votre mot de passe. Vous vous engagez donc à mettre en 
      œuvre les mesures de sécurité appropriées pour garantir que le nom d'utilisateur 
      et le mot de passe qui vous ont été attribués seront divulgués uniquement aux 
      personnes autorisées.
      
      Vous vous engagez à :
       
       <ul>
        <li>
         signaler immédiatement à toute utilisation frauduleuse de votre nom 
         d'utilisateur et de votre mot de passe ou toute autre défaillance de la 
         sécurité ;
        </li>
        <li>
         vous assurer de vous déconnecter de votre nom d'utilisateur à la fin de 
         chaque session.
        </li>
       </ul>
      
     </p>
    </>
   },
   {
    title:   
    <>
     10. Généralités
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Lovelight Factory se réserve le droit de changer ou de mettre à jour à tout 
      moment les présentes Conditions d'utilisation. Vous serez lié par ces 
      modifications, aussi vous devrez consulter régulièrement cette page pour vous 
      informer des Conditions d'utilisation en vigueur.
     </p>
    </>
   },
   {
    title:   
    <>
     11. Droit applicable
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Les présentes Conditions d'utilisation doivent être régies et interprétées 
      conformément au droit français. Les litiges relatifs aux présentes
      Conditions d'utilisation sont soumis à la compétence exclusive des tribunaux 
      de Grasse (Alpes Maritimes).
     </p>
    </>
   },
   {
    title:   
    <>
     12. Adresses
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Lovelight Factory 
      1 place de la Fontaine
      06610 La Gaude 
      France 

      Registre du commerce Grasse : 978 665 511

      Numéro de TVA : FR60978665511 
     </p>
    </>
   },
  ],
  lastPart: 
  <>
  </> 
 }  

export const LeafletPersonnalDatasInformation_Informations: Block = 
 {
  title: 
  <>
   Notice d’information sur les données personnelles de Lovelight Factory
  </> ,
  introduction: 
  <>
   <p className = { classNames(styles.paragraph) }>
    Dans la présente notice d’information sur les données personnelles, nous expliquons 
    la manière dont nous recueillons et utilisons vos données personnelles. Cette notice 
    d’information sur les données personnelles s’applique à toutes les données personnelles 
    vous concernant que nous sommes amenés à traiter lorsque vous commandez, achetez ou 
    utilisez nos produits et services, lorsque vous visitez nos sites Internet ou notre 
    service d’assistance ou lorsque vous interagissez de toute autre manière avec Lovelight 
    Factory. Lovelight Factory respecte votre vie privée et reconnaît que le traitement des 
    données personnelles est une responsabilité sociale importante et doit se faire d’une 
    manière licite et appropriée ; elle déclare donc qu’elle s’efforcera de protéger les 
    données personnelles. Elle s’applique 
    à vous (ci-après, « vous »), en tant que client et/ou fournisseur potentiel, actuel et 
    passé, et à votre usage de nos produits et services, de nos sites Internet ou de tous les 
    autres rapports commerciaux avec Lovelight Factory. Dans la présente notice d’information 
    sur les données personnelles, nous expliquons la manière dont nous traitons vos données 
    personnelles. C’est pourquoi nous vous encourageons à lire cet avis avec attention.
   </p>
  </> ,
  sections: 
  [
   {
    title:   
    <>
     1. Qui sommes-nous ?
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Lovelight Factory est une société française sous la forme d’une société à responsabilité limitée. 
      Nous sommes responsables de la collecte et de l’utilisation de vos données personnelles décrites 
      dans cette notice d’information sur les données personnelles.
      
      Si vous voulez exercer vos droits, comme votre droit d’accéder, de corriger, d’effacer, de restreindre, 
      de refuser ou de transmettre vos données personnelles, ou de retirer votre consentement, ou si vous 
      avez des questions concernant le traitement de vos données personnelles, vous pouvez nous contacter 
      conformément à la présente notice d’information sur les données personnelles à l'adresse :
      team@lovelight.tv
     </p>
    </>
   },
   {
    title:   
    <>
     2. Données personnelles : objet, type et utilisation
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Les données personnelles que nous traitons sont des informations que vous nous fournissez sciemment, 
      que ce soit directement ou à travers des tierces parties. Cependant, dans certains cas, nous traitons 
      des données personnelles que nous pouvons inférer vous concernant sur la base d’autres informations 
      que vous nous avez communiquées ou de nos interactions avec vous, ou les données personnelles que nous 
      recevons de la part d’une société du groupe ou d’une tierce partie avec votre accord.
     </p>
     <p className = { classNames(styles.paragraph) }>
      Si vous refusez de fournir les données personnelles dont nous avons besoin pour l’exécution du contrat
      ou la conformité avec une obligation légale, nous pourrions ne pas être à même de fournir tout ou partie
      des services que vous nous demandez.
     </p>
     <p className = { classNames(styles.paragraph) }>
      Nous pouvons être amenés à traiter votre nom, vos coordonnées, votre date de naissance, vos données 
      d’assistance client, votre profession, vos intérêts photographiques, votre domaine d’expertise 
      en fonction des produits et/ou services Lovelight Factory que nous 
      vous fournissons. Les données personnelles que nous recueillons et traitons, ainsi que leur objet, 
      dépendront des produits et services que Lovelight Factory vous prodigue. Les objectifs principaux pour 
      lesquels nous traitons vos données personnelles sont comme suit :

      <ol>
       <li>
        Vous fournir nos services et communiquer avec vous
        <br/>
        Nous utilisons également vos coordonnées pour répondre à vos questions lorsque vous contactez 
        notre service à la clientèle.
       </li>
       <li>
        Objectifs de marketing direct
        <br/>
        Nous utilisons vos informations de compte et vos coordonnées pour vous envoyer des informations 
        sur les nouveaux produits et services susceptibles de vous intéresser. Nous ne vous envoyons 
        des messages de marketing direct qu’avec votre accord préalable ou si nous avons recueilli 
        votre adresse électronique dans le cours de nos rapports avec vous. Vous pouvez toujours vous 
        désabonner pour ne plus recevoir ces messages en suivant pour cela les instructions contenues 
        dans la communication de marketing relative, en réglant vos préférences de compte (le cas échéant) 
        ou en contactant notre service à la clientèle.
        <br/>
        Nous analysons vos données personnelles et nous pourrions inclure des offres personnalisées dans 
        nos messages de marketing direct en fonction des intérêts légitimes que nous pourrions avoir à vous 
        communiquer des offres appropriées. Vous pouvez à tout moment vous opposer à l’utilisation de vos 
        données personnelles aux fins de marketing direct.
        <br/>
        Nous pourrions utiliser différents canaux de communication pour vous envoyer des publicités 
        personnalisées et des offres spéciales, tels que les courriers postaux ou, avec votre consentement, 
        des notifications poussées mobiles ou les médias sociaux.
       </li>
       <li>
        Pour recherche statistique
        <br/>
        Nous utilisons des outils automatiques pour conduire une recherche statistique sur les tendances 
        générales concernant l’utilisation de nos services, sites Internet, applications et médias sociaux 
        et sur le comportement et les préférences de nos clients et utilisateurs.
        <br/>
        Les informations que nous utilisons pour notre recherche incluent votre code postal et votre pays 
        de résidence, les informations techniques que nous recevons si vous visitez l’un de nos sites Internet, 
        les informations que nous recueillons via les cookies, vos préférences et intérêts personnels et les 
        informations que vous partagez avec nous en ligne ou via les médias sociaux. Pour notre recherche, 
        nous n’utilisons que les données agrégées qui ne sont pas attachées à votre nom et à votre adresse 
        électronique. Nous utilisons les résultats agrégés de la recherche pour détecter les tendances 
        générales dans l’utilisation de nos produits, services et outils. Cela nous permet de mieux développer 
        des produits, services, outils et offres, d’assurer un service à la clientèle plus réactif et 
        d’améliorer la conception et le contenu de nos sites Internet. Pour en savoir davantage sur les cookies 
        et autres technologies similaires, veuillez consulter la Notice d’information sur les cookies sur 
        notre site Internet.
       </li>
       <li>
        Pour la tenue des registres et la conformité aux obligations réglementaires
        <br/>
        Nous recueillons, stockons et utilisons vos données à des fins professionnelles internes telles que 
        la tenue d’un registre et la conformité à nos obligations légales et fiscales.
       </li>
       <li>
        Services spécifiques, applications, événements Lovelight Factory, concours ou campagnes
        <br/>
        Pour certains services, applications, événements, concours ou campagnes en particulier, nous pouvons 
        utiliser vos données personnelles à des fins autres que celles décrites dans la présente notice 
        d’information sur les données personnelles. Nous vous informerons de telles fins lorsque vous vous 
        enregistrerez pour les services, événements, concours ou campagnes ou lorsque vous téléchargerez 
        l’application appropriée.
        <br/>
        Vos données personnelles peuvent être utilisées pour d’autres fins encore. Dans un tel cas, nous nous 
        assurerons que ceci est fait conformément aux lois applicables.
       </li>
      </ol>
     </p>
     <p className = { classNames(styles.paragraph) }>
      Base juridique
     </p>
     <p className = { classNames(styles.paragraph) }>
      Nous traitons vos données personnelles parce que cela est nécessaire à l’exécution d’un contrat stipulé avec 
      vous afin de vous fournir nos produits et services, de nous conformer aux obligations juridiques ou si cela est 
      dans notre intérêt légitime ou dans celui d’une tierce partie, ou encore sur la base de votre consentement.
      <br/>
      Lorsque nous traiterons vos données personnelles pour notre intérêt légitime, nous prendrons également en compte 
      et nous assurerons que le traitement ultérieur de vos données personnelles est fait en conformité avec les 
      exigences légales. De ce fait, nous nous efforcerons également de nous assurer que le traitement effectué à 
      cette nouvelle fin est compatible avec l’objectif pour lequel les données personnelles ont été initialement 
      recueillies. Ceci ne s’applique pas lorsque ce traitement est fait en vertu d’une obligation légale ou lorsque 
      nous avons obtenu votre consentement. Nos intérêts légitimes sont par exemple l’amélioration de nos produits 
      et services, la réduction de nos coûts, l’amélioration de nos brochures et sites Internet ou la sécurisation de 
      nos services et de nos installations.
      <br/>
      Si vous avez donné votre consentement, sachez que vous pouvez le retirer à tout moment en suivant le lien de 
      désabonnement dans un courriel, en réglant vos paramètres (le cas échéant) ou en nous contactant.
     </p>    
    </>
   },
   {
    title:   
    <>
     3. Partage d’informations
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Il est possible que vos données personnelles soient partagées avec des tierces parties de confiance exécutant 
      des fonctions commerciales ou vous fournissant les services. Il sera demandé à ces tierces parties de protéger 
      de manière appropriée vos données personnelles, sous réserve des accords correspondant aux exigences des lois 
      applicables. Vos données personnelles peuvent également être partagées pour des investigations (par ex., 
      divulgation en prévention de crimes ou de fraudes, ou pour se conformer avec une ordonnance de justice ou au 
      droit).
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Un transfert ou une divulgation des données personnelles peut également être effectué(e) en lien avec une 
      opération (prévue) altérant la structure de notre entreprise, comme une réorganisation, une fusion, une vente, 
      une coentreprise, une cession, un transfert, un changement de contrôle ou toute autre disposition de tout ou 
      partie de notre entreprise, de nos actifs ou des actions (opérations sociales).
     </p>    
    </>
   },
   {
    title:   
    <>
     4. Mesures de sécurité et rétention de données
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Nous protégerons vos données personnelles conformément à nos politiques informatiques et de sécurité de sorte 
      que celles-ci seront protégées contre toute utilisation non autorisée, tout accès non autorisé et toute 
      modification, perte ou destruction abusive. Vos données personnelles seront conservées aussi longtemps que 
      nécessaire pour les fins pour lesquelles elles ont été obtenues, ainsi que conformément à nos exigences légales 
      et fiscales.
     </p>    
    </>
   },
   {
    title:   
    <>
     5. Vos droits
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Vous pouvez nous contacter pour exercer l’un ou l’autre des droits mentionnés ci-dessous. Nous pourrions 
      ne pas toujours être en position de répondre à votre requête. Par exemple, votre requête d’accès pourrait 
      être limitée si nécessaire afin de protéger les droits et les libertés d’autres personnes, ou nous pourrions 
      refuser de supprimer vos données personnelles dans le cas où leur traitement serait nécessaire en vertu de 
      nos obligations légales.
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Si vous voulez exercer vos droits, veuillez envoyer votre requête aux coordonnées ci-dessous. Notez que nous 
      pourrions avoir besoin que vous nous donniez d’autres informations pour confirmer votre identité. Vous avez 
      également le droit de déposer une plainte auprès de l’autorité néerlandaise de protection des données.
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Vous pouvez aussi nous contacter si vous avez des questions, remarques ou plaintes en lien avec la présente 
      notice d’information sur les données personnelles.
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Droit d’accès
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Vous pouvez demander à accéder à vos données personnelles. Cela inclut la question de savoir si nous traitons 
      des données personnelles vous concernant.  
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Droit de rectification
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Vous avez le droit de faire rectifier vos données personnelles en cas d’inexactitudes ou de lacunes.
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Droit d’effacement
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Vous avez aussi le droit de faire effacer vos données personnelles. Nous effacerons vos données conformément 
      à votre demande et à la loi. Dans le cas où vos données seraient supprimées, sachez que, en raison de la façon 
      dont nous maintenons certains services, un certain temps pourrait être nécessaire avant que les copies 
      sauvegardées ne soient effacées.
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Droit de restriction du traitement
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Vous avez le droit d’obtenir que le traitement de vos données personnelles soit restreint dans certaines 
      circonstances.
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Droit de portabilité des données
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Le droit à la portabilité des données s’applique aux informations que nous recevons directement de votre part. 
      Vous avez le droit de demander que nous transférions les informations que vous nous donnez d’une organisation 
      à une autre (lorsque techniquement possible) ou que nous vous les donnions. Ce droit ne s’applique que dans 
      le cas de processus automatisés et pour le traitement de vos données sur la base de votre consentement ou 
      de l’exécution d’un contrat.
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Droit d’opposition
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Vous avez le droit de vous opposer au traitement de vos données personnelles dans le cas où nous traiterions 
      vos données personnelles sur la base de nos « intérêts légitimes » (y compris par profilage).
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Vous pouvez, à tout moment et gratuitement, vous opposer aux fins de marketing direct dans le cas où vos 
      données personnelles seraient traitées à de telles fins, ce qui inclut les fins de profilage dans la mesure 
      où elles sont liées à un tel marketing direct. Si vous exercez ce droit, nous ne traiterons plus vos données 
      personnelles à de telles fins.
     </p>    
    </>
   },
   {
    title:   
    <>
     6. Comment nous observons cette politique
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Nous avons très récemment mis à jour cette notice en novembre 2023 et celle-ci remplace les versions antérieures. 
      Nous actualiserons occasionnellement cette notice d’information sur les données personnelles.
     </p>    
    </>
   },
   {
    title:   
    <>
     7. Coordonnées en cas de question sur la protection de vos données personnelles
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Lovelight Factory - 1 place de la Fontaine - 06610 La Gaude - France
      E-mail : team@lovelight.tv                   
     </p>    
    </>
   },
  ],
  lastPart: 
  <>
  </>
 }  

export const LeafletCookiesInformation_Informations: Block = 
 {
  title: 
  <>
   Notice d’information sur les cookies
  </> ,
  introduction: 
  <>
   <p className = { classNames(styles.paragraph) }>
    Nous attachons une grande importance à la confidentialité et nous nous efforçons 
    d’améliorer la convivialité de nos sites Internet. C’est pourquoi nous tenons à vous 
    expliquer comment et pourquoi nous utilisons des cookies. Nous, Lovelight Factory 
    plaçons des cookies, des balises Web, Javascript et un stockage local (les « cookies ») 
    sur votre appareil lorsque vous visitez nos sites Internet. Les cookies font en sorte 
    que votre appareil collecte des informations (via Javascript). Votre appareil les marque 
    ensuite avec de petits fichiers en texte simple (cookies et stockage local), puis les 
    envoie au moment adapté (balises Web). De cette manière, les cookies nous permettent de 
    reconnaître votre appareil lors de votre prochaine visite sur l’un de nos sites Internet.
   </p>
  </> ,
  sections: 
  [
   {
    title:   
    <>
     1. Quels cookies déposons-nous et pourquoi
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Cookies fonctionnels
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Ces cookies sont nécessaires au fonctionnement de base du site et sont donc 
      placés sur votre appareil lorsque vous consultez nos sites Internet. Ces 
      cookies mémorisent vos préférences lorsque vous utilisez nos sites Internet. 
      Ils sont également utilisés pour répartir la charge sur nos serveurs, afin 
      que le site soit toujours disponible, et également pour des raisons de sécurité.
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Les cookies fonctionnels sont placés aux fins suivantes :
      <ul>
       <li>
        Reconnaître vos paramètres lors d’une prochaine visite ;
       </li>
       <li>
        Servir pour la connexion à plusieurs demandes émises lors d’une seule session ;
       </li>
       <li>
        Mémoriser vos préférences, par exemple un filtre produit, des outils de 
        comparaison, une langue, une géolocalisation ainsi que le nombre souhaité de 
        résultats de recherche ;
       </li>
       <li>
        Les articles que vous ajoutez à votre liste d’achat en ligne pendant le 
        shopping en ligne sont mémorisés et stockés ;
       </li>
       <li>
        Envoyer une notification en cas de commande non achevée, pour vous 
        éviter de perdre votre liste d’achat ;
       </li>
       <li>
        Effectuer des achats en toute sécurité sur les sites Internet de 
        Lovelight Factory ;
       </li>
       <li>
        Les sites Internet et les applications sont chargés de manière homogène, 
        afin de garantir leur bon fonctionnement et leur accessibilité ;
       </li>
       <li>
        Détecter des abus ou des problèmes potentiels sur nos sites Internet, 
        applications et services, par exemple en enregistrant le nombre de 
        tentatives de connexion successives infructueuses.
       </li>
      </ul>
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Votre consentement n’est pas requis pour l’utilisation des cookies fonctionnels, 
      qui servent au fonctionnement de base des sites Internet et n’ont pas 
      d’incidence (ou seulement une incidence limitée) sur votre vie privée.
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Cookies d’analyse
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Ces cookies sont utilisés pour collecter des informations sur la manière dont 
      les visiteurs utilisent les sites Internet de Lovelight Factory, y compris des 
      informations sur les pages les plus visitées et les messages d’erreur affichés. 
      Les cookies d’analyse nous permettent de collecter des statistiques relatives 
      aux utilisateurs de nos sites Internet. Ces cookies aident Lovelight Factory à 
      améliorer ses sites Internet.
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Nous utilisons Google Analytics pour générer des statistiques agrégées 
      concernant les visites sur nos sites Internet. Dans ce contexte, nous avons 
      conclu un accord de traitement avec Google. Les données obtenues via Google 
      Analytics sont partagées avec d’autres services Google.
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Les données suivantes sont stockées au moyen de cookies dans le système d’analyse :
      <ul>
       <li>
        Adresse IP ;
       </li>
       <li>
        Fonctionnalités techniques telles que le navigateur que vous utilisez 
        (par exemple Chrome, Internet Explorer ou Firefox) et la résolution de 
        votre écran d’ordinateur ;
       </li>
       <li>
        Page d’origine vous ayant conduit au site Internet de Lovelight Factory ;
       </li>
       <li>
        Date/heure et durée de votre visite ou de votre utilisation des sites 
        Internet Lovelight Factory ;
       </li>
       <li>
        Utilisation ou non des fonctionnalités des sites Internet. Par exemple 
        la création d’une liste d’achat, le placement d’une commande en ligne ou la 
        consultation vos achats précédents ;
       </li>
       <li>
        Pages visitées sur nos sites Internet ;
       </li>
       <li>
        Votre interaction avec les produits offerts par Lovelight Factory et les 
        promotions qui vous sont proposées ;
       </li>
       <li>
        Vos recherches, y compris leurs résultats et les étapes suivies pendant un 
        processus de commande ;
       </li>
       <li>
        Vos transactions (avec consentement) ;
       </li>
       <li>
        Votre utilisation des services Lovelight Factory sur divers appareils (avec consentement) ;
       </li>
       <li>
        Identificateurs uniques, par exemple l’identifiant de visiteur unique 
        attribué à votre appareil quand vous consultez nos sites Internet, 
        l’identifiant de transaction haché et l’identifiant de membre haché.
       </li>
      </ul>
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Nous utilisons ces informations aux fins suivantes :
      <ul>
       <li>
        Suivre le nombre de visiteurs sur nos pages Web ;
       </li>
       <li>
        Mesurer le temps que chaque visiteur passe sur nos pages Web ;
       </li>
       <li>
        Déterminer l’ordre dans lequel un visiteur consulte les différentes 
        pages de nos sites Internet ;
       </li>
       <li>
        Repérer les parties de nos sites et applications qui ont besoin 
        d’être ajustées ;
       </li>
       <li>
        Utiliser les données collectées pour d’autres services Google 
        (uniquement après consentement).
       </li>
      </ul>
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Cookies de marketing
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Ces cookies sont généralement placés sur les sites Internet de Lovelight 
      Factory par des partenaires marketing, des réseaux publicitaires et des 
      plates-formes de réseaux sociaux. Ces parties tierces jouent le rôle 
      d’intermédiaires pour vous présenter notre contenu, par exemple des messages, 
      des offres, des contenus de réseaux sociaux et des annonces publicitaires. 
      Ces parties tierces collectent également des données via nos sites Internet 
      en utilisant des cookies. Le traitement de ces données est soumis à leurs 
      politiques de confidentialité.
     </p>    
     <p className = { classNames(styles.paragraph) }>
      Les objectifs des cookies de marketing incluent :
      <ul>
       <li>
        La connexion à des réseaux sociaux pour que vous puissiez accéder à 
        des contenus et les partager depuis nos sites Internet via les réseaux 
        sociaux ;
       </li>
       <li>
        Collecter des informations pour nous permettre de personnaliser le 
        marketing selon vos centres d’intérêt, à la fois sur nos sites Internet 
        et ailleurs ;
       </li>
       <li>
        Limiter le nombre d’occurrences d’affichage de nos annonces ;
       </li>
       <li>
        Atteindre nos visiteurs en fournissant des contenus personnalisés 
        pertinents sur différents types de supports, par exemple e-mail, réseaux 
        sociaux et bannières publicitaires en fonction de vos actions sur nos 
        sites Internet ;
       </li>
       <li>
        Mesurer l’efficacité de nos campagnes marketing.
       </li>
      </ul>
     </p>
    </>
   },
   {
    title:   
    <>
     2. Contrôler les cookies que nous enregistrons et utilisons
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Si vous souhaitez révoquer votre consentement, vous pouvez régler les paramètres 
      de votre navigateur afin de supprimer nos cookies et ceux des parties tierces. 
      Vous pouvez également ajuster les paramètres de votre navigateur pour empêcher 
      les sites Internet de déposer aussi bien leurs cookies que ceux des tiers. 
      Si vous nous empêchez de placer certains cookies, certaines fonctionnalités 
      risquent de ne pas être disponibles ou certaines parties du site Internet 
      risquent de ne pas pouvoir être chargées. Découvrez comment ajuster les 
      paramètres de :
      <ul>
       <li>
        <Link to        = "https://support.mozilla.org/fr-FR/kb/cookies-information-websites-store-on-your-computer" 
              className = { classNames(styles.link) }
              target    = "__blank">
         Firefox
        </Link>
       </li>
       <li>
        <Link to        = "https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11" 
              className = { classNames(styles.link) }
              target    = "__blank">
         Edge
        </Link>
       </li> 
       <li>
        <Link to        = "https://support.apple.com/kb/PH21411?locale=en_US&viewlocale=en_US" 
              className = { classNames(styles.link) }
              target    = "__blank">
         Safari
        </Link>
       </li>
       <li>
        <Link to        = "https://www.sizmek.com/privacy-policy/optedout/"  
              className = { classNames(styles.link) }
              target    = "__blank">
         Sizmek
        </Link>
       </li>
       <li>
        <Link to        = "https://radiumone.com/opt/" 
              className = { classNames(styles.link) }
              target    = "__blank">
         Radiumone
        </Link>
       </li>
       <li>
        <Link to        = "https://www.appnexus.com/fr/platform-privacy-policy" 
              className = { classNames(styles.link) }
              target    = "__blank">
         Appnexus
        </Link>
       </li>
       <li>
        <Link to        = "https://www.facebook.com/settings/?tab=ads" 
              className = { classNames(styles.link) }
              target    = "__blank">
         Facebook
        </Link>
       </li>
       <li>
        <Link to        = "https://adssettings.google.com/" 
              className = { classNames(styles.link) }
              target    = "__blank">
         Google
        </Link>
       </li>
      </ul>
     </p>
    </>
   },
   {
    title:   
    <>
     3. Notre notice d’information sur les données personnelles
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Pour plus d’informations sur la manière dont Lovelight Factory traite vos 
      données personnelles, veuillez consulter notre Notice d’information sur les 
      données personnelles.
     </p>
    </>
   },
   {
    title:   
    <>
     4. Coordonnées en cas de question sur la protection des données personnelles
    </> ,
    content: 
    <>
     <p className = { classNames(styles.paragraph) }>
      Lovelight Factory – 1 place de la Fontaine – 06610 La Gaude – France
     </p>
     <p className = { classNames(styles.paragraph) }>
      E-mail : team@lovelight.tv
     </p>
    </>
   }
  ],
  lastPart: 
  <>
   <p className={classNames(styles.paragraph)} >
    Nous avons très récemment mis à jour cet avis novembre 2023 et celui-ci remplace les 
    versions antérieures.
   </p>
  </>
 }  
      
export const LegalNoticePanel_Informations: JSX.Element = 

 <>
  <div className={classNames(styles.root)}>
  <h1>MENTIONS LÉGALES</h1>
  <h2>ÉDITEUR & HÉBERGEMENT</h2>
  <p>
   Éditeur : Lovelight Factory
  </p>
  <p>
   Le site lovelight.tv est édité par Lovelight Factory 
    SARL, au capital de 1000 euros, dont le siège social se trouve :
  </p>
  <p>
   1 place de la fontaine – 06610 La Gaude – France
  </p>
  <p>
   Immatriculée au RCS de Grasse
   <br/> 
   sous le numéro 978 665 511.
   <br/>
   contact: team@lovelight.tv
  </p>
  <p>
   Hébergement : Scaleway
  </p>
  <p>
   Société par Actions Simplifiée au capital de 214 410,50 Euros
  </p>
  <p>
   SIREN : 433 115 904 RCS Paris
  </p>
  <p>
   Siège social : 8 rue de la Ville l’Evêque, 75008 Paris
  </p>
  <p>
   <Link to        = "https://www.scaleway.com/fr/"
         className = { classNames(styles.link) }
         target    = "__blank">
    https://www.scaleway.com/fr/
   </Link>
  </p>
  <p>
   CRÉDITS
  </p>
  <p>
   Design & Développement : Lovelight Factory
  </p>
  </div>
 </>