import React, { useState } from 'react';


export interface LongTextProps {
  text: string,
  limit: number
}

export const LongText = ({ text, limit }: LongTextProps) => {

  const [isTruncated, setIsTruncated] = useState(true);

  const isTextTruncated = text.length > limit;

  const toggleTruncated = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <div>
      {isTextTruncated ? (
        <div>
          {isTruncated ? `${text.substring(0, limit)}...` : text}{'   '}
          <button onClick={toggleTruncated}>
            {isTruncated ? 'Lire la suite' : 'Réduire'}
          </button>
        </div>
      ) : (
        text
      )}
    </div>
  );
};

export default LongText;
