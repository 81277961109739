export enum ActionType
 {
  INIT_AUTHENTICATION = 'INIT_AUTHENTICATION' ,
  SET_LOGIN           = 'SET_LOGIN'           , 
  SET_TOKEN           = 'SET_TOKEN'           ,
  COUNT_ATTEMPT       = 'COUNT_ATTEMPT'  
 }

export interface Authentication
 {
  login:              string ,
  token:              string ,
  numberOfAttempts:   number 
 }

export interface actionInitAuthentication
 {
  type:      ActionType.INIT_AUTHENTICATION;
  complete:  boolean;
 }

export interface actionSetLogin
 {
  type:  ActionType.SET_LOGIN;
  login: string;
 }

export interface actionSetToken
 {
  type:  ActionType.SET_TOKEN;
  token: string;
 }

export interface actionCountAttempt
 {
  type:  ActionType.COUNT_ATTEMPT;
 }

export type Action = actionInitAuthentication | 
                     actionSetLogin           | 
                     actionSetToken           |
					 actionCountAttempt       ;

export type DispatchTypeInitAuthentication = ( args: actionInitAuthentication ) => actionInitAuthentication
export type DispatchTypeSetLogin           = ( args: actionSetLogin           ) => actionSetLogin
export type DispatchTypeSetToken           = ( args: actionSetToken           ) => actionSetToken
export type DispatchTypeCountAttempt       = ( args: actionCountAttempt       ) => actionCountAttempt

					 
