import   React                   ,
       { useState                ,
	     useEffect               }  from 'react';


import { useNavigate             }  from 'react-router-dom';

import { Dispatch                }  from 'redux';
import { useDispatch             }  from 'react-redux';

import   classNames                 from 'classnames';

import   styles                     from './offer_choice.module.scss';

import { Window                  }  from '../../../../widget/window/window';
import { AnimatedWaitingIcon     }  from '../../../../widget/animated_waiting_icon/animated_waiting_icon';

import { ProductChoice4          }  from '../../../../block/product/product_choice4';
import { BankChoice              }  from '../../../../block/payment/bank_choice';


import { useTypedSelector        }  from '../../../../../datas/useTypeSelector';
  
import { Product                 }  from '../../../../../datas/productManagment/actionTypes';


import { setLogin                , 
         setToken                ,
		 initAuthentication      }  from '../../../../../datas/authenticationManagment/actionCreators';

import { updateEmail            , 
         updatePassword         , 
         updatePassword2        , 
         updateFirstname        , 
         updateLastname         , 
         updateSex              , 
         updateBirthday         , 
         updateRgpdConsent      ,
		 updateStatus           ,
		 updateId               ,
		 setUser                ,
		 initUser               }  from '../../../../../datas/userManagment/actionCreators';
		 
import { getProductInfos         }  from '../../../../../network/api_product';
  
import { postUser               ,
         postUserAuthentication ,
		 getUserInfos           }  from '../../../../../network/api_user';
  
import { postSubscription        }  from '../../../../../network/api_payment';
import { patchUserDiscoverOfferChoiceAtCreation }  from '../../../../../network/api_user';


import { INTERNAL_LINKS          }  from '../../../../../network/urls';

export const OfferChoice = () => {

  const { authentication } = useTypedSelector((state) => state.authenticationManagment);
  const { user           } = useTypedSelector((state) => state.userManagment);

  const dispatch: Dispatch<any> = useDispatch()
  const navigate = useNavigate();

  const empty:Product [] = []

  const [ first           , setFirst           ] = useState(false);
  const [ selectedProduct , setSelectedProduct ] = useState(0);
  const [ selectedVariant , setSelectedVariant ] = useState(0);
  const [ products        , setProducts        ] = useState(empty);
  const [ showBankChoice  , setShowBankChoice  ] = useState ( false );

  const [ action , setAction ] = useState(0);
  const [ price  , setPrice  ] = useState(0);
  
  const [ showWindow            , setShowWindow         ] = useState ( false );
  const [ windowMessage         , setWindowMessage      ] = useState ( <></> );
  const [ expiredSession        , setExpiredSession     ] = useState ( false );

  const [ showWaitingIcon       , setShowWaitingIcon    ] = useState ( false );

  let login: string   = ""
  let token: string   = ""

  useEffect(() => {
  
    if (!first) 
	 {
      init();
      setFirst(true);
     }
	 
  }, [first, selectedProduct, selectedVariant, products]);

  const init = async () => {
  
    let results = await getProductInfos ( "Offres découverte, standard et premium" )
	
    setProducts ( results.products )
  };
  
  const handleValidationButtonClick = () => {
  
    navigate ( `${INTERNAL_LINKS.AccountCreationSuccess}` );
  }

  const handleActionChange = (id: number, price: number) => {

    setAction (id)
    setPrice  (price)
  }

  const handleValidButtonClick = () => {

    setShowBankChoice (true)

  }

  const handleValidButtonClickOnDiscover = () => {
   
   makeDiscoverOfferChoice ()
  }

  const makeDiscoverOfferChoice = async () => {
  
   const user_id = await makeRegistration ()
  
  					  console.log ("makeDiscoverOfferChoice ", user_id, login, token)

   const result = await patchUserDiscoverOfferChoiceAtCreation ( token   ,
                                                                 user_id )

  					  console.log ("makeDiscoverOfferChoice ", result)

   if ( result.code === 1 )
         {
          dispatch ( initUser   () );
          dispatch ( initAuthentication (true) );

          navigate ( `${INTERNAL_LINKS.AccountCreationSuccess}` );
	     }
	else {
	      console.log ("ERREUR")
	     }
  }

  const handleCloseButtonClick = () => {

    setWindowMessage (<></>);
    setShowWindow	 (false)

    if ( expiredSession )
     {
	  dispatch ( initUser   () );
	  dispatch ( initAuthentication (true) );
      navigate ( `${INTERNAL_LINKS.Home}` );
	 }
  }

  const makeSubscription = async (codeBank : string) => {

   const user_id = await makeRegistration ()
  
  					  console.log ("makeSubscription ", user_id, login, token)

           // Les processeurs de ces 2 offres font la distinction entre achat et modif en fonction du status actuel (discover ou non)

           const response = await postSubscription (
                                                     price                                                                   , // Montant en centimes d'euros
                                                     0                                                                       , // Pas de réduction car c'est une première inscription'
                                                     "TV"                                                                    , // Code du business unit "Lovelight TV"
                                                     action === 2 ? "1" : "3"                                                , // Code des product vatiant "Offre standard annuel" / "ffre premium annuel"
                                                     "001"                                                                   , // Code du prix appliquée à cette vente
                                                     codeBank                                                                , // Code de bank 
                                                     login                                                    , // Email du user
                                                     "Souscription de l'offre " + ( action === 2 ?  "Standard" : "Premium" ) , // Commentaire
                                                     token                                                      // Token JWT
                                                   )

           switch ( response.code )
	            {
                 case 1    : window.location.href = response.url;  // Amène à la page de paiement

			                 break;

	             case -102 : setWindowMessage (<>Votre sessions a expiré, veuillez vous reconnecter</>);
	                         setShowWindow    (true)
                             setExpiredSession (true);

				             break;

                 default :   setWindowMessage (<>Une erreur réseau est survenue lors de la génération de la demande de paiement</>);
                             setShowWindow    (true)
	            }
  }



  const handleCancelButtonClickInBankChoice = () => {
  
    setShowBankChoice (false)
  }

  const handleValidButtonClickInBankChoice = ( codeBank: string ) => {
     
    setShowBankChoice (false)
	
    makeSubscription ( codeBank ) // "01" = "BNP - Axepta"
	                              // "02" = "Paypal"

  }

  const makeRegistration = async (): Promise<number> => {
 
   let user_id: number = 0

      setShowWaitingIcon (true);
	 
      const response01 = await postUser ( user.email     ,
                                          user.password  ,
										  user.firstname ,
										  user.lastname  ,
										  user.sex       ,
										  user.birthday  )

      console.log (response01)

      if ( response01.code === 1 )
	        {
             // Le compte a pu être créé, on va se connecter automatiquement dessus afin de continuer le processus d'abonnement
             // avec un token JWT (car la création d'un paiment est à accès restreint)

             const authenticationResult = await postUserAuthentication ( user.email    ,
                                                                         user.password )


      console.log (authenticationResult)

	         if ( authenticationResult.code === 1 )
                   {
				    token = authenticationResult.token
				    login = user.email
					
                    dispatch ( setLogin ( user.email                 ) );
                    dispatch ( setToken ( authenticationResult.token ) );
				
				    const userInfos = await getUserInfos ( user.email ,
                                                           authenticationResult.token )
														   
      console.log (userInfos)

					if ( userInfos.code === 1 )
                     {		
                      user_id = userInfos.infos.id
					 
                      dispatch ( updateId ( userInfos.infos.id ) )
					  
					  console.log ("C'est ok ", user_id, login, token)
                     }

                   }
              else {
	                setShowWaitingIcon (false);
			 
                    setWindowMessage ( <>Problème lors de la connexion automatique au nouveau compte</> );
                    setShowWindow    ( true );
                   }
	        }
	   else {
	         setShowWaitingIcon (false);

		     setWindowMessage ( <>Problème lors de la création du compte</> );
             setShowWindow    ( true );
	        }
			
	 return user_id;
   }


  return (
  
    <div className={classNames(styles.root)}>
      
	   { ( showWaitingIcon ) && ( <AnimatedWaitingIcon/>) }
	  
	  <div className={classNames(styles.form)}>
        
		<div className={classNames(styles.line_1)}>
          <p className={classNames(styles.subtitle)}>Étape 2 : Choix de l'offre</p>
        </div>
        
		<div className={classNames(styles.line_2)}>
		
		  {/* currentOfferNumber = { 3 } = Offre Free pour gérer le choix de Discover, en plus de Standard et Premium */}

		  <ProductChoice4 onActionChange                = { handleActionChange }
                          onValidButtonClick            = { handleValidButtonClick }
		                  onValidButtonClickOnDiscovert = { handleValidButtonClickOnDiscover }
                          currentOfferNumber            = { 3 }
				          currentOfferDeadline          = { null }
						  nextOfferNumber               = { -1 }
						  offerModificationRequestDate  = { null } />

        </div>

        {
		 showWindow
		 &&
         (
		  < Window message               = { windowMessage          }
			       onClickOnCloseButton  = { handleCloseButtonClick }
			       opaqueBackgroundColor = "#333333"/>
		 )
		}

        {
		 showBankChoice
		 &&
         (
		  < BankChoice onCancelButtonClick = { handleCancelButtonClickInBankChoice }
			           onValidButtonClick  = { handleValidButtonClickInBankChoice  } />
		 )
		}

      </div>

    </div>
	
  );
};

export default OfferChoice;
