import   React              ,
        { PropsWithChildren , 
          ReactNode         }  from 'react';

import   classNames            from 'classnames';

import   styles                from './widget_frame.module.scss';


export interface WidgetFrameProps {
  children:            ReactNode;
  classNameName?:      string;                                                   // classe CSS complémentaire
  classNameGrid?:      string;                                                   // classe CSS complémentaire
  classNameElement?:   string;                                                   // classe CSS complémentaire
  name?:               string;                                                   // Nom du champ écrit au dessus de la zone de saisie
  error?:              string;                                                   // Le message d'erreur
  labelTextPosition?:  string;                                                   // Alignement du texte au sein du bloc label
  labelBlockPosition?: string;                                                   // Position du bloc label
  required?:           boolean | undefined;                                      // Indispensable ou non
}

export const WidgetFrame = ({ children          ,
                              classNameName     , 
                              classNameGrid     , 
                              classNameElement  , 
                              name              ,
                              error             , 
							  labelTextPosition ,
							  labelBlockPosition,
						      required          }:  PropsWithChildren<WidgetFrameProps> ) => {
							  
  const numberOfChildren = React.Children.count(children);
  
  const nameLeftAlign = typeof ( labelTextPosition ) === "undefined" || labelTextPosition === "left";
  
  const renderLabelFromName = (): JSX.Element => {

    if ( typeof (name) !== "undefined" || name !== "" )
	      {
               return <>
				
                       {name}
				 
                       { 
                          ( required ) 
                          ?
                          ( <i className={classNames(styles.widget_frame_required)}>&nbsp;&#1645;</i> ) 
                          :
                          ( <></> )
                       }

                  </>
              }
	 else {
	       return <>
                      </>
	      }
  }

  const renderLabelFromFirstChild = (): JSX.Element => {

    const child = React.Children.toArray(children)[0];

    return <>
	        {child}
           </>
  }

  const renderLabel = (): JSX.Element => {

    if ( typeof (name) !== "undefined" && name !== "" )
	      {
		   return renderLabelFromName ();
		  }
	 else {
	       return renderLabelFromFirstChild ();
	      }

  }
  
  const renderElement = (): JSX.Element => {

    const child = React.Children.toArray(children)[numberOfChildren-1];

    return <>
	        {child}
           </>
  }

  const renderComponentLayout1LabelLeft = (): JSX.Element => {
  
    return <>

             <div className = { classNames ( styles.widget_frame_name, ( nameLeftAlign ? styles.name_left : styles.name_right ), styles.widget_frame_layout_1_position_1_1 , classNameName ) } >

			 { renderLabel () }

			 </div>

   		     <div className = { classNames ( styles.widget_frame_element, styles.widget_frame_layout_1_position_1_2 , classNameElement) } >
			 
			 { renderElement () }
			 
			 </div>
	   
   		     <div className = { classNames ( styles.widget_frame_info, styles.widget_frame_layout_1_position_2 ) } >{error}</div>
      
           </>
  }
  
  const renderComponentLayout1LabelRight = (): JSX.Element => {

    return <>

             <div className = { classNames ( styles.widget_frame_name, ( nameLeftAlign ? styles.name_left : styles.name_right ), styles.widget_frame_layout_1_position_1_2 , classNameName) } >

			 { renderLabel () }

			 </div>

   		     <div className = { classNames ( styles.widget_frame_element, styles.widget_frame_layout_1_position_1_1 ) } >
			 
			 { renderElement () }
			 
			 </div>
	   
   		     <div className = { classNames ( styles.widget_frame_info, styles.widget_frame_layout_1_position_2 ) } >{error}</div>
      
           </>
  }

  const renderComponentLayout2LabelTop = (): JSX.Element => {
  
    return <>

             <div className = { classNames ( styles.widget_frame_name, ( nameLeftAlign ? styles.name_left : styles.name_right ), styles.widget_frame_layout_2_position_1 , classNameName) } >

			 { renderLabel () }

			 </div>

   		     <div className = { classNames ( styles.widget_frame_element, styles.widget_frame_layout_2_position_2 ) } >
			 
			 { renderElement () }
			 
			 </div>
	   
   		     <div className = { classNames ( styles.widget_frame_info, styles.widget_frame_layout_2_position_3 ) } >{error}</div>
      
           </>
  }

  const renderComponent = (): JSX.Element => {
  
         if ( typeof (labelBlockPosition) !== "undefined" && labelBlockPosition === "right" ) { return renderComponentLayout1LabelRight() }
	else if ( typeof (labelBlockPosition) !== "undefined" && labelBlockPosition === "top"   ) { return renderComponentLayout2LabelTop()   }
	else                                                                                      { return renderComponentLayout1LabelLeft()  }
  }

  return (
  
           <div className={ ( typeof (classNameGrid) === "undefined" ) ? classNames(styles.widget_frame) : classNames(classNameGrid) }>

            { renderComponent () }
			
           </div>
           
         );
};

export default WidgetFrame;
