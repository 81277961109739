import React  from 'react';

import   classNames                                from 'classnames';

import styles from './full_height_number.module.scss';

export interface FullHeightNumberProps {
  num: number;
}

export const FullHeightNumber = ({ num }: FullHeightNumberProps) => {

  return (
    <div className = {classNames(num<=9 ? styles.root : styles.root2)}>
      { num }
    </div>
  );
};

export default FullHeightNumber;
