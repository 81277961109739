import   React                ,  
       { useState             ,
	     useEffect            }  from 'react';

import { useNavigate          }  from 'react-router-dom';

import { Dispatch                               }  from 'redux';
import { useDispatch                            }  from 'react-redux';

import   classNames              from 'classnames';

import   styles                  from './video_catalog.module.scss';

import { VideoCategory        }  from './video_category';
import { VideoSheet           }  from './video_sheet';
import { VideoZoom            }  from './video_zoom';

import { Video                }  from '../../../../../datas/videoManagment/actionTypes';
import { initCart             }  from '../../../../../datas/cartManagment/actionCreators';
import { initAuthentication   }  from '../../../../../datas/authenticationManagment/actionCreators';
import { initUser             }  from '../../../../../datas/userManagment/actionCreators';

import { TopTen               }  from '../topten/topten';
import { HightlightedElements }  from '../highlighted_elements/highlighted_elements';


import { useTypedSelector     }  from '../../../../../datas/useTypeSelector';

import { Window               } from '../../../../widget/window/window';

import { WWW_URLS             , 
         INTERNAL_LINKS       }  from '../../../../../network/urls';

import { postEvent            }  from '../../../../../network/api_events_stats';

export const VideoCatalog = () => {

  const { catalog        } = useTypedSelector ( (state) => state.videoManagment );
  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );

  const navigate  = useNavigate();
  const dispatch: Dispatch<any> = useDispatch()

  const videoInit: Video = {
                             id:                       0     ,
							 title:                    ''    ,
                             shortTitle:               ''    ,
                             complementToTheTitle:     ''    ,
                             description:              ''    ,
                             duration:                 ''    ,
                             distribution:             ''    ,
                             production:               ''    ,
                             copyright:                ''    ,
                             releaseDate:              ''    ,
                             ranking:                  0     ,
                             rankingFromYoutube:       0     ,
                             numberOfViews:            0     ,
                             numberOfViewsFromYoutube: 0     ,
                             imageFile:                ''    ,
                             videoFile:                ''    ,
							 visible:                  true  ,
							 locked:                   false ,
							 computedDuration:         0  
				            }

  let zoomVisible2: boolean = false;

  const [ sheetVisible    , setSheetVisible    ] = useState ( false );
  const [ heightSheet     , setHeightSheet     ] = useState (   0   );
  const [ widthSheet      , setWidthSheet      ] = useState (   0   );
  const [ topSheet        , setTopSheet        ] = useState (   0   );
  const [ leftSheet       , setLeftSheet       ] = useState (   0   ); 

  const [ zoomVisible     , setZoomVisible     ] = useState ( false );
  const [ heightThumbnail , setHeightThumbnail ] = useState (   0   );
  const [ widthThumbnail  , setWidthThumbnail  ] = useState (   0   );
  const [ topThumbnail    , setTopThumbnail    ] = useState (   0   );
  const [ leftThumbnail   , setLeftThumbnail   ] = useState (   0   ); 
  
  const [ videoToShow     , setVideoToShow     ] = useState ( videoInit );

  const [ alertWindowVisible , setAlertWindowVisible ] = useState ( false );
  const [ alertWindowDeprecatedTokenVisible, setAlertWindowDeprecatedTokenVisible] = useState ( false );

  let scrollY:number = 0;

   const showZoom = ( video:  Video  ,
                     width:  number ,
					 height: number ,
					 top:    number ,
					 left:   number ) => {
  
  
    if ( !sheetVisible && ( !zoomVisible2 || scrollY !== window.scrollY ) )
	 {
      setVideoToShow     ( video  )
	  setWidthThumbnail  ( width  )
	  setHeightThumbnail ( height )
	  setTopThumbnail    ( top    )
      setLeftThumbnail   ( left   )
	  setZoomVisible     ( true   )
	  
	  zoomVisible2    = true;
	  scrollY         = window.scrollY;
	 }
  }
  
  const hideZoom = () => {

    if ( !sheetVisible )
	 {
    setZoomVisible     ( false )
	setWidthThumbnail  (   0   )
	setHeightThumbnail (   0   )
	setTopThumbnail    (   0   )
    setLeftThumbnail   (   0   )
	
	zoomVisible2 = false;
	 }
  }

  const showSheet = ( video: Video ) => {
  
    if ( !sheetVisible )
	 {
      setZoomVisible     ( false )
	  setWidthThumbnail  (   0   )
	  setHeightThumbnail (   0   )
	  setTopThumbnail    (   0   )
      setLeftThumbnail   (   0   )

  	  zoomVisible2 = false;

  	  setVideoToShow  ( video )
      setSheetVisible ( true )
	  
	  
	 }
  }

  const hideSheet = () => {
  
    setSheetVisible ( false )
  }

 const handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			
			
		updateComponent ( event.clientX ,
		                  event.clientY );		
	}

 const handleWheelMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			
			
		updateComponent ( event.clientX ,
		                  event.clientY );


	}
	
 const updateComponent = ( clientX: number ,
                           clientY: number ) => {
	
  if ( !alertWindowVisible )
   {
    if ( zoomVisible )
     {
      const coeffW    = 1.3;
      const coeffH    = 1.3;
      const topDiv    = topThumbnail  - heightThumbnail * ( coeffH / 2.0 - 0.5 );
      var   leftDiv   = leftThumbnail - widthThumbnail  * ( coeffW / 2.0 - 0.5 );
      const heightDiv = heightThumbnail * coeffH;
      const widthDiv  = widthThumbnail  * coeffW;

      var   leftSlickTrack  = 58;
      var   rightSlickTrack = window.innerWidth - 58 - 13;
  
      const divSlickTrack = document.querySelector('.slick-slider');
 
      if (divSlickTrack) 
       {
        const rect = divSlickTrack.getBoundingClientRect();

        leftSlickTrack  = rect.left + 10;                   // On ajoute le padding pour que ça paraisse aligné 
        rightSlickTrack = leftSlickTrack + rect.width - 15; // On retire le padding pour que ça paraisse aligné 
       }

      leftDiv = ( leftDiv            < leftSlickTrack  ? leftSlickTrack             : leftDiv );
      leftDiv = ( leftDiv + widthDiv > rightSlickTrack ? rightSlickTrack - widthDiv : leftDiv );
   
      let testX: boolean = leftDiv <= clientX + window.scrollX && clientX + window.scrollX <= leftDiv + widthDiv;
      let testY: boolean = topDiv  <= clientY + window.scrollY && clientY + window.scrollY <= topDiv  + heightDiv + 60;  // 60 = Hauteur de la seconde partie
   
      if ( !testX || !testY )
       {
        setZoomVisible     ( false )
	    setWidthThumbnail  (   0   )
	    setHeightThumbnail (   0   )
	    setTopThumbnail    (   0   )
        setLeftThumbnail   (   0   )

    	zoomVisible2 = false;  
	   }
	 }
   }
  } 

  const showAlertWindow = () => {
  
    if ( authentication.token !== '' )
	      {
           const eventMessage: string = `Replay-VideoCatalog-Window:\"Vous devez être un abonné standard ou premium pour accéder à ce programme\"`

           postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
	      }
	 else {
           const eventMessage: string = `Replay-VideoCatalog-Window:\"Vous devez être connecté pour accéder à ce programme\"`

           postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
	      }
  
    setAlertWindowVisible ( true );
  }

  const closeAlertWindowWithCancelButton = () => {
  
    const eventMessage: string = `Replay-VideoCatalog-Window-Button_NON`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
  
    setAlertWindowVisible ( false );
  }

  const closeAlertWindowWithValidButton1 = () => {
  
    const eventMessage: string = `Replay-VideoCatalog-Window-Button_OUI`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
  
    setAlertWindowVisible ( false );
	
	navigate ( `${INTERNAL_LINKS.Login}`)
  }

  const closeAlertWindowWithValidButton2 = () => {
  
    const eventMessage: string = `Replay-VideoCatalog-Window-Button_OUI`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowVisible ( false );
	
	navigate ( `${INTERNAL_LINKS.AccountManagment_Subscription}`)
  }


  const showAlertWindowDeprecatedToken = () => {
  
    const eventMessage: string = `Replay-VideoCatalog-Window:\"Votre session a expiré, vous devez vous reconnecter.\"`

    postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowDeprecatedTokenVisible ( true );
  }

  const closeAlertWindowDeprecatedTokenWithCancelButton = () => {

    const eventMessage: string = `Replay-VideoCatalog-Window-Button_NON`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowDeprecatedTokenVisible ( false );
	
	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Home}` );
   }

  const closeAlertWindowDeprecatedTokenWithValidButton = () => {

    const eventMessage: string = `Replay-VideoCatalog-Window-Button_OUI`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowDeprecatedTokenVisible ( false );

	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Login}` );
  }
 

 
  return (
 
   <div className   = {classNames(styles.root)} 
        onMouseMove = {handleMouseMove}     
		onWheel     = {handleWheelMove} >

      {
		zoomVisible &&
		(<VideoZoom  video           = { videoToShow     }
		             hideZoom        = { hideZoom        }
                     showSheet       = { showSheet       }				 
					 heightThumbnail = { heightThumbnail }
                     widthThumbnail  = { widthThumbnail  }
                     topThumbnail    = { topThumbnail    }
                     leftThumbnail   = { leftThumbnail   } 
					 showAlertWindow = { showAlertWindow }
					 alertWindowVisible = {alertWindowVisible}
					 showAlertWindowDeprecatedToken = {showAlertWindowDeprecatedToken} />)
      }

      {
		sheetVisible &&
		(<VideoSheet video           = { videoToShow     }
		             hideSheet       = { hideSheet       } 
					 showAlertWindow = { showAlertWindow }
                     alertWindowVisible = {alertWindowVisible}
					 showAlertWindowDeprecatedToken = {showAlertWindowDeprecatedToken} />)
      }
            
	  {
	    alertWindowVisible &&
		authentication.token === '' &&
	    ( < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez être connecté pour accéder à ce programme.</span><br/><br/>Se connecter ?<br/></> }
                   messageType           = "normal"
                   onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                   onClickOnValidButton  = {closeAlertWindowWithValidButton1}
                   cancelButtonName      = "NON"
                   validButtonName       = "OUI"   /> )
	  }
			
	  {
	    alertWindowVisible &&
		authentication.token !== '' &&
	    ( < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez être un abonné standard ou premium pour accéder à ce programme.</span><br/><br/>S'abonner ?<br/></> }
                   messageType           = "normal"
                   onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                   onClickOnValidButton  = {closeAlertWindowWithValidButton2}
                   cancelButtonName      = "NON"
                   validButtonName       = "OUI"   /> )
	  }
		
			  {
	           alertWindowDeprecatedTokenVisible 
			   &&
	           ( 
			    < Window message               = { <><span className = {classNames(styles.bold_text)}>Votre session a expiré, vous devez vous reconnecter.</span><br/><br/>Confirmer ?<br/></> }
                         messageType           = "normal"
                         onClickOnCancelButton = {closeAlertWindowDeprecatedTokenWithCancelButton}
                         onClickOnValidButton  = {closeAlertWindowDeprecatedTokenWithValidButton}
                         cancelButtonName      = "NON"
                         validButtonName       = "OUI"   /> 
			   )
	          }
		 

      <HightlightedElements showSheet = {showSheet}
	                        showAlertWindowDeprecatedToken = {showAlertWindowDeprecatedToken} /> 
 
	  <br/>
 
      <TopTen showSheet = {showSheet} 
			  showZoom  = {showZoom} 
			  showAlertWindowDeprecatedToken = {showAlertWindowDeprecatedToken} />
       
	   
      { catalog.categories.map ( (category) => ( <><VideoCategory category           = {category} 
	                                                              showSheet          = {showSheet} 
																  showZoom           = {showZoom} 
                                                                  showAlertWindowDeprecatedToken = {showAlertWindowDeprecatedToken} /><br/></> ) ) }
	
   </div>
 
  );
};

export default VideoCatalog;

