import   React     , 
       { useEffect ,
	     useState  }  from 'react';

import   classNames                                from 'classnames';

import styles from './ranked_thumbnail.module.scss';

import   FullHeightNumber   from '../../../../widget/full_height_number/full_height_number'


import { RankedVideo} from '../../../../../network/api_video'

import { VideoThumbnail      }  from '../catalog/video_thumbnail';

import {Video             }  from '../../../../../datas/videoManagment/actionTypes'


export interface RankedThumbnailProps {
  rank: number;
  video: Video;
  showSheet: ( video: Video ) => void
  showZoom:  ( video:  Video  ,
               width:  number ,
			   height: number ,
			   top:    number ,
			   left:   number ) => void
    showAlertWindowDeprecatedToken: () => void;

}

export const RankedThumbnail = ({ rank      ,
                                  video     ,
								  showSheet ,
								  showZoom  ,
								  showAlertWindowDeprecatedToken }: RankedThumbnailProps) => {


  const imageUrl : string = "https://media.lovelight.tv/Vignettes/" + video.imageFile + ".webp"

  return (
 
   
    <div className   = {classNames (styles.root )}>
	   <FullHeightNumber num={rank} />
	   <div className={classNames(styles.image)} >
	     <VideoThumbnail video     = { video     } 
					   showSheet = { showSheet }
					   showZoom  = { showZoom  } 
					   showAlertWindowDeprecatedToken = {showAlertWindowDeprecatedToken} />
	   </div>
       {/*
	   <img
        src={imageUrl}
        alt="thumbnail"
        className={classNames(styles.image)}
      />*/}
    </div>
 
  );
};

export default RankedThumbnail;

