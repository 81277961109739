import   React             ,
       { PropsWithChildren , 
	     ReactNode         ,
		 useEffect         ,   
         useState          }  from 'react';
		 
import   classNames           from 'classnames';

import   styles               from './tab2.module.scss';

type Tab2Props = {
  className?: string; 
  children:   ReactNode;
  activePanelNumber?: number | undefined;
};

export const Tab2 = ({ className         ,
					   children          ,
					   activePanelNumber }:  PropsWithChildren<Tab2Props> ) => {

  const VIDE1: boolean[]  = [];
  const VIDE2: string[]   = [];

  const [ actives     , setActives     ] = useState(VIDE1);


  useEffect(() => {

      init ();

  }, [activePanelNumber] ); 
  
  const init = () => {
 
    var temp: boolean[] = [];
 
    if (children && Array.isArray(children)) 
	 {
      for (let index = 0; index < children.length; index++) 
	   {
	    temp.push(index === activePanelNumber);
       }
     }
	 
    setActives ( temp );
  }
/*
  const handleChange = ( newActives: boolean[] ) => {
  
    setActives ( newActives );
  }
 /*
  const handleNext = () => {

    var temp: boolean[] = []; 

    if ( !actives[actives.length-1] )
	 {
	  temp.push ( false );
	  
      for (let index = 0; index < actives.length-1; index++)
	   {
	    temp.push(actives[index]);
       }
	   
      setActives ( temp );
     }

  }
/*
  const handlePrevious = () => {

    var temp: boolean[] = []; 

    if ( !actives[0] )
	 {

      for (let index = 1; index < actives.length; index++) 
	   {
	    temp.push(actives[index]);
       }

	  temp.push ( false );
	  
      setActives ( temp );
     }
	 
  }

/*
  const handleValidate = () => {

    if ( typeof ( onValidateButtonClick ) !== "undefined" )
	 {
	  onValidateButtonClick ();
	 }
  }
*/
  return (

           <div className={classNames(styles.root)}>

             <div className={classNames(styles.contain)}>

               {/*
               <div className={classNames(styles.controls, styles.top)}>

                 <div className={classNames(styles.left)}>

                    <Trigger classNameNormal = {classNames(styles.trigger_normal)}
					         classNamePushed = {classNames(styles.trigger_pushed)}
					         name            = "<" 
							 onClick         = {handlePrevious} />

                 </div>

                 <div className={classNames(styles.center)}>

                    <Choices classNameNormal = {classNames(styles.trigger_normal)}
                             classNamePushed = {classNames(styles.trigger_pushed)}
                             choices         = { choices }
                             actived         = { actives }
			                 onChange        = { handleChange }
							 vertical        = { false } />

                 </div>

                 <div className={classNames(styles.right)}>

                    <Trigger classNameNormal = {classNames(styles.trigger_normal)}
					         classNamePushed = {classNames(styles.trigger_pushed)}
					         name            = ">"  
							 onClick         = { handleNext }/>

                 </div>

               </div>
               */}
			   
               <div className={classNames(styles.middle)}>

               {
	             children && 
	             Array.isArray(children) && 
	              (
                    <div>
                     {
     		          children.map((item, index) => ( actives[index] && <div key={index}>{item}</div> ))
	  	             }
                    </div>
                  )
	           }

	           </div>

               {/*
               <div className={classNames(styles.bottom)}>

                 <Trigger classNameNormal = { classNames ( styles.trigger_normal ) }
				          classNamePushed = { classNames ( styles.trigger_pushed ) }
					      name            = { (actives[actives.length-1] ? "Valider" : "Etape suivante") } 
						  onClick         = { (actives[actives.length-1] ? handleValidate : handleNext) } />

	           </div>
               */}
			   
	         </div> 
			 
           </div> 
         );
};


export default Tab2;
