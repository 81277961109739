import   React                  ,  
       { useState               ,
	     useEffect              }  from 'react';
		 
import { useNavigate            }  from 'react-router-dom';
		 
import { Dispatch               }  from 'redux';
import { useDispatch            }  from 'react-redux';
		 
import { format                 }  from 'date-fns';
import { formatInTimeZone       }  from 'date-fns-tz'

import   classNames                from 'classnames';

import   styles                    from './hotline.module.scss';

import { Question               }  from '../../../../../datas/hotlineQuestionManagment/actionTypes';

import { HotlineQuestionDetails }  from './hotline_question_details';


export interface HotlineQuestionListProps {
  questions:          Question [];
  updateQuestionList: () => void;  
}

export const HotlineQuestionList = ({ questions          ,
                                      updateQuestionList }: HotlineQuestionListProps) => {

  const [ questionNumber , setQuestionNumber ] = useState ( -1 )
  const [isSmallScreen   , setIsSmallScreen  ] = useState(false);

  const switchWidthLimit = 615;

  // Fonction pour ajuster isSmallScreen selon la taille de l'écran
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= switchWidthLimit);
  };

  // Initialisation et écoute du redimensionnement
  useEffect(() => {
    handleResize(); // Appel initial pour vérifier la taille de l'écran
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

  }, [questions, questionNumber, isSmallScreen]); 

  const handleDetailsButtonClick = ( num: number ) => {
  
 
    setQuestionNumber ( num )
  }

  const handleCloseButtonClick = () => {
  
    setQuestionNumber ( -1 )
  }

  const parisTimeZone = 'Europe/Paris';

  return (
            
           <div className = { classNames(styles.root) } >

             { questionNumber >= 0 && ( <HotlineQuestionDetails question           = {questions[questionNumber]}
			                                                    hideDetails        = {handleCloseButtonClick} 
																updateQuestionList = {updateQuestionList} /> ) }

		     <p className = { classNames(styles.title) } >
               MES QUESTIONS
             </p>

			 <br/>

{
  (questions.length === 0) ? (
    <div>
      <p className={classNames(styles.noQuestionMessage)}>
        Aucune question posée
      </p>
    </div>
  ) : (
    <>
      <div>
        <div className={classNames(styles.questions_grid)}>
          <div className={classNames(styles.questions_grid_col_1, styles.columnTitle)}>
            Question posée le
          </div>
          <div className={classNames(styles.questions_grid_col_2, styles.columnTitle)}>
            {!isSmallScreen && ( <>Titre de la question</> )}
          </div>
          <div className={classNames(styles.questions_grid_col_3, styles.columnTitle)}>
            Etat
          </div>
          <div className={classNames(styles.questions_grid_col_4)}>
          </div>
        </div>

        {questions.map((question, num) => (
          <div key={num} className={classNames(styles.questions_grid)}>
            <div className={classNames(styles.questions_grid_col_1)}>
              {formatInTimeZone(question.submissionDate, 'Europe/Paris', 'dd/MM/yyyy à HH:mm:ss')}
            </div>
			 <div className={classNames(styles.questions_grid_col_2, styles.columnTitle)}>
               {!isSmallScreen && ( <>{question.title}</> )}

             </div>
            <div className={classNames(styles.questions_grid_col_3)}>
              {question.status === "New" ? "En cours" : "Traitée"}
            </div>
            <div className={classNames(styles.questions_grid_col_4)}>
              <button
                className="relative inline-flex justify-left h-6 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
                onClick={() => handleDetailsButtonClick(num)}
              >
                <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-1 py-1 text-base font-medium text-white backdrop-blur-3xl">
                  + d'infos
                </span>
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}


           </div>
           
         );
};


export default HotlineQuestionList;
