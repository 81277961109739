import   React                    , 
       { useEffect                , 
	     useState                 }  from 'react';

import { useNavigate                 }  from 'react-router-dom';
		 
import { retrieveUserAgentInformations }  from '../../../network/utils';	
		 
import { Broadcasting             }  from './broadcasting';	
		 
import { postEvent                              }  from '../../../network/api_events_stats';
		 
import { useTypedSelector                       }  from '../../../datas/useTypeSelector';

import { INTERNAL_LINKS                         }  from '../../../network/urls';
		 
export const BroadcastingViewer = () => {
  
  const   navigate                = useNavigate();

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
  const { user           } = useTypedSelector ( (state) => state.userManagment );

  const [ version , setVersion ] = useState (0)
  
  useEffect(() => {
 
    if ( authentication.login === "" || 
	     authentication.token === "" ||
		 user.state           === "New" ||
		 user.status          === "Free" )
     {
	  navigate ( `${INTERNAL_LINKS.Home}` );
	 }
	 
    window.scroll(0, 0);

	postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );
 
  }, [] )

    return (
     
          <>
		  
		   <Broadcasting/>
		  
          </>		  
	);
};

export default BroadcastingViewer;
