import   React      ,
       { useEffect            ,   
         useState   ,
	     ReactNode  }  from 'react';
import { Link       }  from 'react-router-dom';
import   classNames    from 'classnames';

import   styles        from './trigger.module.scss';

export interface TriggerProps {
  classNameNormal?: string; 
  classNamePushed?: string;
  name?:            string;
  image1?:          ReactNode; // Accepts any ReactNode, including SVG elements
  image2?:          ReactNode; // Accepts any ReactNode, including SVG elements
  image3?:          ReactNode; // Accepts any ReactNode, including SVG elements
  link?:            string | undefined; 
  id?:              number | undefined;
  withStates?:      boolean | undefined;       // disponible ou non
  states?:          boolean[] | undefined;       // disponible ou non
  disabled?:        boolean | undefined;       // Disponible ou non
  onClick?:         (id?: number | undefined) => void | undefined;      
  onPushed?:        (id?: number | undefined) => void | undefined;     
  onUnpushed?:      (id?: number | undefined) => void | undefined;     
}

export const Trigger = ({ classNameNormal , 
                          classNamePushed ,
                          name            ,
                          image1          , 						  
                          image2          , 						  
                          image3          , 						  
                          link            ,
						  id              ,
						  withStates      ,
                          states          ,
                          disabled        , 
                          onClick         ,
						  onPushed        ,
						  onUnpushed      }: TriggerProps) => {

  const [ pushed , setPushed  ] = useState ( false );

  useEffect(() => {

    if ( typeof (withStates) !== "undefined" && 
	     typeof (states    ) !== "undefined" &&
	     typeof (id        ) !== "undefined" &&  
		         withStates                  )
     {
      setPushed(states[id]);
     }

  }, [withStates, id, states, pushed]); 
  

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

    if ( ( typeof (withStates) !== "undefined" ) && withStates )
	 {
	  if ( pushed )
	        {
             if ( typeof (onUnpushed) !== "undefined" && typeof (id) !== "undefined" )
              {
               onUnpushed(id);
              }
			}
	   else {
             if ( typeof (onPushed) !== "undefined" && typeof (id) !== "undefined" )
              {
               onPushed(id);
              }
	        }
			
	  setPushed ( !pushed );
	 }

    if ( typeof (onClick) !== "undefined" )
     {
      onClick(id);
     }
  };

  return (
  
           <>
			  
           {
			  
		     ( typeof ( link ) === "undefined" )
             ?
             (
               <button className = { ( !pushed ? classNames ( styles.trigger_normal , classNameNormal ) : classNames ( styles.trigger_pushed , classNamePushed ) ) }
                       disabled  = { disabled }
                       onClick   = { handleClick } >
			      {name}
  		          {image1 && <>{image1}</>}
  		          {image2 && <>&nbsp;&nbsp;{image2}</>}
  		          {image3 && <>&nbsp;&nbsp;{image3}</>}
			   </button>
  		     )			 
			 :
			 (
               <Link to={link}>
                 <button className = { ( !pushed ? classNames ( styles.trigger_normal , classNameNormal ) : classNames ( styles.trigger_pushed , classNamePushed ) ) }
                         disabled  = { disabled } 
                         onClick   = { handleClick } >
			        {name}
  		            {image1 && <>{image1}</>}
  		            {image2 && <>&nbsp;&nbsp;{image2}</>}
  		            {image3 && <>&nbsp;&nbsp;{image3}</>}
				 </button>
               </Link>
			 )
			 
		   }

  	       </>

         );
};

export default Trigger;
