import   React              ,
       { useEffect          ,
	     useState           }  from 'react';
		 
import   classNames            from 'classnames';

import { useLocation        ,
         useNavigate        }  from 'react-router-dom';

import { Dispatch           }  from 'redux';
import { useDispatch        }  from 'react-redux';

import   styles                from './rome.module.scss';

import { GlobalMenu         }  from '../../../menu/global/global';
import { Footer             }  from '../../../menu/footer/footer';

import { TopShifter         }  from '../../../menu/top_shifter/top_shifter';
import { CookiesManager     }  from '../../../cookie/manager';

import { Part01             }  from './blocks/part_01/part_01';
import { Part02             }  from './blocks/part_02/part_02';

import { postEvent          }  from '../../../../network/api_events_stats';

import { INTERNAL_LINKS     }  from '../../../../network/urls';
import { isTokenValid       }  from '../../../../network/utils'; 

import { useTypedSelector   }  from '../../../../datas/useTypeSelector';

import { initCart           }  from '../../../../datas/cartManagment/actionCreators';
import { initAuthentication }  from '../../../../datas/authenticationManagment/actionCreators';
import { initUser           }  from '../../../../datas/userManagment/actionCreators';

import { Window             }  from '../../../widget/window/window';

export const UrbanTour_Rome = () => {

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );

  const location = useLocation();
  
  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();
  
  const [ alertWindowVisible , setAlertWindowVisible ] = useState ( false );
  const [ alertWindowDeprecatedTokenVisible, setAlertWindowDeprecatedTokenVisible] = useState ( false );

  useEffect(() => {

    window.scroll(0, 0);

	postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

  }); 

  const showAlertWindow = () => {
  
  /*
    if ( authentication.token !== '' )
	      {
		   if ( isTokenValid ( authentication.token ) !== false )
            {
             const eventMessage: string = `UrbanTour-Rome-Window:\"Vous devez être un abonné standard ou premium pour accéder à cette masterclass\"`

             postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
			 
             setAlertWindowVisible ( true );
			}
	      }
	 else {
           const eventMessage: string = `UrbanTour-Rome-Window:\"Vous devez être connecté pour accéder à cette masterclass\"`

           postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
		   
           setAlertWindowVisible ( true );
	      }
  */
  
    if ( authentication.token === '' )
	 {
      const eventMessage: string = `UrbanTour-Rome-Window:\"Vous devez disposer d’un compte gratuit\"`

      postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
		   
      setAlertWindowVisible ( true );
	 }

  }


/*
  const closeAlertWindowWithCancelButton = () => {
  
    const eventMessage: string = `UrbanTour-Rome-Window-Button_NON`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowVisible ( false );
  }

  const closeAlertWindowWithValidButton1 = () => {
  
    const eventMessage: string = `UrbanTour-Rome-Window-Button_OUI`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
  
    setAlertWindowVisible ( false );
	
	navigate ( `${INTERNAL_LINKS.Login}`)
  }

  const closeAlertWindowWithValidButton2 = () => {
  
    const eventMessage: string = `UrbanTour-Rome-Window-Button_OUI`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
  
    setAlertWindowVisible ( false );
	
	navigate ( `${INTERNAL_LINKS.AccountManagment_Subscription}`)
  }
*/

  const closeAlertWindowWithCreateAccountButton = () => {
  
    const eventMessage: string = `UrbanTour-Rome-Window-Button_CREATE_ACOUNT`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowVisible ( false );
	
	navigate ( `${INTERNAL_LINKS.Subscription}`)
  }

  const closeAlertWindowWithLoginButton = () => {
  
    const eventMessage: string = `UrbanTour-Rome-Window-Button_LOGIN`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
  
    setAlertWindowVisible ( false );
	
	navigate ( `${INTERNAL_LINKS.Login}`)
  }



  const showAlertWindowDeprecatedToken = () => {
  
    const eventMessage: string = `UrbanTour-Rome-Window:\"Votre session a expiré, vous devez vous reconnecter.\"`

    postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowDeprecatedTokenVisible ( true );
  }

  const closeAlertWindowDeprecatedTokenWithCancelButton = () => {

    const eventMessage: string = `UrbanTour-Rome-Window-Button_NON`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowDeprecatedTokenVisible ( false );
	
	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Home}` );
   }

  const closeAlertWindowDeprecatedTokenWithValidButton = () => {

    const eventMessage: string = `UrbanTour-Rome-Window-Button_OUI`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowDeprecatedTokenVisible ( false );

	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Login}` );
  }

  return (
            <>

 		      <CookiesManager />
 			  <GlobalMenu />
              <TopShifter/>

	          {/*
	            alertWindowVisible &&
		        authentication.token === '' &&
	            ( < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez être connecté pour accéder à cette offre.</span><br/><br/>Se connecter ?<br/></> }
                           messageType           = "normal"
                           onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                           onClickOnValidButton  = {closeAlertWindowWithValidButton1}
                           cancelButtonName      = "NON"
                           validButtonName       = "OUI"   /> )
	          */}
			
	          {/*
	            alertWindowVisible &&
		        authentication.token !== '' &&
	            ( < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez être un abonné standard ou premium pour accéder à cette offre.</span><br/><br/>S'abonner ?<br/></> }
                           messageType           = "normal"
                           onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                           onClickOnValidButton  = {closeAlertWindowWithValidButton2}
                           cancelButtonName      = "NON"
                           validButtonName       = "OUI"   /> )
	          */}
			  
	          {
	            alertWindowVisible &&
		        authentication.token === '' &&
	            ( < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez disposer d’un compte gratuit</span><br/></> }
                           messageType           = "normal"
                           onClickOnCancelButton = {closeAlertWindowWithLoginButton}
                           onClickOnValidButton  = {closeAlertWindowWithCreateAccountButton}
                           cancelButtonName      = "Se connecter"
                           validButtonName       = "Créer un compte gratuit"   /> )
	          }
			  
		
			  {
	           alertWindowDeprecatedTokenVisible 
			   &&
	           ( 
			    < Window message               = { <><span className = {classNames(styles.bold_text)}>Votre session a expiré, vous devez vous reconnecter.</span><br/><br/>Confirmer ?<br/></> }
                         messageType           = "normal"
                         onClickOnCancelButton = {closeAlertWindowDeprecatedTokenWithCancelButton}
                         onClickOnValidButton  = {closeAlertWindowDeprecatedTokenWithValidButton}
                         cancelButtonName      = "NON"
                         validButtonName       = "OUI"   /> 
			   )
	          }
		 

				  <Part01 />
				  <Part02 showAlertWindow={showAlertWindow}/>


			<Footer/>

            </>
        );
};

export default UrbanTour_Rome;
