export interface Validation
 {
  code:    number  ,
  comment: string
 }
 
export const checkEmail = ( newEmail: string ): Validation => {

  var result: Validation = {
    code:    0 ,
    comment: ""  
  };

  if ( newEmail === "" )
        {
	     result.code    = -1;
		 result.comment = "Il manque l'adresse e-mail.";

        }
   else {
         const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        
         if ( emailRegex.test (newEmail) === false )
		       {
                result.code = -2;
                result.comment = "Ce n'est pas une adresse email valide.";
			   }
        }
		  
  return result;
};

export const checkPassword = ( newPassword: string ): Validation => {

  var result: Validation = {
    code:    0 ,
    comment: ""  
  };

  if ( newPassword === "" )
   {
	result.code    = -1;
	result.comment = "Il manque le mot de passe.";
   }

  return result;
};
