import   React                       ,
       { useEffect                   ,
	     useState                    }  from 'react';

import { Dispatch                                     }  from 'redux';
import { useDispatch                                  }  from 'react-redux';

import { useNavigate                 }  from 'react-router-dom';

import   classNames                from 'classnames';

import { TypewriterEffectSmooth }  from "../../ui/typewriter-effect";

import   styles                    from './fail.module.scss';

import { GlobalMenu             }  from '../../menu/global/global';
import { CookiesManager         }  from '../../cookie/manager';
import { Trigger                }  from '../../widget/trigger/trigger';
import { Footer                 }  from '../../menu/footer/footer';

import { INTERNAL_LINKS         }  from '../../../network/urls';

import { initAuthentication     }  from '../../../datas/authenticationManagment/actionCreators';
import { initUser               ,
         setUser                }  from '../../../datas/userManagment/actionCreators';

import { useTypedSelector       }  from '../../../datas/useTypeSelector';

import { postEvent              }  from '../../../network/api_events_stats';
 
export const PaymentFail = () => {

  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { user           } = useTypedSelector ((state) => state.userManagment );

  const [ version , setVersion ] = useState (0)
  const [isSmallScreen   , setIsSmallScreen  ] = useState(false);

  const switchWidthLimit = 615;

  // Fonction pour ajuster isSmallScreen selon la taille de l'écran
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= switchWidthLimit);
  };

  useEffect(() => {

    window.scroll(0, 0);

    handleResize(); // Appel initial pour vérifier la taille de l'écran
    window.addEventListener('resize', handleResize);
	
	postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

    return () => {
      window.removeEventListener('resize', handleResize);
    };
	
  }, []); 

  useEffect(() => {
	
  }, [version, isSmallScreen]); 

  const reinitCart = async () => {
    
	if ( authentication.login === "" || authentication.token === "" || user.state === "New" || user.state === "new" )
	      {
    dispatch ( initUser   () )
	dispatch ( initAuthentication (true) )
   	      }

    setVersion (version + 1)
  }


  const words = [
    {
      text: "Votre",
      className: "text-red-500 dark:text-red-500",
    },
    {
      text: "paiement",
      className: "text-red-500 dark:text-red-500",
    },
    {
      text: "a",
      className: "text-red-500 dark:text-red-500",
    },
    {
      text: "échoué.",
      className: "text-red-500 dark:text-red-500",
    },
  ];
  
  
  const logout = () => {

//    dispatch ( initUser   () );
//	dispatch ( initAuthentication (true) );
    navigate ( `${INTERNAL_LINKS.Login}` );
  }
  
  return (
 
    <>

      <GlobalMenu />
      <CookiesManager />

  	  <div className={classNames(styles.root)}>

    	<div className={classNames(styles.contain)}>

     	  <div className={classNames(styles.message)}>
           {
		      (isSmallScreen)
		      ?
		      (
                <p className="text-red-500 dark:text-red-500 text-[18px] font-bold">Votre paiement a échoué</p>
		      )
		      :
		      (
                <TypewriterEffectSmooth words={words} />
			  )
			}
          </div>

          
      	  <div className={classNames(styles.message2)}>
		  {/*
		    <br/>
             Vous pouvez cliquer sur le bouton ci-dessous pour vous rendre sur la section abonnements et services de votre compte et faire une nouvelle demande d'abonnement.
		  */}
            <br/>
		    <br/>
			
		   {
		     (
			   authentication.login === "" || authentication.token === "" || user.state === "New" || user.state === "new"
			 )
			 ?
			 (
               <Trigger onClick         = {logout}
		                name            = "Page de connexion"
                        classNameNormal = {classNames(styles.button)} />
			 )
			 :
			 (
               <Trigger link            = {INTERNAL_LINKS.Cart}
		                name            = "Retour au panier"
                        classNameNormal = {classNames(styles.button)} />
			 )
		   }

 	      </div>
		  
		  
        </div>

	  </div>

    <Footer />
 
    </>
	
  );
};

export default PaymentFail;

