import   React       ,
       { useEffect   ,   
         useState    }  from 'react';

import { Trigger     }  from '../trigger/trigger';

export interface ChoicesProps {
  classNameNormal?: string;                         // classe CSS complémentaire
  classNamePushed?: string;                         // classe CSS complémentaire
  choices:          string[];                       // Liste des choix
  actived?:         boolean[] | undefined;          // Etat de départ des choix
  readOnly?:        boolean | undefined;            // Editable ou non
  onChange?:        (choices: boolean[]) => void;   // Pour gérer le changement de l'input
  vertical?:        boolean | undefined;
}

export const Choices = ({ classNameNormal , 
                          classNamePushed ,
                          choices         ,
						  actived         ,
						  readOnly        ,
                          onChange        ,
						  vertical        }: ChoicesProps) => {

  const INITIAL_STATE: boolean[] = Array(choices.length).fill(false);

  const verticalDirection: boolean = ( ( typeof (vertical) === "undefined" ) || ( vertical === true ) );

  const [ currentChoices , setCurrentChoices ] = useState ( INITIAL_STATE );
  const [ firstRender    , setFirstRender    ] = useState ( true );

  useEffect(() => {

    if ( firstRender )
     {
	  if ( typeof ( actived ) !== "undefined"  )
	   {
        setCurrentChoices ( actived );
	   }
	   
      setFirstRender (false);
     }

  }, [firstRender, currentChoices, actived]); 
  
  const handleClick = (index?: number) => {
  
    if ( typeof (index) !== "undefined" )
	 {
      var tab: boolean[] = INITIAL_STATE;
	  tab[index] = true;

      setCurrentChoices (tab);
	
	  if ( typeof (onChange) !== "undefined" )
	   {
	    onChange (tab);
	   }
	 }
  }

  return (
            <>
			
			 {
			   choices.map ( 
			    
				 (choice, index) => ( 
				 
				    <>
				    
  					  <Trigger classNameNormal = {classNameNormal}
			                   classNamePushed = {classNamePushed}
			                   name            = {choice}
			                   withStates      = {true}  
                               states          = {currentChoices}														   
					           id              = {index}
							   onClick         = {handleClick}/> 
							
                      { 
					    ( verticalDirection ) &&
						( <br/> )
					  }
					  
							   
					</>
							  
					) 
				 )
			 }
			 
			</>
           
         );
};


export default Choices;
