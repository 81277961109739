import   React               ,  
       { useState            ,
	     useEffect           }  from 'react';
		 
import { useNavigate         }  from 'react-router-dom';
		 
import { Dispatch            }  from 'redux';
import { useDispatch         }  from 'react-redux';
		 
import   classNames             from 'classnames';

import   styles                 from './hotline.module.scss';

import { Field               }  from '../../../../widget/field/field';
import { TextArea            }  from '../../../../widget/textarea/textarea';
import { Select              }  from '../../../../widget/select/select';
import { DateSelector        }  from '../../../../widget/date_selector/date_selector';
import { WidgetFrame         }  from '../../../../widget/widget_frame/widget_frame';
import { WidgetFrameLabel    }  from '../../../../widget/widget_frame/widget_frame_label';
import { Toggle              }  from '../../../../widget/toggle/toggle';
import { Window              }  from '../../../../widget/window/window';

import { useTypedSelector    }  from '../../../../../datas/useTypeSelector';

import { initAuthentication  }  from '../../../../../datas/authenticationManagment/actionCreators';
import { initUser            }  from '../../../../../datas/userManagment/actionCreators';

import { INTERNAL_LINKS      }  from '../../../../../network/urls';

import { postHotlineQuestion }  from '../../../../../network/api_hotline';

export interface HotlineQuestionFormProps {
  updateQuestionList: () => void;
}

export const HotlineQuestionForm = ({ updateQuestionList }: HotlineQuestionFormProps) => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
  const { user           } = useTypedSelector ( (state) => state.userManagment   );

  const [ title              , setTitle              ] = useState ('');
  const [ device             , setDevice             ] = useState ('');
  const [ question           , setQuestion           ] = useState ('');
  const [ ruleConsent        , setRuleConsent        ] = useState (false);
  
  const [ messageTitle       , setMessageTitle       ] = useState ('');
  const [ messageDevice      , setMessageDevice      ] = useState ('');
  const [ messageQuestion    , setMessageQuestion    ] = useState ('');
  const [ messageRuleConsent , setMessageRuleConsent ] = useState ('');

  useEffect(() => {

    if ( authentication.login === "" || 
         authentication.token === "" )
     {
	  dispatch ( initUser () )
	  
      navigate ("/connexion");
     }

  }, []); 

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setTitle    ( e.target.value ) 
	
	if ( e.target.value === "" )
	      {
	       setMessageTitle ( "Vous devez saisir un titre" );
	      }
	 else {
	       setMessageTitle ( "" );
	      }
	
  };

  const handleDeviceChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setDevice    ( e.target.value ) 
	
	/*
	if ( e.target.value === "" )
	      {
	       setMessageDevice ( "Vous devez indiquer le matériel concerné" );
	      }
	 else {
	       setMessageDevice ( "" );
	      }
	*/
  };
  
  const handleQuestionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {

    setQuestion    ( e.target.value ) 
	
	if ( e.target.value === "" )
	      {
	       setMessageQuestion ( "Vous devez saisir une question" );
	      }
	 else {
	       setMessageQuestion ( "" );
	      }
	
  };

  const handleRuleConsentCheckedChange = ( newConsent: boolean ) => {
  
    setRuleConsent ( newConsent )

    if ( newConsent === false )
	      {
	       setMessageRuleConsent ( "Validation obligatoire" )
          }
	 else {
	       setMessageRuleConsent ( "" )
          }
  }
  
  const handleQuestionSubmissionButtonClick = () => {
  
    let test = true
  
  	if ( title === "" )
	      {
	       setMessageTitle ( "Vous devez saisir un titre" );
		   test = false
  	      }
	 else {
	       setMessageTitle ( "" );
	      }
	/*
	if ( device === "" )
	      {
	       setMessageDevice ( "Vous devez indiquer le matériel concerné" );
	      }
	 else {
	       setMessageDevice ( "" );
	      }
	*/
	if ( question === "" )
	      {
	       setMessageQuestion ( "Vous devez saisir une question" );
		   test = false
	      }
	 else {
	       setMessageQuestion ( "" );
	      }
	
    if ( ruleConsent === false )
	      {
	       setMessageRuleConsent ( "Validation obligatoire" )
		   test = false
          }
	 else {
	       setMessageRuleConsent ( "" )
          }

    if ( test )
	 {
	   makeSubmission ()
	 }

  }
  
  const makeSubmission = async () => {
  
	const response = await postHotlineQuestion ( user.id              ,
	                                             authentication.token ,
	                                             title                ,
				 			                     device               ,
				  			                     question             )
	
	if ( response.code === 1)
          {	
	       updateQuestionList ();	
		   
           setTitle       ('');
           setDevice      ('');
           setQuestion    ('');
           setRuleConsent (false);
		   
          }
	 else {
	 
	      }
  }

  return (
            
           <div className = { classNames(styles.root) } >

	           <p className={classNames(styles.title)}>
                 MA QUESTION
               </p>
              
			   <br/>
			  
               <div className={classNames(styles.form)}>

                 <div className={classNames(styles.layout_1_line_1)}>

			       <WidgetFrame name          = "Titre"
                                error         = {messageTitle}
   							    classNameGrid = {classNames(styles.grid_info)} >

                        <Field  type          = "text"
                                placeholder   = ""
								value         = {title}
                                onChange      = {handleTitleChange} />
				  
				  </WidgetFrame>
				  
                 </div>

                 <div className={classNames(styles.layout_1_line_2)}>

			       <WidgetFrame name          = "Matériel concerné"
                                error         = {messageDevice}
   							    classNameGrid = {classNames(styles.grid_info)} >

                        <Field  type          = "text"
                                placeholder   = ""
								value         = {device}
                                onChange      = {handleDeviceChange} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_3)}>

                   <WidgetFrame name          = "Question"
                                error         = {messageQuestion}
	    						classNameGrid = {classNames(styles.grid_info)} >
								
                        <TextArea placeholder = ""
							      value       = {question}
                                  onChange    = {handleQuestionChange} />

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_4)}>

                   <WidgetFrame error              = {messageRuleConsent}
	  			                classNameGrid      = {classNames(styles.grid_consent)} 
				                labelBlockPosition = "right" >

                        <WidgetFrameLabel className = {classNames(styles.text_consent)}>
					 
		                  Nous vous rappelons que vos questions sont posées à titre informel et ne
						  constituent pas une obligation contractuelle de réponse, liée à votre abonnement.

		                </WidgetFrameLabel>

                        <Toggle checked        = {ruleConsent}
                                onChange       = {handleRuleConsentCheckedChange}
		                        form           = "checkbox" />
							
                   </WidgetFrame>
				   
                 </div>

                 <div className={classNames(styles.layout_1_line_5)}>

                   <div className={classNames(styles.buttons)}>
					
					  <button className = "px-6 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                              onClick   = {handleQuestionSubmissionButtonClick}>
                           Envoyer ma question
                      </button>

                   </div>

                 </div>

                            
               </div>

           </div>
           
         );
};


export default HotlineQuestionForm;
