export enum ActionType
 {
  INIT_QUESTIONS = 'INIT_QUESTIONS',
  SET_QUESTIONS  = 'SET_QUESTIONS',
  ADD_QUESTION   = 'ADD_QUESTION',
 }

export interface Question
 {
  id:             number ,
  submissionDate: Date   ,
  title:          string ,
  device:         string ,
  question:       string ,
  status:         string ,
  response:       string ,
  responseDate:   Date | null  
 }

export interface actionInitQuestions
 {
  type:       ActionType.INIT_QUESTIONS
 }

export interface actionSetQuestions
 {
  type:         ActionType.SET_QUESTIONS,
  newQuestions: Question []
 }

export interface actionAddQuestion
 {
  type:         ActionType.ADD_QUESTION,
  newQuestion:  Question
 }

export type Action = actionInitQuestions |
                     actionSetQuestions  |
                     actionAddQuestion   ;

export type DispatchTypeInitQuestions = ( args: actionInitQuestions ) => actionInitQuestions
export type DispatchTypeSetQuestions  = ( args: actionSetQuestions  ) => actionSetQuestions
export type DispatchTypeAddQuestion   = ( args: actionAddQuestion   ) => actionAddQuestion
					 
