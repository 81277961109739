import   React                                  ,
       { useState                               }  from 'react';

import   classNames                                from 'classnames';

import   styles                                    from './leaflet_cookies_information.module.scss';

import { Leaflet                                }  from '../leaflet/leaflet';
import { LeafletCookiesInformation_Informations }  from '../legal_data/legal_data';

import { WindowCookiesManager                   }  from '../../../../cookie/windows/window_cookies_manager';

export const LeafletCookiesInformation = () => {

  const [ showCookiesParameters , setCookiesParameters ] = useState(false);

  const handleOpenCookiesManagerClick = () => {

    setCookiesParameters( true );
  };

  const handleCloseCookiesManagerClick = () => {

    setCookiesParameters( false );
  };

  return (

        <>

         { 
           showCookiesParameters &&
              
           ( <WindowCookiesManager onClickOnCloseButton = {handleCloseCookiesManagerClick }/> )
         }

          <div className={classNames(styles.root)}>

           <Leaflet block = {LeafletCookiesInformation_Informations} />

           <br/>

           <div className={classNames(styles.block_buttons)}>
		   
             <button className = "px-6 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                     onClick   = {handleOpenCookiesManagerClick}>
               Centre de préférences de la confidentialité
             </button>

           </div>
            
          </div>
        
        </>

            
  );
};

export default LeafletCookiesInformation;
