import   React                         , 
       { useEffect                     ,
	     useState                      ,
		 useRef                        }  from 'react';

import { useNavigate                   }  from 'react-router-dom';

import { Dispatch                      }  from 'redux';
import { useDispatch                   }  from 'react-redux';
	   
import   ReactPlayer                      from 'react-player/lazy'

import   classNames                       from 'classnames';

import   styles                           from './advert.module.scss';

import { retrieveUserAgentInformations }  from '../../../../../network/utils';	


import { Video                         }  from '../../../../../datas/videoManagment/actionTypes';

import { useTypedSelector              }  from '../../../../../datas/useTypeSelector';

import { setCurrentlyPlayingVideo      }  from '../../../../../datas/videoManagment/actionCreators';

import { HighlightedVideo }  from '../../../../../datas/videoManagment/actionTypes'

import { Window                        } from '../../../../widget/window/window';

import { WWW_URLS, 
         INTERNAL_LINKS                }  from '../../../../../network/urls';

import { isTokenValid }  from '../../../../../network/utils'; 

export interface AdvertProps {

  highlightedVideo : HighlightedVideo,
  showSheet: ( video: Video ) => void
  onAdvertComplete : () => void
  showAlertWindowDeprecatedToken: () => void;			   
}

export const Advert = ({ highlightedVideo , 
                         showSheet        ,
						 onAdvertComplete ,
						 showAlertWindowDeprecatedToken }: AdvertProps) => {

  const navigate  = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();

  const { catalog        } = useTypedSelector ( (state) => state.videoManagment );
  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );

  const [isPlayHovered , setPlayIsHovered ] = useState (false);
  const [isInfoHovered , setInfoIsHovered ] = useState (false);

  const [isShowing , setIsShowing ] = useState (false);
  const [isMuted , setIsMuted ] = useState (true);


  const [ alertWindowVisible1 , setAlertWindowVisible1 ] = useState ( false );
  const [ alertWindowVisible2 , setAlertWindowVisible2 ] = useState ( false );

  const [ infos      , setInfos      ] = useState ( retrieveUserAgentInformations () )

  const [ urlAdImage , setUrlAdImage ] = useState ("")
  const [ urlPoster  , setUrlPoster  ] = useState ("")
  const [ urlTrailer , setUrlTrailer ] = useState ("")
  const [ video      , setVideo      ] = useState<Video|null> (null)

  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const playerRef = useRef<ReactPlayer>(null);
  
  let isPlaying: boolean = false

  let scrollY:number = 0;
  
  let timer1: ReturnType<typeof setTimeout>;
  let timer2: ReturnType<typeof setTimeout>;

  useEffect(() => {
    
	const handleResize = () => {
      setScreenWidth(window.innerWidth); // Met à jour la largeur de l'écran avec screen.width
    };

    // Ajouter un écouteur d'événement pour le redimensionnement de la fenêtre
    window.addEventListener('resize', handleResize);

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('resize', handleResize);
    };
	
	init (); 
 
  }, []); 

  useEffect(() => {
  
    init ();  
	
  }, [highlightedVideo]);

  useEffect(() => {
  
  /*
    if ( isShowing )
	 {
      isPlaying = true  //( window.scrollY <= 600 )
	 }
  */
  
    if (playerRef.current) 
	 {
      if ( playerRef.current.getInternalPlayer() )
	   {
	    playerRef.current.getInternalPlayer().muted = isMuted; 
	   }
     }
  
  
  }, [ urlAdImage, urlPoster, urlTrailer,  isPlayHovered, isInfoHovered,  isShowing, screenWidth, isMuted]); 

  
  const init = () => {
 
    setIsShowing (false);
	isPlaying = false;

    const urlBase = 'https://media.lovelight.tv/'
	const url1 = urlBase + 'Affiches/' + highlightedVideo.adImageFile
	const url2 = urlBase + 'Affiches/' + highlightedVideo.posterImageFile
	const url3 = urlBase + 'Videos/'   + highlightedVideo.trailerVideo + '/index.m3u8'

    setUrlAdImage ( url1 )
    setUrlPoster  ( url2 )
	setUrlTrailer ( url3 )
	 
	setVideo ( getVideoById ( highlightedVideo.videoId ) )
	
    timer2 = setTimeout(() => {
      
        setIsShowing (true);
		
        }, 3000);
  }
  
  const getVideoById = (id: number): Video | null => {
  
    for (const category of catalog.categories) 
	 {
      for (const videoSet of category.videoSets) 
	   {
        for (const video of videoSet.videos) 
		 {
          if (video.id === id) 
		   {
            return video; 
           }
         }
       }
     }
	 
    return null; 
  };


  const handleMouseEnterInfo = () => {

    setInfoIsHovered (true);
  };

  const handleMouseLeaveInfo = () => {

    setInfoIsHovered (false);
  };
  
  const handleClickInfo = () => {

    if ( video !== null )
     {
      showSheet (video)	 
	 }
 
  }

  const handleMouseEnterPlay = () => {

    setPlayIsHovered (true);
  };

  const handleMouseLeavePlay = () => {

    setPlayIsHovered (false);
  };
  
  const handleClickPlay = () => {

    let deprecatedToken: boolean = false;
  
  	if ( authentication.login !== "" )
	 {
	  if ( isTokenValid ( authentication.token ) === false )
	   {
	    deprecatedToken = true
	    showAlertWindowDeprecatedToken ()
	   }
     }
  
    if ( !deprecatedToken )
	 {
      if ( video !== null )
       {	
        if ( video.locked === false )
	          {
     	       dispatch ( setCurrentlyPlayingVideo ( video ) );

               navigate ("/video")
	          }
	     else {
	           if ( authentication.login === "" || authentication.token === ""  )
		             { showAlertWindow1 (); }
		        else { showAlertWindow2 (); }
  	          }
	   }
	 }
  }

  const showAlertWindow1 = () => {
  
    setAlertWindowVisible1 ( true );
  }

  const showAlertWindow2 = () => {
  
    setAlertWindowVisible2 ( true );
  }

  const closeAlertWindowWithCancelButton = () => {
  
    setAlertWindowVisible1 ( false );
    setAlertWindowVisible2 ( false );
  }

  const closeAlertWindowWithValidButton1 = () => {
  
    setAlertWindowVisible1 ( false );
	
    navigate ( `${INTERNAL_LINKS.Login}`)
  }

  const closeAlertWindowWithValidButton2 = () => {
  
    setAlertWindowVisible2 ( false );
	
	navigate ( `${INTERNAL_LINKS.AccountManagment_Subscription}`) 
  }

  const handleEnded = () => {
  
    setIsShowing ( false )
	
	setTimeout(() => {
      
      onAdvertComplete  ()
		
    }, 10000);
  }
  
  const handleMuteClick = () => {
  
    setIsMuted ( !isMuted )
  
  }

  return (
 
   
        <div className   = {classNames (styles.root )}>
   
	      {
	        alertWindowVisible1 &&
	        ( 
		      < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez être connecté pour accéder à ce programme.</span><br/><br/>Se connecter ?<br/></> }
                       messageType           = "normal"
                       onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                       onClickOnValidButton  = {closeAlertWindowWithValidButton1}
                       cancelButtonName      = "NON"
                       validButtonName       = "OUI"   /> 
		    )
	      }
			
	      {
	        alertWindowVisible2 &&
	        ( 
			  < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez être abonné standard ou premium pour accéder à ce programme.</span><br/><br/>S'abonner ?<br/></> }
                       messageType           = "normal"
                       onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                       onClickOnValidButton  = {closeAlertWindowWithValidButton2}
                       cancelButtonName      = "NON"
                       validButtonName       = "OUI"   /> 
			)
	      }

          <div className = {classNames( styles.ad, isShowing ? styles.reduced : styles.normal )} >

		    <img src       = {urlAdImage} 
		         className = {classNames(styles.logo)}/>

            {
			  ( !isShowing && window.innerWidth >= 900 ) &&
			  (
		        <div className = {classNames(styles.text)}>
			      {highlightedVideo.adText}
			    </div>
			  )
            }
			
		    <div className = {classNames(styles.buttons)}>

			  	<div className = { classNames(styles.play)} >
						 
				 {
	               (video && video.locked)
				   ?
		           (
				     <>
				      {
                        ( screenWidth >= 900 ) 
						 ?
						(
				           <button className = "px-6 py-2 rounded-full bg-[#777777] font-bold text-sm text-black tracking-widest uppercase transform border border-black md:px-4 md:py-1.5 md:text-sm lg:px-5 lg:py-2 lg:text-base xl:px-6 xl:py-2 xl:text-lg"
						           onClick   = {handleClickPlay}>
                               ► Lecture
                           </button>
                        )
                         :						
						( 
						   <img src          = "https://lovelight.tv/images/play.png" 
			                    className    = {classNames(styles.icon_play)}  
			                    onClick   = {handleClickPlay} />
						)
					  }
					 </>
				   )
                   :
                   (
				      <>
				       {
                        ( screenWidth >= 900 ) 
						 ?
						(
				           <button className = "px-6 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200 md:px-4 md:py-1.5 md:text-sm lg:px-5 lg:py-2 lg:text-base xl:px-6 xl:py-2 xl:text-lg"
                                   onClick   = {handleClickPlay}>
                               ► Lecture
                           </button>
                         )
                         :						
						( 
						   <img src          = "https://lovelight.tv/images/play.png" 
			                    className    = {classNames(styles.icon_play)}  
			                    onClick   = {handleClickPlay} />
						)
					  }	
                     </>					  
				   )
                 }
				 
				</div>
			  
			  	<div className = { classNames(styles.info)} >
						 
 		          {
                    ( screenWidth >= 900 ) 
					?
					(
				       <button className = "px-6 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200 min-w-[100px] md:px-4 md:py-1.5 md:text-sm lg:px-5 lg:py-2 lg:text-base xl:px-6 xl:py-2 xl:text-lg"
                               onClick   = {handleClickInfo}>
                          + d'infos
                       </button>
				    )
					:
					(
   					   <img src          = "https://lovelight.tv/images/info.png" 
			             className    = {classNames(styles.icon_info)} 
			             onClick      = {handleClickInfo}     />
				    )
				  }
				  
				</div>
				
			
	        </div>
			  
          </div>
		  
		  {
            isShowing && infos.device.model !== 'iPhone' &&  
            (
              <div className={classNames(styles.videoOverlay)}>
                <div className={classNames(styles.videoPlayer)}>
				
                <ReactPlayer ref      = {playerRef}
                             url      = {urlTrailer}
                             controls = {false}
                             playing  = {true}
                             loop     = {false}
                             onEnded  = {handleEnded}
                             width    = '100%'
                             height   = '100%' 
							 muted    = {isMuted} 
							 volume={isMuted ? 0 : 0.5}  />
							 
			  	<div className = { classNames(styles.sound)} >
				
				 {
                    ( isMuted ) 
					?
					(
   					   <img src       = "/images/MuteOff.png" 
			             className    = {classNames(styles.icon_sound)} 
			             onClick      = {handleMuteClick}     />
				    )
					:
					(
   					   <img src       = "/images/MuteOn.png" 
			             className    = {classNames(styles.icon_sound)} 
			             onClick      = {handleMuteClick}     />
				    )
				  }
				
				</div>

				</div>
							 
              </div>
            )
          }

          <img src={urlPoster} className={classNames(styles.preview)} />
		  
        </div>
 
  );
};

export default Advert;

