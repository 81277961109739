import { ActionType                                         ,
         actionInitCatalog                                  ,
         actionSetCatalog                                   ,
         actionAddCategory                                  , 
		 actionAddVideoSet                                  ,
		 actionAddProduct                                   ,
         actionAddVideo                                     ,
		 actionSetLocked                                    ,
		 actionSetCurrentlyPlayingVideo                     ,
		 actionSetBroadcastingChannelPackage                ,
		 actionSetCurrentlyPlayingBroadcastingChannel       ,
         DispatchTypeInitCatalog                            ,
         DispatchTypeSetCatalog                             ,
		 DispatchTypeAddCategory                            ,
		 DispatchTypeAddVideoSet                            ,
		 DispatchTypeAddProduct                             ,
         DispatchTypeAddVideo                               ,
         DispatchTypeSetLocked                              ,
		 DispatchTypeSetCurrentlyPlayingVideo               ,
         DispatchTypeSetBroadcastingChannelPackage          ,
         DispatchTypeSetCurrentlyPlayingBroadcastingChannel ,
		 Catalog                                            ,
		 Category                                           ,
		 VideoSet                                           ,
		 Product                                            ,
		 Video                                              ,
         BroadcastingChannel                                ,
         BroadcastingChannelPackage                         }  from './actionTypes';

export const initCatalog = () => 
 {
  const action: actionInitCatalog = 
   {
    type:          ActionType.INIT_CATALOG
   }

  return ( dispatch: DispatchTypeInitCatalog ) => 
   {
    dispatch (action);
   }
 } 

export const setCatalog = (newCatalog: Catalog) => 
 {
  const action: actionSetCatalog = 
   {
    type:          ActionType.SET_CATALOG,
	newCatalog:    newCatalog
   }

  return ( dispatch: DispatchTypeSetCatalog ) => 
   {
    dispatch (action);
   }
 } 

export const addCategory = ( newCategory: Category ) => 
 {
  const action: actionAddCategory = 
   {
    type:               ActionType.ADD_CATEGORY,
	newCategory:        newCategory
   }

  return ( dispatch: DispatchTypeAddCategory ) => 
   {
    dispatch (action);
   }
 } 

export const addVideoSet = ( categoryNumber: number   ,
                             newVideoSet:    VideoSet ) => 
 {
  const action: actionAddVideoSet =
   {
    type:            ActionType.ADD_VIDEOSET,
	categoryNumber:  categoryNumber,
	newVideoSet:     newVideoSet,
   }
 
  return ( dispatch: DispatchTypeAddVideoSet ) => 
   {
    dispatch (action);
   }
 } 

export const addProduct = ( categoryNumber:  number  ,
                            videoSetNumber:  number  ,
                            newProduct:      Product ) => 
 {
  const action: actionAddProduct =
   {
    type:            ActionType.ADD_PRODUCT ,
	categoryNumber:  categoryNumber         ,
    videoSetNumber:  videoSetNumber         ,
    newProduct:      newProduct
   }
 
  return ( dispatch: DispatchTypeAddProduct ) => 
   {
    dispatch (action);
   }
 } 

export const addVideo = ( categoryNumber:  number ,
                          videoSetNumber:  number ,
                          newVideo:        Video  ) => 
 {
  const action: actionAddVideo =
   {
    type:            ActionType.ADD_VIDEO ,
	categoryNumber:  categoryNumber       ,
    videoSetNumber:  videoSetNumber       ,
    newVideo:        newVideo
   }
 
  return ( dispatch: DispatchTypeAddVideo ) => 
   {
    dispatch (action);
   }
 } 

export const setLocked = ( categoryNumber:  number  ,
                           videoSetNumber:  number  ,
                           videoNumber:     number  ,
                           newLocked:       boolean ) => 
 {
  const action: actionSetLocked =
   {
    type:            ActionType.SET_LOCKED ,
	categoryNumber:  categoryNumber        ,
    videoSetNumber:  videoSetNumber        ,
    videoNumber:     videoNumber           ,
    newLocked:       newLocked
   }
 
  return ( dispatch: DispatchTypeSetLocked ) => 
   {
    dispatch (action);
   }
 } 

export const setCurrentlyPlayingVideo = ( newVideo: Video | null ) => 
 {
  const action: actionSetCurrentlyPlayingVideo =
   {
    type:            ActionType.SET_CURRENTLY_PLAYING_VIDEO ,
	newVideo:        newVideo
   }
 
  return ( dispatch: DispatchTypeSetCurrentlyPlayingVideo ) => 
   {
    dispatch (action);
   }
 } 

export const setBroadcastingChannelPackage = (newBroadcastingChannelPackage: BroadcastingChannelPackage) =>
 {
  const action: actionSetBroadcastingChannelPackage =
   {
    type:                           ActionType.SET_BROADCASTING_CHANNEL_PACKAGE ,
	newBroadcastingChannelPackage:  newBroadcastingChannelPackage
   }

  return ( dispatch: DispatchTypeSetBroadcastingChannelPackage ) =>
   {
    dispatch (action);
   }
 }

export const setCurrentlyPlayingBroadcastingChannel = ( newBroadcastingChannel: BroadcastingChannel | null ) =>
 {
  const action: actionSetCurrentlyPlayingBroadcastingChannel =
   {
    type:                    ActionType.SET_CURRENTLY_PLAYING_BROADCASTING_CHANNEL ,
	newBroadcastingChannel:  newBroadcastingChannel
   }

  return ( dispatch: DispatchTypeSetCurrentlyPlayingBroadcastingChannel ) =>
   {
    dispatch (action);
   }
 }


