import   React            ,
       { useEffect        ,
         useState         }  from 'react';

import { Link             ,
         useNavigate      }  from 'react-router-dom';

import   classNames          from 'classnames';

import   styles              from './part_01.module.scss';

import { HoverEffect      ,
         Item             }  from "../../../../ui/card-hover-effect";

import { useTypedSelector }  from '../../../../../datas/useTypeSelector';

import { postEvent        }  from '../../../../../network/api_events_stats';

import { INTERNAL_LINKS   }  from '../../../../../network/urls';

export const Part01 = () => {

   const navigate = useNavigate();

   const { authentication } = useTypedSelector((state) => state.authenticationManagment );

   const yes = (): JSX.Element => {

      return (
              <>
               <img src       = "https://lovelightfactory.fr/images/icons/yes.png"
	                className = {classNames(styles.icon)}
			        alt       = "yes" />
              </>
             )
   }

   const no = (): JSX.Element => {

      return (
              <>
               <img src       = "https://lovelightfactory.fr/images/icons/no.png"
	                className = {classNames(styles.icon)}
			        alt       = "no" />
              </>
             )
   }

   const button = ( text: string   ,
                    fct:  ()=>void ) : JSX.Element => {

      return (
              <>
                    <div className={classNames(styles.btn)}>
                       <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
                               onClick   = {fct} >
                          <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                          <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-3 py-1 text-xl font-medium text-white backdrop-blur-3xl">{text}</span>
                       </button>
                    </div>
              </>
             )
   }

   const handleButtonClick1 = () => {

       const eventMessage: string = "Home-Part01-Button01:\"Je m'inscris gratuitement\""

       postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

       navigate ( `${INTERNAL_LINKS.Registration}`)
   }

   const handleButtonClick2 = () => {

       const eventMessage: string = "Home-Part01-Button02:\"Je m'abonne\""

       postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

       navigate ( `${INTERNAL_LINKS.Subscription}`)
   }

   const handleButtonClick2b = () => {

       const eventMessage: string = "Home-Part01-Button03:\"Je m'abonne\""

       postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

       navigate ( `${INTERNAL_LINKS.Subscription}`)
   }

   const offers:Item[] =
     [
       {
         title:        <>

	                     <div className={classNames(styles.offer_super_title)} >Offre Découverte</div>
	                     <br/>
                         <div className={classNames(styles.price)} >
	                        <div className={classNames(styles.free_block)} >
	                           <div className={classNames(styles.price)} >Gratuit</div>
                            </div>
                         </div>

                       </> ,

         description:  <>

                         <div className={classNames(styles.tab)}>

                            <div className={classNames(styles.tab_1_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_1_2)}>
	                           Vidéo sans coupure pub
                            </div>

                            <div className={classNames(styles.tab_2_1)}>
                               {no()}
                            </div>

                            <div className={classNames(styles.tab_2_2)}>
	                           Accès aux vidéos avant la sortie sur Youtube
                            </div>

                            <div className={classNames(styles.tab_3_1)}>
                               {no()}
                            </div>

                            <div className={classNames(styles.tab_3_2)}>
	                           Accès au live et découvrez des programmes exclusifs
                            </div>

                            <div className={classNames(styles.tab_4_1)}>
                               {no()}
                            </div>

                            <div className={classNames(styles.tab_4_2)}>
	                           Posez vos questions pendant les lives privées
                            </div>

                            <div className={classNames(styles.tab_5_1)}>
                               {no()}
                            </div>

                            <div className={classNames(styles.tab_5_2)}>
	                           Accès aux offres  <br/>
  	                           &laquo;&nbsp;MasterClasses Lovelight Urban Tour&nbsp;&raquo; <br/>
		                       <span className={classNames(styles.bold_italic)}>(abonnement annuel premium remboursé à l'achat de la première commande d'une Masterclass Urban Tour)</span>
                            </div>

                            <div className={classNames(styles.tab_6_1)}>
                               {no()}
                            </div>

                            <div className={classNames(styles.tab_6_2)}>
                               Invitation aux événements Lovelight
                            </div>

                         </div>

                         <br/>

	                     {button ("Je m'inscris gratuitement",handleButtonClick1)}

                       </> ,

         link: "",
       },
       {
         title:        <>

	                     <div className={classNames(styles.offer_super_title)} >Offre Standard</div>
	                     <br/>
	                     <div className={classNames(styles.offers)} >
                            <div className={classNames(styles.annual)} >
	                           <div className={classNames(styles.price)} >5,90 €/mois</div>
	                           <div className={classNames(styles.price)} >Offre annuelle</div>
	                           <span className={classNames(styles.unbold)} >1 paiement de 69 €</span>
                            </div>
                            {/*
                            <div className={classNames(styles.mensual)} >
	                           <div className={classNames(styles.price)} >7,90 €/mois</div>
	                           <div className={classNames(styles.price)} >Offre mensuelle</div>
	                           <span className={classNames(styles.unbold)} >Offre sans engagement</span>
                            </div>
                            */}
	                     </div>

                       </> ,

         description:  <>

                         <div className={classNames(styles.tab)}>

                            <div className={classNames(styles.tab_1_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_1_2)}>
                               Vidéo sans coupure pub
                            </div>

                            <div className={classNames(styles.tab_2_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_2_2)}>
	                           Accès aux vidéos avant la sortie sur Youtube
                            </div>

                            <div className={classNames(styles.tab_3_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_3_2)}>
	                           Accès au live et découvrez des programmes exclusifs
                            </div>

                            <div className={classNames(styles.tab_4_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_4_2)}>
	                           Posez vos questions pendant les lives privées
                            </div>

                            <div className={classNames(styles.tab_5_1)}>
                               {no()}
	                        </div>

                            <div className={classNames(styles.tab_5_2)}>
	                           Accès aux offres  <br/>
  	                           &laquo;&nbsp;MasterClasses Lovelight Urban Tour&nbsp;&raquo; <br/>
		                       <span className={classNames(styles.bold_italic)}>(abonnement annuel premium remboursé à l'achat de la première commande d'une Masterclass Urban Tour)</span>
                            </div>

                            <div className={classNames(styles.tab_6_1)}>
                               {no()}
	                        </div>

                            <div className={classNames(styles.tab_6_2)}>
	                           Invitation aux événements Lovelight
                            </div>

                         </div>

                         <br/>

	                     {button ("Je m'abonne",handleButtonClick2)}

                       </> ,

         link: "",
       },
       {
         title:        <>

 	                     <div className={classNames(styles.offer_super_title)} >Offre Premium</div>
	                     <br/>
	                     <div className={classNames(styles.offers)} >
  	                        <div className={classNames(styles.annual)} >
	                           <div className={classNames(styles.price)} >9,90 €/mois</div>
	                           <div className={classNames(styles.price)} >Offre annuelle</div>
	                           <span className={classNames(styles.unbold)} >1 paiement de 119 €</span>
                            </div>
                            {/*
                            <div className={classNames(styles.mensual)} >
	                           <div className={classNames(styles.price)} >12,90 €/mois</div>
	                           <div className={classNames(styles.price)} >Offre mensuelle</div>
	                           <span className={classNames(styles.unbold)} >Offre sans engagement</span>
                            </div>
                            */}
	                     </div>

                       </> ,

         description:  <>

                         <div className={classNames(styles.tab)}>

                            <div className={classNames(styles.tab_1_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_1_2)}>
	                           Vidéo sans coupure pub
                            </div>

                            <div className={classNames(styles.tab_2_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_2_2)}>
	                           Accès aux vidéos avant la sortie sur Youtube
                            </div>

                            <div className={classNames(styles.tab_3_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_3_2)}>
	                           Accès au live et découvrez des programmes exclusifs
                            </div>

                            <div className={classNames(styles.tab_4_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_4_2)}>
	                           <div className={classNames(styles.boldText)} >
							      Posez vos questions pendant les lives privées
							   </div>
                            </div>

                            <div className={classNames(styles.tab_5_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_5_2)}>
	                           Accès aux offres  <br/>
  	                           &laquo;&nbsp;MasterClasses Lovelight Urban Tour&nbsp;&raquo; <br/>
		                       <span className={classNames(styles.bold_italic)}>(abonnement annuel premium remboursé à l'achat de la première commande d'une Masterclass Urban Tour)</span>
                            </div>

                            <div className={classNames(styles.tab_6_1)}>
                               {yes()}
	                        </div>

                            <div className={classNames(styles.tab_6_2)}>
	                           <div className={classNames(styles.boldText)} >Invitation aux événements Lovelight</div>
                            </div>

                         </div>

                         <br/>

	                     {button ("Je m'abonne",handleButtonClick2b)}

                       </> ,

         link: "",
       }
   ]


   return (

        <div className={classNames(styles.part1)}>

          <div className={classNames(styles.contain)}>
		  
            <div className="w-full rounded-md flex md:justify-center relative overflow-hidden">

              <div className="p-4 max-w-9xl  mx-auto relative z-10  w-full pt-20 md:pt-20">

                <h1 className="text-4xl md:text-7xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 bg-opacity-50">
                  La plateforme de la Photo et de l'Audiovisuel.
                </h1>

    		    <p className="mt-4 font-normal text-4xl text-neutral-300 max-w-lg text-center mx-auto">
                  Explorez - Apprenez - Créez
                </p>

		        <br/>

                <div className="max-w-7xl mx-auto px-8">
                  <HoverEffect items={offers} />
                </div>

              </div>

            </div>

          </div>

        </div>
   );

};

export default Part01;

