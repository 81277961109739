import   React                            ,
       { useState                         ,
	     useEffect                        }  from 'react';
		 
import { useNavigate                      }  from 'react-router-dom';
		 
import { Dispatch                         }  from 'redux';
import { useDispatch                      }  from 'react-redux';

import { format } from 'date-fns';


import   classNames                          from 'classnames';

import   styles                              from './subscription_termination.module.scss';

import { Window                           }  from '../../../../widget/window/window';
import { WidgetFrame                      }  from '../../../../widget/widget_frame/widget_frame';

import { useTypedSelector                 }  from '../../../../../datas/useTypeSelector';

import { patchUserAccountClosure          ,
         patchUserCurrentOfferTermination }  from '../../../../../network/api_user';

import { initAuthentication               }  from '../../../../../datas/authenticationManagment/actionCreators';

import { initUser                         ,
         setUser                          }  from '../../../../../datas/userManagment/actionCreators';

import { INTERNAL_LINKS                   }  from '../../../../../network/urls';

export const SubscriptionTermination = () => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
  const { user           } = useTypedSelector ( (state) => state.userManagment );

  const [ showWindow     , setShowWindow     ] = useState ( false );
  const [ windowMessage  , setWindowMessage  ] = useState ( <></> );

  const [ showWindow2    , setShowWindow2    ] = useState ( false );
  const [ windowMessage2 , setWindowMessage2 ] = useState ( <></> );

  const [ reinitNeed     , setReinitNeed     ] = useState ( false );

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  const switchWidthLimit = 870;

  const handleResize = () => {
    setIsSmallScreen ( window.innerWidth <= switchWidthLimit );
  };

  useEffect(() => {

    if ( authentication.login === "" || 
         authentication.token === "" )
     {
	  dispatch ( initUser () )

      navigate (`${INTERNAL_LINKS.Login}`);
     }

  }, []);

  useEffect(() => {


  }, []);

  const makeSubscriptionTermination = async () => {

    const response = await patchUserCurrentOfferTermination ( authentication.token ,
                                                              user.id              )
    switch ( response.code )
	 {
      case 1    : // L'offre découverte sera activée à la fin de votre offre actuel le **'

                  dispatch ( setUser   ( user.id                                   ,
                                         user.email                                ,
                                         user.newEmail                             ,
								         user.firstname                            ,
								         user.lastname                             ,
								         user.sex                                  ,
								         user.birthday                             ,
								         user.address                              ,
								         user.address2                             ,
								         ""                                        ,
								         user.city                                 ,
								         user.country                              ,
								         user.phoneNumber                          ,
								         response.status                as string  ,
								         response.statusDeadline        as Date    ,
								         response.nextStatus            as string  ,
								         response.nextStatusRequestDate as Date    ,
                                         user.state                                ) )

                  let messageDate: string = ", soit le "

                  if ( typeof (response.statusDeadline) !== "undefined" && response.statusDeadline !== null )
                   {
                    messageDate = `, soit le ${format(response.statusDeadline as Date, 'dd/MM/yyyy')}`
                   }

                  setWindowMessage2 (<>La demande de résilitation de votre abonnement {user.status} est enregistrée<br/>Elle sera effective à la date de fin de votre abonnement actuel{messageDate}</>);
                  setShowWindow2    (true)

                  break;

	  case -102 : setWindowMessage2 (<>Votre sessions a expiré, veuillez vous reconnecter</>);
	              setShowWindow2    (true)
  	              setReinitNeed (true);

				  break;

      default :   setWindowMessage2 (<>Une erreur réseau est survenue lors de la demande de résiliation de votre offre courante</>);
                  setShowWindow2    (true)
	 }
  }

  const handleSubscriptionTerminationButtonClick = () => {

    setWindowMessage (<>Etes-vous sûr(e) de vouloir résilier votre abonnement {user.status} ?</>);
	setShowWindow    (true)
  }

  const handleValidButtonClick = () => {

    setWindowMessage (<></>)
	setShowWindow    (false)

    makeSubscriptionTermination ()
  }

  const handleCancelButtonClick = () => {

    setWindowMessage (<></>)
	setShowWindow    (false)
  }

  const handleCloseButtonClick2 = () => {

    setWindowMessage2 (<></>)
	setShowWindow2    (false)

    if ( reinitNeed )
     {
	  dispatch ( initUser   () );
	  dispatch ( initAuthentication (true) );
      navigate ( `${INTERNAL_LINKS.Home}` );
     }
  }


  return (
            
           <div className = { classNames(styles.root) } >

             {
			    showWindow  &&
                (
				  < Window message               = { windowMessage           }
				           onClickOnValidButton  = { handleValidButtonClick  }
				           onClickOnCancelButton = { handleCancelButtonClick }
				           cancelButtonName      = {"NON"}
                           validButtonName       = {"OUI"}
			               opaqueBackgroundColor = "#333333"/>
				)				
		     }
			   
             {
			    showWindow2  &&
                (
				  < Window message               = { windowMessage2          }
				           onClickOnCloseButton  = { handleCloseButtonClick2 }
                           opaqueBackgroundColor = "#333333"/>
				)
		     }



			 {
			   ( user.nextStatus !== "Discover" )
               ?
               (
                <>
		          <p className = { classNames(styles.title) } >
                    RESILIER L'ABONNEMENT
                  </p>

			      <br/>

                  <div className={classNames(styles.button)}>

				     <button className = "px-6 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                             onClick   = {handleSubscriptionTerminationButtonClick}>
                        Demander la résilisation de l'abonnement en cours
                     </button>

                  </div>
                </>
               )
               :
               (
                <>
		          <p className = { classNames(styles.title_2) } >
				    {
					 ( user.nextStatusRequestDate !== null )
					 ?
					 (
                      <>
					    UNE DEMANDE DE RESILIATION A ETE RECUE LE {format(user.nextStatusRequestDate as Date, 'dd/MM/yyyy')}
					  </> 
                     )
                     :
                     (
                      <>
					    UNE DEMANDE DE RESILIATION A ETE RECUE
					  </> 
					 )					 
					}
                  </p>

			      <br/>

                  <div className = {classNames(styles.blank_text,styles.centered)}>
                     Rendez-vous dans l'espace abonnements et services pour annuler cette demande.
                  </div>
                </>
               )
             }

           </div>
           
         );
};


export default SubscriptionTermination;
