import   React       ,  
       { useState    ,
	     useEffect   ,
		 useRef      }  from 'react';

import { Dispatch    }  from 'redux';
import { useDispatch }  from 'react-redux';
import { useTypedSelector               }  from '../../../../../datas/useTypeSelector';

import   classNames     from 'classnames';

import   styles         from './video_thumbnail.module.scss';

import { Video       }  from '../../../../../datas/videoManagment/actionTypes';

import { VideoSheet  }  from './video_sheet';
import { VideoZoom   }  from './video_zoom';

import { WWW_URLS    }  from '../../../../../network/urls';
import { isTokenValid }  from '../../../../../network/utils'; 

import { postEvent   }  from '../../../../../network/api_events_stats';

export interface VideoThumbnailProps {
  video:       Video;
  showZoom:  ( video:  Video  ,
               width:  number ,
			   height: number ,
			   top:    number ,
			   left:   number ) => void
  showSheet: ( video:  Video  ) => void
  showAlertWindowDeprecatedToken: () => void;
}

export const VideoThumbnail = ({ video     ,
                                 showZoom  ,
								 showSheet                      ,
								 showAlertWindowDeprecatedToken }: VideoThumbnailProps) => {

/*
  const [ heightDiv  , setHeightDiv  ] = useState (  0  );
  const [ widthDiv   , setWidthDiv   ] = useState (  0  );
  const [ topDiv     , setTopDiv     ] = useState (  0  );
  const [ leftDiv    , setLeftDiv    ] = useState (  0  );
*/

  const [isMobileView, setIsMobileView] = useState(false);


  const { authentication } = useTypedSelector((state) => state.authenticationManagment );

  let heightDiv:number = 0;
  let widthDiv:number  = 0;
  let topDiv:number    = 0;
  let leftDiv:number   = 0;
  let scrollY:number   = 0;
  
  let isHovered: boolean = false;
  
  const urlImage = `${WWW_URLS.Thumbnail}${video.imageFile}.webp`;
  
  const divRef = useRef<HTMLDivElement>(null);

  let timeoutId: ReturnType<typeof setTimeout>;


  // Fonction pour mettre à jour l'état en fonction de la largeur d'écran
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 400) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    // Détecter la taille initiale
    handleResize();

    // Ajouter un écouteur pour les redimensionnements
    window.addEventListener('resize', handleResize);

    // Nettoyage de l'écouteur lors de la destruction du composant
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseEnter = () => {

    testEnter ()
  };


  const handleClick = () => {
  
    testEnter ()
  }

  const testEnter = () => {

    isHovered = true;

    if ( divRef.current ) 
	 {
      const { width, height, top, left } = divRef.current.getBoundingClientRect();

	  heightDiv = height;
	  widthDiv  = width;
	  topDiv    = top  + window.scrollY;
	  leftDiv   = left + window.scrollX;
	  scrollY   = window.scrollY;
	  
      timeoutId = setTimeout(() => {
 
	    if ( isHovered && scrollY === window.scrollY ) 
		 {
          const eventMessage: string = `Replay-VideoThumbnail:${video.title}`

          postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
		 
		 
          showZoom ( video      ,
		             widthDiv   ,
					 heightDiv  ,
					 topDiv     ,
					 leftDiv    );
         }
		 
      }, 500);
     }
  };


  const handleMouseLeave = () => {
  
    isHovered = false;
    clearTimeout(timeoutId);
  };
 
  const handleWheelMove = () => {
  
  }
 

const customStyles: React.CSSProperties = {

  position:   'absolute'    ,
  top:        topDiv + heightDiv - 60 + 'px',
  left:       leftDiv + 'px',
  zIndex:     45            ,
  background: 'transparent' ,
  border:     'none'        ,
  color:      'white' 

};
 
  return (


            <div ref          = {divRef} 
			     onWheel      = {handleWheelMove}
				 onClick      = {handleClick}
			     onMouseOver  = {handleMouseEnter} 
	             onMouseOut   = {handleMouseLeave}
				 className    = {classNames(styles.root, styles.zoom_effect)} >
		   
              <div className    = {classNames( styles.rel , isMobileView ? styles.size2 : styles.size1 )} >
							
	            <img src       = { urlImage                 }
			         alt       = { video.imageFile          }
			         className = { classNames(isMobileView ? styles.size2 : styles.size1) } />
			 
 		        {
	             video.locked &&
		         (
                  <img style = {{ position: 'absolute' ,
				                  bottom:   '0px'    ,
                                  left:     '0px'      ,
								  width:    '50px'     ,
								  height:   '50px'     }}
			  	       src   = "https://lovelightfactory.fr/images/icons/locked.svg"
 		               alt   = "locked" />
                 )
	            }
 
              </div>
			  
            </div>
           
         );
};


export default VideoThumbnail;
