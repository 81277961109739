import   React                                  , 
       { useState                               ,
	     useEffect                              }  from 'react';

import   classNames                                from 'classnames';

import { useLocation                            }  from 'react-router-dom';

import { Dispatch                               }  from 'redux';
import { useDispatch                            }  from 'react-redux';

import   styles                                    from './replay.module.scss';

import { GlobalMenu                             }  from '../../menu/global/global';
import { Footer                                 }  from '../../menu/footer/footer';
import { TopShifter                             }  from '../../menu/top_shifter/top_shifter';
import { CookiesManager                         }  from '../../cookie/manager';

import { VideoCatalog                           }  from './blocks/catalog/video_catalog';
import { HightlightedElements                   }  from './blocks/highlighted_elements/highlighted_elements';

import { setCatalog                             ,
         setBroadcastingChannelPackage          ,
         setCurrentlyPlayingBroadcastingChannel }  from '../../../datas/videoManagment/actionCreators';

import { useTypedSelector                       }  from '../../../datas/useTypeSelector';

import { getCatalogInfos                        ,
         CatalogInfos                           ,
         BroadcastingChannelPackageInfos        ,
         getBroadcastingChannelPackageInfos     }  from '../../../network/api_video';

import { postEvent                              }  from '../../../network/api_events_stats';

import { retrieveUserAgentInformations          }  from '../../../network/utils';	


export const Replay = () => {

  const location = useLocation();
  const dispatch: Dispatch<any> = useDispatch()
  
  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
  const { user           } = useTypedSelector ( (state) => state.userManagment   );
  const { catalog        } = useTypedSelector ( (state) => state.videoManagment );

  const [viewerVisible, setViewerVisible] = useState(true);
  const [version, setVersion] = useState(0);

    const infos = retrieveUserAgentInformations ()

  var catalogInfos: CatalogInfos = {
      code:    0 ,
	  comment: "" ,
      catalog: {
        categories: []
      }
    };  

  var broadcastingChannelPackageInfos: BroadcastingChannelPackageInfos = {
      code:    0 ,
	  comment: "" ,
      broadcastingChannelPackage: {
        channels: []
      }
    };

  useEffect(() => {

/*
    console.log ( "useEffect de Program")

    var identifiant = location.hash.substring(1);
   
     if ( identifiant === "" )
      {   
	   identifiant = "accueil";
      }

     var element = document.getElementById( identifiant );

     if ( identifiant !== "accueil" )
           {
            if ( element !== null )
             {	 
              element.scrollIntoView (); //({ behavior: 'smooth', block: "end", inline: "nearest" })	 
  	         }
	       }
	  else {
            if ( element !== null )
             {	 
	          element.scrollTo(0, 70);
	         }
	       }
		 

    //	 init ();
	*/
	
  }, [catalogInfos, viewerVisible]); 


  useEffect(() => {

    window.scroll(0, 0);

    const infos = retrieveUserAgentInformations ()

    if ( window.scrollY > 600 )
		  {
		   setViewerVisible (false)
	 	  }
	 else {
           if ( infos.os.name !== 'iOS' )
            {
		     setViewerVisible (true)
			}
		  }
		 
	postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

    init ();

  }, [] );

  const init = async () => {
  
    let status : number = -1; // Non connecté
  
    if ( ( authentication.login !== ""   &&
           authentication.token !== "" ) )
     {
      status = 0;	 
		   
       if ( user.status  === "Standard" )  { status = 1 }
       if ( user.status  === "Premium"  )  { status = 2 }
     }
	 
    catalogInfos = await getCatalogInfos ( status );

	dispatch ( setCatalog ( catalogInfos.catalog ) );

    // Le paramètre n'est pas utilisé pour le moment

    broadcastingChannelPackageInfos = await getBroadcastingChannelPackageInfos ( ( authentication.login !== ""   &&
                                                                                   authentication.token !== "" ) &&
                                                                                 ( user.status  === "Premium" ) );

	dispatch ( setBroadcastingChannelPackage ( broadcastingChannelPackageInfos.broadcastingChannelPackage ) );

	dispatch ( setCurrentlyPlayingBroadcastingChannel ( broadcastingChannelPackageInfos.broadcastingChannelPackage.channels[0] ) )
  }

  const handleWheelMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			
			
    if ( window.scrollY > 600 )
		  {
		   setViewerVisible (false)
	 	  }
	 else {
           if ( infos.os.name !== 'iOS' )
            {
		     setViewerVisible (true)
			}
		  }
  }

  return (
            <>
		      
 		      <CookiesManager />
 			  <GlobalMenu />
              <TopShifter/>

              <div className = {classNames(styles.root)} 
                   onWheel   = {handleWheelMove}>
			  
               {/*
                 ( infos.engine.name !== 'Blink' || infos.engine.version !== '130.0.0.0' )
				  ?
                 (					
		           <VideoCatalog/>
			     )
				 :
				 (
				   <div className = {classNames(styles.alert)} >
				     Suite à la dernière mise à jour de votre navigateur, cette page est inacessible momentanément.<br/>
					 Vous pouvez cependant la visualiser avec le navigateur Safari ou Firefox.
				   </div>
				 )
			  */ }
			   
			   
			    <VideoCatalog/>
              </div>			 

			  <Footer/>

            </>
        );
};

export default Replay;
