import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './topten.module.scss';

import RankedThumbnail from './ranked_thumbnail';
import { useTypedSelector } from '../../../../../datas/useTypeSelector';
import { Video } from '../../../../../datas/videoManagment/actionTypes';

import { Carousel } from '../../../../widget/carousel/carousel';

export interface TopTenProps {
  showSheet: (video: Video) => void;
  showZoom: (video: Video, width: number, height: number, top: number, left: number) => void;
  showAlertWindowDeprecatedToken: () => void;
}

export const TopTen = ({
  showSheet,
  showZoom,
  showAlertWindowDeprecatedToken,
}: TopTenProps) => {
  const { catalog } = useTypedSelector((state) => state.videoManagment);
  const [first, setFirst] = useState(false);
  const [rankedVideoList, setRankedVideoList] = useState<Video[]>([]);
  const [nbSlides, setNbSlides] = useState(3);

  // Fonction pour récupérer et trier les vidéos en évitant les doublons
  const getUniqueVideosSortedByViews = (): Video[] => {
    let allVideos: Video[] = [];
    const videoIdsSet = new Set<number>();

    catalog.categories.forEach((category) => {
      category.videoSets.forEach((videoSet) => {
        videoSet.videos.forEach((video) => {
          if (!videoIdsSet.has(video.id)) {
            allVideos.push(video);
            videoIdsSet.add(video.id);
          }
        });
      });
    });

    return allVideos.sort(
      (a, b) =>
        b.numberOfViews + b.numberOfViewsFromYoutube - (a.numberOfViews + a.numberOfViewsFromYoutube)
    );
  };

  // Fonction d'initialisation
  const init = () => {
    const list = getUniqueVideosSortedByViews();
    setRankedVideoList(list);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1110) setNbSlides(1);
      else if (window.innerWidth < 1630) setNbSlides(2);
      else if (window.innerWidth < 2155) setNbSlides(3);
      else if (window.innerWidth < 2680) setNbSlides(4);
      else if (window.innerWidth < 3225) setNbSlides(5);
      else if (window.innerWidth < 3742) setNbSlides(6);
      else if (window.innerWidth < 4020) setNbSlides(7);
      else setNbSlides(8);
    };

    if (!first) {
      setFirst(true);
      handleResize();
      init();
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [first]);

  // Génération des vignettes
  const generateThumbnails = (): JSX.Element[] => {
    const thumbnails: JSX.Element[] = [];

    for (let i = 0; i < Math.min(10, rankedVideoList.length); i++) {
      thumbnails.push(
        <div key={i}>
          <RankedThumbnail
            rank={i + 1}
            video={rankedVideoList[i]}
            showSheet={showSheet}
            showZoom={showZoom}
            showAlertWindowDeprecatedToken={showAlertWindowDeprecatedToken}
          />
        </div>
      );
    }

    return thumbnails;
  };

  return (
    <div className={classNames(styles.root)}>
      <p className={classNames(styles.title)}>Top 10 des vidéos</p>

      <div className={classNames(styles.carousel)}>
        {/* Utilisation du nouveau composant Carousel */}
		<Carousel items={generateThumbnails()} itemWidth={530} />
      </div>
    </div>
  );
};

export default TopTen;

//        <Carousel items={generateThumbnails()} itemWidth={530} />
