import   React                                            from 'react';

import   classNames                                       from 'classnames';

import   styles                                           from './leaflet_personnal_datas_information.module.scss';

import { Leaflet                                       }  from '../leaflet/leaflet';
import { LeafletPersonnalDatasInformation_Informations }  from '../legal_data/legal_data';

export const LeafletPersonnalDatasInformation = () => {

  return (

        <>

          <div className={classNames(styles.root)}>

            <Leaflet block = {LeafletPersonnalDatasInformation_Informations} />
        
          </div>
        
        </>

            
  );
};

export default LeafletPersonnalDatasInformation;
