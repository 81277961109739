import { Action         , 
         ActionType     ,
		 Authentication }  from './actionTypes';


interface State 
 {
  authentication: Authentication;
 }

const initialState = 
 {
  authentication: 
   {
    login:            "" ,
    token:            "" ,
	numberOfAttempts: 0,
   } 
 }

const AuthenticationManagmentReducer = (state: State = initialState, action: Action):State => 
 {
  switch(action.type) 
   {
    case ActionType.INIT_AUTHENTICATION: state.authentication.login            = ""
                                         state.authentication.token            = ""
										 
										 if ( action.complete )
										  {
										   state.authentication.numberOfAttempts = 0
                                          }
										  
										 return state;

    case ActionType.SET_LOGIN:           state.authentication.login = action.login

                                         return state;
									 
    case ActionType.SET_TOKEN:           state.authentication.token = action.token

                                         return state;

    case ActionType.COUNT_ATTEMPT:       state.authentication.numberOfAttempts = state.authentication.numberOfAttempts + 1

                                         return state;
									 
    default:                             return state;
   }
 }


export default AuthenticationManagmentReducer;