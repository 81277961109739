import   React              ,  
       { useState           }  from 'react';

import   classNames            from 'classnames';

import   styles                from './bank_choice.module.scss';

import { Trigger            }  from '../../widget/trigger/trigger';
import { Window             }  from '../../widget/window/window';
import { Toggle             }  from '../../widget/toggle/toggle';

  
import { INTERNAL_LINKS     }  from '../../../network/urls';
 
export interface BankChoiceProps {

  onCancelButtonClick: () => void,
  onValidButtonClick:  ( codeBank: string ) => void,
}

export const BankChoice = ({ onCancelButtonClick ,
                             onValidButtonClick  }: BankChoiceProps ) => {

  const [ selectedBankCode , setSelectedBankCode ] = useState ("02")

  const handleToggleStateChange = ( newState: boolean            ,
                                    id?:      number | undefined ) => {
	
    if ( typeof (id) !== "undefined" )
     {
	       if ( id === 1 && newState ) { setSelectedBankCode ("01") }
	  else if ( id === 2 && newState ) { setSelectedBankCode ("02") }
	  else                             { setSelectedBankCode ("00") }
	 }	
  }

  const handleCancelButtonClick = () => {
  
    onCancelButtonClick ()
  }
  
  const handleValidateButtonClick = () => {
  
    onValidButtonClick ( selectedBankCode )
  }

  return (
           <> 

		      <div className = { classNames(styles.root)} >

		        <div className = { classNames(styles.title)} >
				   Choisissez votre mode de règlement
		        </div>

		        <div className = { classNames(styles.grid)} >

		          <div className = { classNames(styles.grid_1_1)} >
		  
		  			<Toggle id       = { 1 }
					        form     = "radio button"             
			                onChange = { handleToggleStateChange } 
					        checked  = { selectedBankCode === "01" } />

                  </div>
				  
		          <div className = { classNames(styles.grid_1_2)} >
		  
			        <img src       = { `${INTERNAL_LINKS.Logo_Carte_bleue}` }
                         className = { classNames(styles.logo) }
                         alt       = "logo"/>

                    &nbsp;

			        <img src       = { `${INTERNAL_LINKS.Logo_Visa}` }
                         className = { classNames(styles.logo) }
                         alt       = "logo"/>

                    &nbsp;

			        <img src       = { `${INTERNAL_LINKS.Logo_Mastercard}` }
                         className = { classNames(styles.logo) }
                         alt       = "logo"/>

                    &nbsp;

			        <img src       = { `${INTERNAL_LINKS.Logo_Amex}` }
                         className = { classNames(styles.logo) }
                         alt       = "logo"/>

                  </div>
				  
		          <div className = { classNames(styles.grid_2_1)} >
		  
		  			<Toggle id       = { 2 }
					        form     = "radio button"             
			                onChange = { handleToggleStateChange } 
					        checked  = { selectedBankCode === "02" } />

                  </div>
				  
		          <div className = { classNames(styles.grid_2_2)} >
		  
			        <img src       = { `${INTERNAL_LINKS.Logo_PayPal}` }
                         className = { classNames(styles.logo) }
                         alt       = "logo"/>

                  </div>

		          <div className = { classNames(styles.grid_3)} >
			  
		            <div className={classNames(styles.buttons)}>

                      <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                              onClick   = {handleCancelButtonClick}>
                        Annuler
                      </button>

                      &nbsp;&nbsp;&nbsp;

                      <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                              onClick   = {handleValidateButtonClick}>
                        Valider
                      </button>
			 
			        </div>
				
		          </div>

	            </div>
				  
			  </div>
			  
           </>
         );
};


export default BankChoice;
