import   React                   from 'react';

import   classNames              from 'classnames';

import { Accordion            ,
         AccordionItem        ,
         AccordionItemHeading ,
         AccordionItemButton  ,
         AccordionItemPanel   }  from 'react-accessible-accordion';

import   styles                  from './leaflet.module.scss';

import { Block                }  from '../legal_data/legal_data';

export interface LeafletProps {
  block: Block     
}

export const Leaflet = ({ block }: LeafletProps) => {


  return (

        <>

          <div className={classNames(styles.root)}>

            <div className={classNames(styles.contain)}>

              <h1>{block.title}</h1>
		
              <br/>

              {block.introduction}
		
              <br/>
		
		      <Accordion allowZeroExpanded className={classNames(styles.accordion)}>

                { 
				 block.sections.map ( ( section ) => 
				  (
                   <AccordionItem className={classNames(styles.accordion__item)}>
                
				     <AccordionItemHeading className={classNames(styles.accordion__heading)}>
                       <AccordionItemButton className={classNames(styles.accordion__button)} >
					     {section.title}
                       </AccordionItemButton>
                     </AccordionItemHeading>
                
				     <AccordionItemPanel className={classNames(styles.accordion__panel)} >
					     {section.content}
                     </AccordionItemPanel>
            
			       </AccordionItem>
                  )
				 )
				}

              </Accordion>
		
		      <br/>
		
              {block.lastPart}

            </div>
            
          </div>
        
        </>
            
  );
};

export default Leaflet;
