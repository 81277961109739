import   React      from "react";
import   classNames from 'classnames';

import   styles     from './textarea.module.scss';

export interface TextAreaProps {
  classNameGrid?: string;                                                   // classe CSS complémentaire
  placeholder?:   string;                                                   // Le placeholder de l'input
  value?:         string;                                                   // La valeur de l'input
  error?:         string;                                                   // Le message d'erreur
  autocomplete?:  string;                                                   // Se remplit automatiquement
  required?:      boolean | undefined;                                      // Indispensable ou non
  readOnly?:      boolean | undefined;                                      // Editable ou non
  onChange?:      (event: React.ChangeEvent<HTMLTextAreaElement>) => void;  // Ajout d'une prop pour gérer le changement du textarea
}

export const TextArea = ({ classNameGrid , 
                           placeholder   , 
                           value         , 
                           error         , 
                           autocomplete  ,
                           required      ,
                           readOnly      ,
                           onChange      }: TextAreaProps) => {

  var completion = "new-password";

  if ( typeof (autocomplete) == 'string' )
   {
    if ( autocomplete === "on" )
     {
      completion = "on";
     }
   }
   
  return (
  
            <textarea className    = { classNames ( styles.textarea_input ) }
                      placeholder  = { placeholder                          }
                      value        = { value                                }
                      readOnly     = { readOnly                             }
                      onChange     = { onChange                             } 
                      autoComplete = { completion                           } />
           
         );
};

export default TextArea;
