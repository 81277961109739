import   axios                    ,
       { AxiosError               }  from 'axios';

import { format                   ,
         parseISO                 }  from 'date-fns';

import { Question                 }  from '../datas/hotlineQuestionManagment/actionTypes';

import { getUserAgentInformations ,
         getAppInformations       }  from './utils';

import { API_URLS                 }  from './urls';

export interface PostHotlineQuestionResult {
  code:    number,
  comment: string
}

export const postHotlineQuestion = async ( userId:   number ,
	                                       token:    string ,
                                           title:    string ,
							               device:   string ,
							               question: string ): Promise<PostHotlineQuestionResult> => {

    const userAgentInformations = getUserAgentInformations ();
    const appInformations       = getAppInformations ();

    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json',
        'Authorization': `Bearer ${token}`,
        'User-Agent-Informations': `${userAgentInformations}`,
        'App-Informations': `${appInformations}`,
    };

    var result:PostHotlineQuestionResult = {
      code:    0,
      comment: "",
    };

    try
     {
      const response = await axios.post (
        
                                          `${API_URLS.postHotlineQuestion}`,
                              
                                          {
                                            userId   ,
                                            title    ,
                                            device   ,
                                            question
                                          },
                                            
                                          {
                                            headers: headers
                                          }
                                        );

      switch ( response.status )
       {
        case 201 : result = {
                              code:    1 ,
                              comment: "Informations d'achat et de paiement créées" ,
                            };

                   break;
 
        default  : result = {
                              code:     -102 ,
                              comment: `Erreur lors de la création de la question ${response.status}`,
                            };
       }
    }  
    catch ( err ) {

        const error = err as AxiosError;

        if ( error.response && error.response.status )
              {
               switch ( error.response.status )
                {
                 case 401 : result = {
                                       code:    -102,
                                       comment: "Session expirée"
                                     };

                            break;

                 default :  result = {
                                       code:    -201,
                                       comment: "Erreur réseau"
                                     };
                }
              }
         else {
               result = {
                          code:    -201,
                          comment: "Erreur réseau"
                        };
              }
    }


    return result;
};

export interface HotLineQuestionsByEmail{
  code:      number,
  comment:   string,
  questions: Question []
}

export const getHotlineQuestionsByEmail = async ( email: string ,
                                                  token: string ) : Promise<HotLineQuestionsByEmail>  => {

    console.log ("getHotlineQuestionsByEmail")

    const baseUrl  = `${API_URLS.getHotlineQuestionsByEmail}?`;
    const nomInfo1 = "email=";
    const valInfo1 = encodeURIComponent (email);

    const url = `${baseUrl}${nomInfo1}${valInfo1}`;

    console.log ("getHotlineQuestionsByEmail url=", url)

    const userAgentInformations = getUserAgentInformations ();
    const appInformations       = getAppInformations ();

    const headers = {
          'Authorization': `Bearer ${token}`,
          'User-Agent-Informations': `${userAgentInformations}`,
          'App-Informations': `${appInformations}`,
    };

    var result:HotLineQuestionsByEmail = {
      code:      0 ,
      comment:  "" ,
      questions: []
    };

    try {

      const response = await axios.get ( url, { headers: headers } );

    console.log ("getHotlineQuestionsByEmail response=", response)

      const statut = response.status;

      switch ( statut )
       {
        case 200 : const rawInfos = response.data['hydra:member']

                   let questions: Question[] = [];

                   if ( Array.isArray (rawInfos) ) 
                    {
                     questions = rawInfos.map ( (info: any) => {

                       return {
                                id:             info.id,
                                submissionDate: info.submissionDate ,
                                title:          info.title ,
                                device:         info.device ,
                                question:       info.question ,
                                status:         info.status ,
                                response:       info.response ,
                                responseDate:   info.responseDate  

                              };
                       } );
                    }

                   result = {
                              code:      1,
                              comment:   "Récupération des hotline questions de l'utilisateur",
                              questions: questions
                            }

                   break;


        default :  result = {
                              code:    -102,
                              comment: `Erreur lors de la récupération des hotline questions de l'utilisateur : ${response.status}`,
                              questions: []
                            };
       }
    }
    catch ( err ) {

        const error = err as AxiosError;

      console.log (error)

        if ( error.response && error.response.status )
              {
               switch ( error.response.status )
                {
                 case 401 : result = {
                                       code:    -102,
                                       comment: "Session expirée",
                                        questions: []
                                     };

                            break;

                 default :  result = {
                                       code:    -201,
                                       comment: "Erreur réseau",
                                        questions: []
                                     };
                }
              }
         else {
               result = {
                          code:    -201,
                          comment: "Erreur réseau",
                           questions: []
                        };
              }
    }

    return result;
  };



export interface PatchHotlineQuestionResult {
  code:    number,
  comment: string
}

export const patchHotlineQuestion = async ( token:    string ,
											id:       number ,
                                            title:    string ,
                                            device:   string ,
                                            question: string ): Promise<PatchHotlineQuestionResult> => {

    const baseUrl =  `${API_URLS.patchHotlineQuestion}`; 

    const url = baseUrl.replace("{id}", `${id}`);

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
        'Authorization': `Bearer ${token}`,
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };

    console.log (url)

	var result:PatchHotlineQuestionResult = {
      code:    0,
      comment: ""
    };
	
    try {

        const response = await axios.patch (
                                                 url,
                              
                                               { 
                                                 title    ,
                                                 device   ,
                                                 question           												 
                                               },
                                            
                                               {
                                                 headers: headers
                                               }
                                           );
									
    console.log (response)
									
        switch ( response.status )
	     {
	      case 200 : result = {
		                        code:    1,
		                        comment: "Hotline question modifiée"
		                      };
			  
			         break;
				   
          default :  result = {
		                        code:    -101 ,
		                        comment: `Erreur lors de la modification de la hotline question : ${response.status}`
		                      };
	     }

    } 
    catch ( err ) {

        const error = err as AxiosError;

        if ( error.response && error.response.status )
	          {
               switch ( error.response.status )
		        {
		         case 401 : result = {
		                               code:    -102,
		                               comment: "Session expirée"
		                             };
							
				            break;

                 default :  result = {
		                               code:    -201,
		                               comment: "Erreur réseau"
		                             };
                }
              }
         else {
	           result = {
		                  code:    -201,
		                  comment: "Erreur réseau"
		                };
	          }	   
    }

	return result;
};
