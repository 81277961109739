import   React             ,
       { PropsWithChildren , 
         ReactNode         }  from 'react';
		 
type PanelProps = {
  className?: string; 
  name:       string; 
  children:   ReactNode;
};


export const Panel = ({ className , 
                        name      , 
                        children  }:  PropsWithChildren<PanelProps> ) => {

 return (
          <div>
          
           {children}    
		 
          </div>
        )
};

export default Panel;
