import React, { useState } from 'react';
import classNames from 'classnames';

import styles from './window.module.scss';

import { WidgetFrame } from '../widget_frame/widget_frame';
import { WidgetFrameLabel } from '../widget_frame/widget_frame_label';
import { Toggle } from '../toggle/toggle';

export interface WindowWithConfirmationProps {
  message: JSX.Element;
  messageType?: string;
  confirmationMessage: JSX.Element;
  onClickOnCloseButton?: (() => void) | undefined;
  onClickOnCancelButton?: (() => void) | undefined;
  onClickOnValidButton?: (() => void) | undefined;
  closeButtonName?: string;
  cancelButtonName?: string;
  validButtonName?: string;
  opaqueBackgroundColor?: string;
}

export const WindowWithConfirmation = ({
  message,
  messageType,
  confirmationMessage,
  onClickOnCloseButton,
  onClickOnCancelButton,
  onClickOnValidButton,
  closeButtonName,
  cancelButtonName,
  validButtonName,
  opaqueBackgroundColor,
}: WindowWithConfirmationProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmationChecked, setConfirmationChecked] = useState(false);

  // Gestion du clic sur le bouton "Fermer"
  const handleCloseButtonClick = () => {
    if (confirmationChecked) {
      setErrorMessage('');
      onClickOnCloseButton?.();
    } else {
      setErrorMessage('Validation obligatoire');
    }
  };

  // Gestion du clic sur le bouton "Annuler"
  const handleCancelButtonClick = () => {
    onClickOnCancelButton?.();
  };

  // Gestion du clic sur le bouton "Valider"
  const handleValidButtonClick = () => {
    if (confirmationChecked) {
      setErrorMessage('');
      onClickOnValidButton?.();
    } else {
      setErrorMessage('Validation obligatoire');
    }
  };

  // Génération de la classe en fonction du type de message
  const generateClassName = (): string => {
    switch (messageType) {
      case 'alert':
        return classNames(styles.redMessage);
      case 'ack':
        return classNames(styles.greenMessage);
      case 'normal':
      default:
        return classNames(styles.whiteMessage);
    }
  };

  // Gestion du changement de la case à cocher
  const handleConfirmationCheckedChange = (newConfirmation: boolean) => {
    setConfirmationChecked(newConfirmation);
    setErrorMessage(newConfirmation ? '' : 'Validation obligatoire');
  };

  // Styles de la fenêtre avec couleur d'arrière-plan
  const windowStyle = opaqueBackgroundColor
    ? { backgroundColor: opaqueBackgroundColor }
    : {};

  // Vérifie si les boutons doivent être empilés
  const isStacked = typeof window !== 'undefined' && window.innerWidth < 480;

  return (
    <div className={classNames(styles.window)} style={windowStyle}>
      {/* Message */}
      <div className={classNames(generateClassName(), styles.message)}>
        {message}
      </div>

      <br />

      {/* WidgetFrame avec la confirmation */}
      <WidgetFrame
        error={errorMessage}
        classNameGrid={classNames(styles.grid_confirmation)}
        labelBlockPosition="right"
      >
        <WidgetFrameLabel className={styles.text_confirmation}>
          {confirmationMessage}
        </WidgetFrameLabel>

        <Toggle
          checked={confirmationChecked}
          onChange={handleConfirmationCheckedChange}
          form="checkbox"
        />
      </WidgetFrame>

      <br />

      {/* Boutons d'action */}
      <div
        className={classNames(
          styles.buttons_block,
          isStacked && styles.stacked
        )}
      >
        {onClickOnCloseButton && (
          <button
            className={styles.button}
            onClick={handleCloseButtonClick}
          >
            {closeButtonName || 'Fermer'}
          </button>
        )}

        {onClickOnCancelButton && (
          <button
            className={styles.button}
            onClick={handleCancelButtonClick}
          >
            {cancelButtonName || 'Annuler'}
          </button>
        )}

        {onClickOnValidButton && (
          <button
            className={styles.button}
            onClick={handleValidButtonClick}
          >
            {validButtonName || 'Valider'}
          </button>
        )}
      </div>
    </div>
  );
};

export default WindowWithConfirmation;
