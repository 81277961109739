import   React                       , 
       { useEffect                   ,
         useState                    }  from 'react';

import   classNames                     from 'classnames';


import { Link                        ,
         useLocation                 ,
         useNavigate                 }  from 'react-router-dom';

import { Dispatch                    }  from 'redux';
import { useDispatch                 }  from 'react-redux';

import   styles                         from './login.module.scss';

import { Field                       }  from '../../widget/field/field'; 
import { WidgetFrame                 }  from '../../widget/widget_frame/widget_frame';

import { GlobalMenu                  }  from '../../menu/global/global';
import { Footer                      }  from '../../menu/footer/footer';
import { CookiesManager              }  from '../../cookie/manager';
import { TopShifter                  }  from '../../menu/top_shifter/top_shifter';

import { WindowForgottenPassword     }  from './windows/forgotten_password/forgotten_password';

import { initAuthentication          ,
         setLogin                    , 
         setToken                    ,
		 countAttempt                }  from '../../../datas/authenticationManagment/actionCreators';
         
import { Validation                  ,
         checkEmail                  ,
         checkPassword               }  from '../../../datas/authenticationManagment/validators';

import { setUser             ,
         addPayment                  }  from '../../../datas/userManagment/actionCreators';

import { setCatalog                  }  from '../../../datas/videoManagment/actionCreators';

import { setProductCatalog           }  from '../../../datas/productManagment/actionCreators';

import { setCart                     }  from '../../../datas/cartManagment/actionCreators';

import { useTypedSelector            }  from '../../../datas/useTypeSelector';

import { postUserAuthentication      ,
         getUserInfos                ,
         getUserEmailValidation      ,
         getUserEmailValidationCheck }  from '../../../network/api_user';

import { getCatalogInfos             }  from '../../../network/api_video';
import { getProductCatalogInfos      }  from '../../../network/api_product';
		 
import { mergeWithUserCart           ,
         getCart                     }  from '../../../network/api_cart';
		 
import { getPaymentInfos             ,
         getDetailledPaymentInfos    }  from '../../../network/api_payment';

import { postBannedIpAddress         ,
         getBannedIpAddressCheck     }  from '../../../network/api_banned_ip_addresses';
		 
import { INTERNAL_LINKS              }  from '../../../network/urls';

import { postEvent                   }  from '../../../network/api_events_stats';

export const Login = () => {
  
  const   dispatch: Dispatch<any> = useDispatch();
  const   navigate                = useNavigate();
  const   location                = useLocation();
  
  const { authentication }        = useTypedSelector((state) => state.authenticationManagment );
  const { cart           }        = useTypedSelector((state) => state.cartManagment );
    
  const [ email                 , setEmail                 ] = useState('');
  const [ password              , setPassword              ] = useState('');
  
  const [ messageEmail          , setMessageEmail          ] = useState ('');
  const [ messagePassword       , setMessagePassword       ] = useState ('');

  const [ showForgottenPassword , setForgottenPassword     ] = useState(false);
  const [ showErrorMessage      , setShowErrorMessage      ] = useState(0);
  const [ showEmailCheckMessage , setShowEmailCheckMessage ] = useState(0);
  const [ showBanMessage        , setShowBanMessage        ] = useState(false);

  const [ emailCheckDate        , setEmailCheckDate        ] = useState('');
  const [ emailCheck            , setEmailCheck            ] = useState(false);

  const [ version               , setVersion               ] = useState(0);

  const [ showWaitMessage       , setShowWaitMessage       ] = useState (false);

  useEffect(() => {

    window.scroll(0, 0);

    console.log (document.referrer)

    if ( authentication.login !== "" &&
         authentication.token !== "" )
          {
		   
		  
           navigate ( `${INTERNAL_LINKS.Replay}` );
          }
     else {
           if ( !emailCheck )
            {
             const token = new URLSearchParams(location.search).get("token");
   
             console.log (token)

             if ( token )
              {
               analyseToken ( token! )
			  }
            }
          }            

  }, [ authentication.login            , 
       authentication.token            , 
       authentication.numberOfAttempts , 
       emailCheck                      , 
       emailCheckDate                  , 
       showEmailCheckMessage           , 
       navigate                        , 
       location.search                 ,
	   showWaitMessage] ); 
  
  useEffect(() => {
  
    console.log (document.referrer)

    if ( authentication.login !== "" &&
         authentication.token !== "" )
          {
	       postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );
		   
           navigate ("/grille");
          }
     else {
	       postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , '' );
		   
           if ( !emailCheck )
            {
             const token = new URLSearchParams(location.search).get("token");
   
             console.log (token)
   
             if ( token )
              {
               analyseToken ( token! )
			  }
            }
          }            

  }, [] );
  
  const analyseToken = async (token: string) => {

    if ( token )
     {
      const userEmailValidationResult = await getUserEmailValidation (token)

      console.log ("analyseToken :" , userEmailValidationResult)
		
      switch ( 	userEmailValidationResult.code )
       {
        case 1    : setShowEmailCheckMessage ( userEmailValidationResult.code      )
                    setEmailCheckDate        ( userEmailValidationResult.checkDate )
                    setEmailCheck            ( true                                )
				    break;

        case 2    : setShowEmailCheckMessage ( userEmailValidationResult.code      )
                    setEmailCheckDate        ( userEmailValidationResult.checkDate )
                    setEmailCheck            ( true                                )
				    break;

        case -2   : setShowErrorMessage ( 5 );
				    setShowWaitMessage (false);

				    break;
				 
        case -101 : setShowErrorMessage ( 7 );
				    setShowWaitMessage (false);

				    break;
				 
        default   : setShowErrorMessage ( 6 );
				    setShowWaitMessage (false);

				    break;
	   }	   
     }     
  }
  
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setEmail ( e.target.value ) 
    const retour: Validation = checkEmail ( e.target.value ) 
    setMessageEmail ( retour.comment );
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword ( e.target.value );
    const retour: Validation = checkPassword ( e.target.value ) 
    setMessagePassword ( retour.comment );
  };

  const handleClickOnForgottenPassword = (event: React.MouseEvent<HTMLElement>) => {
    
    setForgottenPassword( true );
  };

  const handleClickOnCloseForgottenPassword = () => {
    
    setForgottenPassword( false );
  };

  const handleClickOnConnection = () => {
  
    setShowWaitMessage (true);
    makeAuthentication ();
  }

  const checkAll = async (): Promise<boolean> => {
  
    const retour01 = checkEmail    ( email ) 
    const retour02 = checkPassword ( password ) 

    setMessageEmail       ( retour01.comment );
    setMessagePassword    ( retour02.comment );
    
    const code:number = retour01.code + 
                        retour02.code;

    return ( code === 0 );                        
  }

  const makeAuthentication = async () => {
 
    setShowErrorMessage ( 0 );

    const check = await checkAll ()
 
    if ( check )
     {
	  const bannedIpAddressCheckResult = await getBannedIpAddressCheck ();

      console.log ("makeAuthentication :" , bannedIpAddressCheckResult)
	  
	  if ( bannedIpAddressCheckResult.code === 1 )
	        {
	         dispatch ( countAttempt () );
	 
             const authenticationResult = await postUserAuthentication ( email    ,
                                                                         password )
                                    
             console.log ("makeAuthentication :" , authenticationResult)
	  
             if ( authenticationResult.code === 1 )
                   {
                    dispatch ( setLogin ( email                      ) );
                    dispatch ( setToken ( authenticationResult.token ) );

                    const userEmailValidationCheckResult = await getUserEmailValidationCheck ( email ,
                                                                                               authenticationResult.token );

                    console.log ("makeAuthentication :" , userEmailValidationCheckResult)

                    // Pas la peine de tester l'âge du token JWT car il vient d'être créé

                    if ( userEmailValidationCheckResult.code === 1 )
                          {
                           // L'email a été validé

                           const userInfos = await getUserInfos ( email                      ,
                                                                  authenticationResult.token )

                           console.log ("makeAuthentication :" , userInfos)

					       // Pas la peine de tester l'âge du token JWT car il vient d'être créé
					
                           if ( userInfos.code === 1 )
                                 {
                                  dispatch ( setUser ( userInfos.infos.id                    ,
                                                       userInfos.infos.email                 ,
                                                       ""                                    ,
                                                       userInfos.infos.firstname             ,
                                                       userInfos.infos.lastname              ,
                                                       userInfos.infos.sex                   ,
                                                       userInfos.infos.birthday              ,
                                                       userInfos.infos.address               ,
                                                       userInfos.infos.address2              ,
                                                       userInfos.infos.zipCode               ,
                                                       userInfos.infos.city                  ,
                                                       userInfos.infos.country               ,
                                                       userInfos.infos.phoneNumber           ,
                                                       userInfos.infos.status                ,
											       	   userInfos.infos.statusDeadline        ,
													   userInfos.infos.nextStatus            ,
											       	   userInfos.infos.nextStatusRequestDate ,
                                                       userInfos.infos.state                 ) )

                                  ///////////////////////////////////////////////////////////////////////////

                                  if ( cart.items.length > 0 )
                                   {
								    // On a détecté un panier anonyme, on le merge avec le panier du user
									
                                    await mergeWithUserCart ( email                      ,
                                                              authenticationResult.token ,
															  cart                       )
                                   }

                                  const userCart = await getCart ( email                      ,
                                                                   authenticationResult.token ) 

                                  dispatch ( setCart ( userCart.cart ) ) 


                                  const productCatalogInfos = await getProductCatalogInfos ( );

	                              dispatch ( setProductCatalog ( productCatalogInfos.catalog ) );

                                  ///////////////////////////////////////////////////////////////////////////

                                  const paymentInfos = await getPaymentInfos ( email                      ,
                                                                               authenticationResult.token ) 

                                  const detailledPaymentInfos = await getDetailledPaymentInfos ( email                      ,
                                                                               authenticationResult.token ) 
                                                            
								  console.log (detailledPaymentInfos)							
															
                                  for ( let p = 0 ; p < paymentInfos.payments.length ; p++ )
                                   {
                                    const payment = paymentInfos.payments[p];
						 
                                    dispatch ( addPayment ( payment ) ) 
                                   }

								  ///////////////////////////////////////////////////////////////////////////

                                  let status : number = 0; // Connecté et gratuit
  
                                  if ( userInfos.infos.status  === "Standard" ) { status = 1 }
                                  if ( userInfos.infos.status  === "Premium"  ) { status = 2 }
								   
								  const catalogInfos = await getCatalogInfos ( status );

	                              dispatch ( setCatalog ( catalogInfos.catalog ) );

                                  /*
                                  // Le paramètre n'est pas utilisé pour le moment

                                  broadcastingChannelPackageInfos = await getBroadcastingChannelPackageInfos ( ( authentication.login !== ""   &&
                                                                                                                 authentication.token !== "" ) &&
                                                                                                               ( user.status  === "Premium" ) );

	                              dispatch ( setBroadcastingChannelPackage ( broadcastingChannelPackageInfos.broadcastingChannelPackage ) );

	                              dispatch ( setCurrentlyPlayingBroadcastingChannel ( broadcastingChannelPackageInfos.broadcastingChannelPackage.channels[0] ) )  
                                  */
								  
                                  navigate ("/grille");
                                 }
                            else {
                                  dispatch ( initAuthentication(false) );
						   
                                  setShowErrorMessage ( 3 );
								  setShowWaitMessage (false);
						          setVersion ( version + 1 )
								  
					              if ( authentication.numberOfAttempts > 5 )
					               {
					                postBannedIpAddress ( email )
					                setShowBanMessage ( true );
					               }								  
                                 }
                          }
                     else {
                           // L'email n'a pas encore été validé

                           dispatch ( initAuthentication(false) );
                    
                           setShowErrorMessage ( 2 );
						   setShowWaitMessage (false);

				           setVersion ( version + 1 )
						   
					       if ( authentication.numberOfAttempts > 5 )
					        {
					         postBannedIpAddress ( email )
					         setShowBanMessage ( true );
					        }
                          }
                   }
              else {
                    // L'authentification a échoué

                    dispatch ( initAuthentication(false) );
             
                    setShowErrorMessage ( 1 );
					setShowWaitMessage (false);
		            setVersion ( version + 1 )
					
					if ( authentication.numberOfAttempts > 5 )
					 {
					  postBannedIpAddress ( email )
					  setShowBanMessage ( true );
					 }

                   }
		    }
			
       else {
             // L'adresse Ip est bannie

             dispatch ( initAuthentication(false) );
             
             setShowErrorMessage ( 4 );
			 setShowWaitMessage (false);

		     setVersion ( version + 1 )
            }
     }     
   }


  return (

   <>

    <GlobalMenu/>
    <CookiesManager />
    <TopShifter />

    {
      showForgottenPassword &&
      ( <WindowForgottenPassword onClickOnCloseButton = {handleClickOnCloseForgottenPassword }/> )
    }
              
    <div className={classNames(styles.root)}>
	
     <div className={classNames(styles.shiftbox)}></div>

     <div className={classNames(styles.contain)}>

      { 
        ( showEmailCheckMessage === 1 ) && 
        ( 
          <>
           <br/> 
           <div>
            <p className = {classNames(styles.message_ack)}>
             Votre adresse e-mail a été vérifiée
            </p>
           </div>
          </>
        )  
      }
             
      { 
        ( showEmailCheckMessage === 2 ) && 
        ( 
          <>
           <div>
            <p className = {classNames(styles.message_ack)}>
             Votre adresse e-mail a été vérifiée le {(emailCheckDate)}
            </p>
           </div>
          </>
        )  
      }

      { 
        ( showEmailCheckMessage === -1 ) && 
        ( 
          <>
           <br/>
           <div>
            <p className = {classNames(styles.message_error)}>
             Echec de la vérification de l'adresse e-mail
            </p>
           </div>
          </>
        )  
      }
    
      <br/>

      <div className={classNames(styles.title)}>ME CONNECTER</div>

      <br/>

      <WidgetFrame name               = "Adresse E-mail"
                   error              = {messageEmail}
                   labelTextPosition  = "left"
                   labelBlockPosition = "top" 
				   classNameName      = {styles.frame_label} >

                     <Field type         = "email"
                            placeholder  = ""
                            value        = {email}
                            onChange     = {handleEmailChange} 
                            autocomplete = "on"/>              
                         
      </WidgetFrame>
               
      <br/> 

      <WidgetFrame name               = "Mot de passe"
                   error              = {messagePassword}
                   labelTextPosition  = "left"
                   labelBlockPosition = "top" 
				   classNameName      = {styles.frame_label} >

       <Field type           = "password"
              placeholder    = ""
              value          = {password}
              onChange       = {handlePasswordChange} 
              autocomplete   = "on" 
			  eyeVisible     = {true} 
			  eyeColor       = "#FFFFFF" />

      </WidgetFrame>
               
      <Link to="#" onClick = {handleClickOnForgottenPassword} >
       <p className = {classNames(styles.forgotten_password)}>
        Mot de passe oublié
       </p>
      </Link>
                
      <br/>
               
      <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
              onClick   = { handleClickOnConnection } >
       <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
       <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-3 py-1 text-sm font-medium text-white backdrop-blur-3xl">
        Se connecter
       </span>
      </button>

      <br/>
      <br/>
                                           
      { 
        ( showErrorMessage === 1 ) && 
        ( 
		  <div>
           <p className = {classNames(styles.message_error)}>
            Erreur d'authentification.<br/>
            Tentative {authentication.numberOfAttempts} / 5
           </p>
          </div> 
		)  
      }

      { 
        ( showErrorMessage === 2 ) && 
        ( 
		  <div>
           <p className = {classNames(styles.message_error)}>
            Vous devez valider votre adresse e-mail avant de pouvoir accéder à votre compte.<br/>
			Tentative {authentication.numberOfAttempts} / 5
           </p>
          </div> 
		)  
      }
              
      { 
        ( showErrorMessage === 3 ) && 
        ( 
		  <div>
           <p className = {classNames(styles.message_error)}>
            Impossible de récupérer les informations de votre compte.<br/>
			Tentative {authentication.numberOfAttempts} / 5
           </p>
          </div> 
		)  
      }

      { 
        ( showErrorMessage === 4 || showBanMessage ) && 
        ( 
		  <div>
           <p className = {classNames(styles.message_error)}>
            Trop de tentatives, votre compte est bloqué pendant 1 heure.
           </p>
          </div> 
		)  
      }
	  
      { 
        ( showErrorMessage === 5 ) && 
        ( 
		  <div>
           <p className = {classNames(styles.message_error)}>
            Le lien de validation de votre adresse email est obsolète, vous devez recréer votre compte.<br/>
           </p>
          </div> 
		)  
      }

      { 
        ( showErrorMessage === 6 ) && 
        ( 
		  <div>
           <p className = {classNames(styles.message_error)}>
            Impossible de valider votre adresse email. <br/>
			Tentative {authentication.numberOfAttempts} / 5
           </p>
          </div> 
		)  
      }

      { 
        ( showErrorMessage === 7 ) && 
        ( 
		  <div>
           <p className = {classNames(styles.message_error)}>
            Erreur de connexion au serveur. <br/>
			Tentative {authentication.numberOfAttempts} / 5
           </p>
          </div> 
		)  
      }

      { 
        ( showWaitMessage ) && 
        ( 
		  <div>
           <p className = {classNames(styles.message_wait)}>
            Veuillez patienter un instant...
           </p>
          </div> 
		)  
      }

      <br/>
              
      <Link to="/abonnement">
       <p className = {classNames(styles.no_account)}>
        Vous n'avez pas de compte ? Créer un compte
       </p>
      </Link>
              
     </div>
    </div>

    <Footer />
       
   </>

         );


};

export default Login;
