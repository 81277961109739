import { ActionType                     ,
         actionInitAuthentication       ,
		 actionSetLogin                 ,
         actionSetToken                 ,
		 actionCountAttempt             ,
         DispatchTypeInitAuthentication ,
         DispatchTypeSetLogin           ,
         DispatchTypeSetToken           ,
		 DispatchTypeCountAttempt       }  from './actionTypes';

export const initAuthentication = (complete: boolean) => 
 {
  const action: actionInitAuthentication = 
   {
    type:     ActionType.INIT_AUTHENTICATION,
	complete: complete
   }

  return ( dispatch: DispatchTypeInitAuthentication ) => 
   {
    dispatch (action);
   }
 } 

export const setLogin = ( login: string ) => 
 {
  const action: actionSetLogin = 
   {
    type:  ActionType.SET_LOGIN,
	login: login
   }

  return ( dispatch: DispatchTypeSetLogin ) => 
   {
    dispatch (action);
   }
 } 

export const setToken = ( token: string ) => 
 {
  const action: actionSetToken =
   {
    type:  ActionType.SET_TOKEN,
	token: token
   }
 
  return ( dispatch: DispatchTypeSetToken ) => 
   {
    dispatch (action);
   }
 }
 
export const countAttempt = () => 
 {
  const action: actionCountAttempt =
   {
    type:  ActionType.COUNT_ATTEMPT
   }
 
  return ( dispatch: DispatchTypeCountAttempt ) => 
   {
    dispatch (action);
   }
 }
  