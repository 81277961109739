import   React               ,  
       { useState            ,
	     useEffect           }  from 'react';
		 
import { useNavigate         }  from 'react-router-dom';
		 
import { Dispatch            }  from 'redux';
import { useDispatch         }  from 'react-redux';
		 
import   classNames             from 'classnames';

import   styles                 from './hotline.module.scss';

import { HotlineQuestionForm    }  from './hotline_question_form';
import { HotlineQuestionList    }  from './hotline_question_list';

import { useTypedSelector    }  from '../../../../../datas/useTypeSelector';

import { initAuthentication  }  from '../../../../../datas/authenticationManagment/actionCreators';
import { initUser            }  from '../../../../../datas/userManagment/actionCreators';

import { INTERNAL_LINKS      }  from '../../../../../network/urls';

import { Question                 }  from '../../../../../datas/hotlineQuestionManagment/actionTypes';

import { getHotlineQuestionsByEmail}  from '../../../../../network/api_hotline';

export const Hotline = () => {

  const emptyList: Question [] = [];

  const [ questions , setQuestions ] = useState (emptyList);

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
  const { user           } = useTypedSelector ( (state) => state.userManagment   );


  useEffect(() => {

    if ( authentication.login === "" || 
         authentication.token === "" )
     {
	  dispatch ( initUser () )
	  
      navigate (`${INTERNAL_LINKS.Login}`);
     }
	 
	 initList ()

  }, []); 

  useEffect(() => {
  

  }, [questions]); 

  const updateQuestionList = () => {
  
    initList ()
  }
  
  const initList = async () => {
  
      console.log ("initList de Hotline")

    const response = await getHotlineQuestionsByEmail ( authentication.login ,
                                                        authentication.token )
												  
      console.log ("initList de Hotline response = ", response)
	
	setQuestions ( response.questions )
  }

  return (
            
           <div className = { classNames(styles.root) } >
			 <HotlineQuestionForm updateQuestionList = {updateQuestionList} />
			 <br/>
			 <hr/>
			 <br/>
		     <HotlineQuestionList questions          = {questions} 
			                      updateQuestionList = {updateQuestionList}  />
           </div>
           
         );
};


export default Hotline;
