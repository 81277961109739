import   React                          , 
       { useState                       }  from 'react';
import { Link                           }  from 'react-router-dom';
import   classNames                        from 'classnames';

import   styles                            from './window_cookies_manager.module.scss';

import { WindowCookiesManager2          }  from './window_cookies_manager_2';

import { updateAllCookiesConsent        ,
         updateFunctionalCookiesConsent }  from '../../../network/utils';
		 
import { postRgpdConsentLog             }  from '../../../network/api_rgpd_consent_log';
		 
import { WWW_URLS                       }  from '../../../network/urls';

export interface WindowCookiesManagerProps {

  onClickOnCloseButton: (() => void)
}

export const WindowCookiesManager = ({ onClickOnCloseButton }: WindowCookiesManagerProps) => {

  const [ showParameters , setShowParameters ] = useState (false);

  const handleClickAccept = () => {
  
    updateAllCookiesConsent (1);

    postRgpdConsentLog ( 
	                     1,   // allCookiesConsent
	                     1,   // functionalCookiesConsent
						 1,   // analysisCookiesConsent
						 1    // marketingCookiesConsent
					   );

    onClickOnCloseButton();
  };

  const handleClickRefuse = () => {

    updateAllCookiesConsent (-1);

    postRgpdConsentLog ( 
	                      0,   // allCookiesConsent         ( toujours à 0 car mélange de 1 et -1)
						  1,   // functionalCookiesConsent  ( toujours à 1 en théorie ???)
						 -1,   // analysisCookiesConsent
						 -1    // marketingCookiesConsent
					   );

    onClickOnCloseButton();
  };

  const handleClickParameters = () => {
  
    updateAllCookiesConsent        (0);
    updateFunctionalCookiesConsent (1);
	
    setShowParameters (true);
  };

  const handleTriggerCookiesParametersClose = () => {
  
    setShowParameters (false);
    onClickOnCloseButton();
  };

  return (
            <>
            
              {  
                showParameters && 
                ( <WindowCookiesManager2 onClickOnCloseButton = {handleTriggerCookiesParametersClose }/> ) 
              }


              <div className={classNames(styles.root)}>
			  
                <div className={classNames(styles.window)}>
              
                  <h1 className={classNames(styles.title)}>Centre de préférences de la confidentialité</h1>
              
                  <br/>
              
                  <p className={classNames(styles.text_rgpd)}>
				     Lorsque vous vous rendez sur un site web, des informations peuvent être 
                     stockées sur votre navigateur ou récupérées à partir de celui-ci, généralement 
                     sous la forme de cookies. Ces informations peuvent porter sur vous, sur vos 
                     préférences ou sur votre appareil et sont principalement utilisées pour 
                     s'assurer que le site fonctionne comme prévu. Les données enregistrées ne 
                     permettent généralement pas de vous identifier directement, mais peuvent vous 
                     permettre de bénéficier d'une expérience web personnalisée. Vous pouvez choisir 
                     de bloquer certains types de cookies. Cliquez sur les différentes catégories 
                     pour obtenir plus de détails sur chacune d'entre elles, et pour modifier les 
                     paramètres par défaut. Veuillez noter, cependant, que si vous bloquez certains 
                     types de cookies, votre expérience de navigation et les services que nous sommes 
                     en mesure de vous offrir peuvent être impactés.
				  </p>
                 
                  <br/>
              
                  <p><Link to        = {WWW_URLS.CookiesInformation} 
				           target    = "_blank"  
					  	   className = { classNames(styles.link) }>Notice d’Information sur les cookies</Link></p>

                  <br/>

                  <div className={classNames(styles.block_buttons)}>
              
                    <button className = "px-3 py-1 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
			                onClick   = {handleClickAccept}>
                      Tout accepter
                    </button>
					
					&nbsp;&nbsp;
					
                    <button className = "px-3 py-1 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                            onClick   = {handleClickRefuse}>
                      Tout refuser
                    </button>

					&nbsp;&nbsp;
					
                    <button className = "px-3 py-1 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                            onClick   = {handleClickParameters}>
                      Réglages
                    </button>
					
			  {/*
                    <Trigger name            = "Tout accepter" 
                             onClick         = {handleClickAccept}
                             classNameNormal = {classNames(styles.button)}/>
                       
                    <Trigger name            = "Tout refuser" 
                             onClick         = {handleClickRefuse}
                             classNameNormal = {classNames(styles.button)}/>
                       
                    <Trigger name            = "Réglages" 
                             onClick         = {handleClickParameters}
                             classNameNormal = {classNames(styles.button)}/>
                        */} 
                  </div>
              
                </div>
				
              </div>
            
            </>
         );
};

export default WindowCookiesManager;
