import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import styles from './video_category.module.scss';

import { Category, Video } from '../../../../../datas/videoManagment/actionTypes';
import { VideoThumbnail } from './video_thumbnail';
import { sortVideosByDate } from '../../../../../network/api_video';

import { Carousel } from '../../../../widget/carousel/carousel';

export interface VideoCategoryProps {
  category: Category;
  showSheet: (video: Video) => void;
  showZoom: (video: Video, width: number, height: number, top: number, left: number) => void;
  showAlertWindowDeprecatedToken: () => void;
}

export const VideoCategory = ({
  category,
  showSheet,
  showZoom,
  showAlertWindowDeprecatedToken,
}: VideoCategoryProps) => {

  const [first, setFirst] = useState(false);
  const [videos, setVideos] = useState<Video[]>([]);

  useEffect(() => {

    if (!first) {
      setFirst(true);
      init();
    }

  }, [first]);

  useEffect(() => {
    if (!first) {
      init();
      setFirst(true);
    }
  }, [category, first, videos]);

  const init = () => {
    let temp: Video[] = [];
    for (let i = 0; i < category.videoSets.length; i++) {
      const videoSet = category.videoSets[i];
      for (let j = 0; j < videoSet.videos.length; j++) {
        const video = videoSet.videos[j];
        temp.push(video);
      }
    }
    temp = sortVideosByDate(temp);
    setVideos(temp);
  };

  const generateThumbnails = (): JSX.Element[] => {
    const thumbnails: JSX.Element[] = [];

    for (let i = 0; i < videos.length; i++) {
      const video = videos[i];
      thumbnails.push(
        <div key={i}>
          <VideoThumbnail
            video={video}
            showSheet={showSheet}
            showZoom={showZoom}
            showAlertWindowDeprecatedToken={showAlertWindowDeprecatedToken}
          />
        </div>
      );
    }
	
    return thumbnails;
  };

  return (
    <div className={classNames(styles.root)}>
      <p className={classNames(styles.title)}>{category.name}</p>
      <div className={classNames(styles.carousel)}>
        <Carousel items={generateThumbnails()} itemWidth={374} />
      </div>
    </div>
  );
};

export default VideoCategory;

