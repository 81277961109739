export enum ActionType
 {
  INIT_PAYMENT           = 'INIT_PAYMENT'           ,
  SET_PAYMENT            = 'SET_PAYMENT'            ,
  UPDATE_DESCRIPTION     = 'UPDATE_DESCRIPTION'     ,
  UPDATE_AMOUNT          = 'UPDATE_AMOUNT'          , 
  UPDATE_DATE            = 'UPDATE_DATE'            ,
  UPDATE_MEANSOFPAYMENT  = 'UPDATE_MEANSOFPAYMENT'  ,
  UPDATE_STATUS          = 'UPDATE_STATUS'          ,
  UPDATE_TRANSACTIONID   = 'UPDATE_TRANSACTIONID'          
 }

export interface Payment
 {
  description:      string ,
  amount:           number ,
  date:             Date   ,
  meansOfPayment:   string ,
  status:           string ,
  transactionId:    string ,
  paymentReference: string
 }
 
export interface Item
 {
  productId:          number,
  productName:        string,
  productCatchword:   string | null,
  productKind:        string,
  productVariantId:   number,
  productVariantName: string,
  priceToPay:         number,
  quantity:           number,
  totalPrice:         number 
 }
 
export interface Purchase 
 {
  creationDate : Date,
  cart: Item []
 } 
 
export interface Payment2
 {
  description:      string ,
  amount:           number ,
  date:             Date   ,
  meansOfPayment:   string ,
  status:           string ,
  transactionId:    string ,
  paymentReference: string ,
  purchase:         Purchase
 }

export interface actionInitPayment
 {
  type:                   ActionType.INIT_PAYMENT;
 }

export interface actionSetPayment
 {
  type:                   ActionType.SET_PAYMENT;
  newDescription:         string;
  newAmount:              number;
  newDate:                Date;
  newMeansOfPayment:      string;
  newStatus:              string;
  newTransactionId:       string;
  newPaymentReference:    string;
 }

export type Action = actionInitPayment |
                     actionSetPayment  ;
					 
export type DispatchTypeInitPayment = ( args: actionInitPayment ) => actionInitPayment
export type DispatchTypeSetPayment  = ( args: actionSetPayment  ) => actionSetPayment
