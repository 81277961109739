import   axios                    , 
       { AxiosError               }  from 'axios';

import { getUserAgentInformations ,
         getAppInformations       }  from './utils';	   

import { API_URLS                 }  from './urls';

export interface PostResult {
  code    : number,
  comment : string
}

export const postBannedIpAddress = async ( email: string ): Promise<PostResult> => {

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();
	
    const headers = {
        'Accept'                  : 'application/ld+json',
        'Content-Type'            : 'application/ld+json',
	    'User-Agent-Informations' : `${userAgentInformations}`,
	    'App-Informations'        : `${appInformations}`,
    };
	
	var result:PostResult = {
      code    : 0,
      comment : "",
    };
		
    try {

        const response = await axios.post (
        
                                               `${API_URLS.postBannedIpAddress}`,
                              
                                               { 
                                                 email               											 
                                               },
                                            
                                               {
                                                 headers: headers
                                               }
                                          );

        switch ( response.status )
		 {
		  case 201 : result = {
                                code:    1 ,
		                        comment: "Ban de l'adresse Ip créé" 
		                      };
								  		   
					 break;
 
          default :  result = {
		                        code:     -102 ,
		                        comment: `Erreur lors de la création du banissement : ${response.status}` 
		                      };
         }

    }  
    catch ( err ) {

        const error = err as AxiosError;

        if ( error.response && error.response.status )
	          {
               result = {
		                  code    : -202,
		                  comment : `Erreur lors de la création du banissement : ${error.response.status}`
		                };
               
              }
         else {
	           result = {
		                  code:    -201,
		                  comment: "Erreur réseau"
		                };
	          }	   
    }
	
	return result;
};


export interface BannedIpAddressCheckResult {
  code:    number,
  comment: string
}

export const getBannedIpAddressCheck = async ( ): Promise<BannedIpAddressCheckResult> => {

    const url =  `${API_URLS.getBannedIpAddressCheck}`;
		  
	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };
		  
    var result:BannedIpAddressCheckResult = {
      code:    0,
      comment: ""
    };

    try {

		const response = await axios.get ( url , { headers: headers } );
		
		if ( response.status === 200 )
		      {
			   if ( response.data[0].result )
			         {
                      result = {
                                 code:    1,
                                 comment: "ok"
                               };
                     }
                else {
                      result = {
                                 code:    0,
                                 comment: "IP toujours bannie"
                               };
				     }				
		      }
		 else {
               result = {
                 code:    -101,
                 comment: `Erreur : ${response.status}`
               };        
		      }
    } 
    catch (err) {
	
        const error = err as AxiosError;

        if ( error.response && error.response.status )
	          {
               result = {
		                  code    : -202,
		                  comment : `Erreur : ${error.response.status}`
		                };
               
              }
         else {
	           result = {
		                  code:    -201,
		                  comment: "Erreur réseau"
		                };
	          }	   
    }

    return result;
};

