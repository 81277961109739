import   React                            from 'react';

import   classNames                       from 'classnames';

import   styles                           from './legal_notice_panel.module.scss';

import { LegalNoticePanel_Informations }  from '../legal_data/legal_data';

export const LegalNoticePanel = () => {

  return (

        <>

          <div className={classNames(styles.root)}>

            {LegalNoticePanel_Informations}
        
          </div>
        
        </>

            
  );
};

export default LegalNoticePanel;
