import   React                  ,  
       { useState               ,
	     useEffect              }  from 'react';

import { Link                   }  from 'react-router-dom';
		 
import { Dispatch               }  from 'redux';
import { useDispatch            }  from 'react-redux';

import   classNames                from 'classnames';

import   styles                    from './registration.module.scss';

import { Field                  }  from '../../../../widget/field/field';
import { Select                 }  from '../../../../widget/select/select';
import { DateSelector           }  from '../../../../widget/date_selector/date_selector';
import { WidgetFrame            }  from '../../../../widget/widget_frame/widget_frame';
import { WidgetFrameLabel       }  from '../../../../widget/widget_frame/widget_frame_label';
import { Toggle                 }  from '../../../../widget/toggle/toggle';
import { Window                 }  from '../../../../widget/window/window';

import { Validation             ,
         checkEmail             ,
         checkPassword          ,
         checkPassword2         ,
         checkFirstname         ,
         checkLastname          ,
         checkSex               ,
         checkBirthday          ,
         checkRgpdConsent       }  from '../../../../../datas/userManagment/validators';	
		 
import { updateEmail            , 
         updatePassword         , 
         updatePassword2        , 
         updateFirstname        , 
         updateLastname         , 
         updateSex              , 
         updateBirthday         , 
         updateRgpdConsent      ,
		 updateStatus           ,
		 updateId               ,
		 setUser                ,
		 initUser               }  from '../../../../../datas/userManagment/actionCreators';

import { setLogin               , 
         setToken               ,
		 initAuthentication     }  from '../../../../../datas/authenticationManagment/actionCreators';
		 
import { useTypedSelector       }  from '../../../../../datas/useTypeSelector';
  
import { postUser               ,
         postUserAuthentication ,
		 getUserInfos           }  from '../../../../../network/api_user';
  
  
  
import { WWW_URLS               }  from '../../../../../network/urls';


type RegistrationProps = {
  getState:        (state: number) => void | undefined;
  withOfferChoice: boolean;
};


export const Registration = ({ getState        ,
                               withOfferChoice }: RegistrationProps) => {

  const dispatch: Dispatch<any> = useDispatch()

  const { user  } = useTypedSelector ( (state) => state.userManagment   );

  const [ email                 , setEmail              ] = useState (  ''   );
  const [ password              , setPassword           ] = useState (  ''   );
  const [ password2             , setPassword2          ] = useState (  ''   );
  const [ firstname             , setFirstname          ] = useState (  ''   );
  const [ lastname              , setLastname           ] = useState (  ''   );
  const [ sex                   , setSex                ] = useState (  'Aucune réponse'   );
  const [ birthday              , setBirthday           ] = useState (  '01/01/1975'   );
  const [ rgpdConsent           , setRgpdConsent        ] = useState ( false );
  
  const [ messageEmail          , setMessageEmail       ] = useState ( '' );
  const [ messagePassword       , setMessagePassword    ] = useState ( '' );
  const [ messagePassword2      , setMessagePassword2   ] = useState ( '' );
  const [ messageFirstname      , setMessageFirstname   ] = useState ( '' );
  const [ messageLastname       , setMessageLastname    ] = useState ( '' );
  const [ messageSex            , setMessageSex         ] = useState ( '' );
  const [ messageBirthday       , setMessageBirthday    ] = useState ( '' );
  const [ messageRgpdConsent    , setMessageRgpdConsent ] = useState ( '' );
  
  const [ windowMessage         , setWindowMessage      ] = useState ( <></> );
  const [ showWindow            , setShowWindow         ] = useState ( false );

  const [isSmallScreen,           setIsSmallScreen      ] = useState<boolean>(false);

  const handleResize = () => {
      setIsSmallScreen ( window.innerWidth <= 600 );
  };

  useEffect(() => {

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
	
  }, []); 
  
  useEffect(() => {

  }, [isSmallScreen]); 

  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    setEmail ( e.target.value ) 
    dispatch ( updateEmail ( e.target.value ) )
	
    const response: Validation = await checkEmail ( e.target.value ) 
	setMessageEmail ( response.comment );
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword ( e.target.value ) 
    dispatch ( updatePassword ( e.target.value ) )
	
    const response: Validation = checkPassword ( e.target.value ) 
	setMessagePassword ( response.comment );
	
    const retour2: Validation = checkPassword2 ( password2, e.target.value )
	setMessagePassword2 ( retour2.comment );
  };

  const handlePassword2Change = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword2    ( e.target.value ) 
    dispatch ( updatePassword2 ( e.target.value ) )
    
    const retour: Validation = checkPassword2 ( e.target.value , password )
	setMessagePassword2 ( retour.comment );
  };

  const handleFirstnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setFirstname ( e.target.value ) 
    dispatch ( updateFirstname ( e.target.value ) )
	
    const response: Validation = checkFirstname ( e.target.value ) 
	setMessageFirstname ( response.comment );
  };

  const handleLastnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setLastname ( e.target.value ) 
    dispatch ( updateLastname ( e.target.value ) )
	
    const response: Validation = checkLastname ( e.target.value ) 
	setMessageLastname ( response.comment );
  };

  const handleSexChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    setSex    ( e.target.value ) 
    dispatch ( updateSex ( e.target.value ) )
    
	const retour: Validation = checkSex  ( e.target.value ) 
	setMessageSex ( retour.comment );
  };

  const handleBirthdayChange = (value:string) => {

    setBirthday ( value ) 
    dispatch ( updateBirthday ( value ) )
    
	const retour: Validation = checkBirthday  ( value ) 
	setMessageBirthday ( retour.comment );
  };

  const handleRgpdConsentChange  = ( newRgpdConsent: boolean ) => {
  
    setRgpdConsent ( newRgpdConsent )
    dispatch ( updateRgpdConsent ( newRgpdConsent ) )
    
	const response: Validation = checkRgpdConsent ( newRgpdConsent )
	setMessageRgpdConsent ( response.comment );
  };
  
  const handleValidationButtonClick = () => {
  
    makeRegistration ();   
  }
  
  const handleCloseButtonClick = () => {
 
    setWindowMessage (<></>);   
    setShowWindow    (false);   
  }

  const checkAll = async (): Promise<boolean> => {
  
	if ( birthday === "" )
	 {
	  setBirthday ( "01/01/1975" )
      dispatch ( updateBirthday ( "01/01/1975" ) )
	 }

    const response01 = await checkEmail       ( email ) 
    const response02 =       checkPassword    ( password ) 
    const response03 =       checkPassword2   ( password2 , password )
    const response04 =       checkFirstname   ( firstname ) 
    const response05 =       checkLastname    ( lastname ) 
	const response06 =       checkSex         ( sex ) 
	const response07 =       checkBirthday    ( birthday ) 
	const response08 =       checkRgpdConsent ( rgpdConsent )

	setMessageEmail       ( response01.comment );
	setMessagePassword    ( response02.comment );
	setMessagePassword2   ( response03.comment );
	setMessageFirstname   ( response04.comment );
	setMessageLastname    ( response05.comment );
	setMessageSex         ( response06.comment );
	setMessageBirthday    ( response07.comment );
	setMessageRgpdConsent ( response08.comment );

	const globalResponse:boolean = ( response01.code === 1 ) && 
	                               ( response02.code === 1 ) &&
	                               ( response03.code === 1 ) &&
	                               ( response04.code === 1 ) &&
	                               ( response05.code === 1 ) &&
	                               ( response06.code === 1 ) &&
	                               ( response07.code === 1 ) &&
	                               ( response08.code === 1 );

    return globalResponse;						
  }

/*
  const makeRegistration = async () => {
 
    const check = await checkAll ()
 
    if ( check )
	 {
      setShowWaitingIcon (true);
	 
      const response01 = await postUser ( user.email     ,
                                          user.password  ,
										  user.firstname ,
										  user.lastname  ,
										  user.sex       ,
										  user.birthday  )

      if ( response01.code === 1 )
	        {
	         dispatch ( updateStatus ( "Free" ) )

             // Le compte a pu être créé, on va se connecter automatiquement dessus afin de continuer le processus d'abonnement
             // avec un token JWT (car la création d'un paiment est à accès restreint)

             const authenticationResult = await postUserAuthentication ( user.email    ,
                                                                         user.password )

	         if ( authenticationResult.code === 1 )
                   {
                    dispatch ( setLogin ( email                      ) );
                    dispatch ( setToken ( authenticationResult.token ) );
				
				    const userInfos = await getUserInfos ( email                      ,
                                                           authenticationResult.token )

					if ( userInfos.code === 1 )
                     {					  
                      dispatch ( updateId ( userInfos.infos.id ) )
                     }
				
					if ( typeof ( getState ) !== "undefined" )
	                 {
	                  getState (1);
	                 }

                   }
              else {
	                setShowWaitingIcon (false);
			 
                    setWindowMessage ( <>Problème lors de la connexion automatique au nouveau compte</> );
                    setShowWindow    ( true );
                   }
	        }
	   else {
	         setShowWaitingIcon (false);

		     setWindowMessage ( <>Problème lors de la création du compte</> );
             setShowWindow    ( true );
	        }
     }
   }

*/

  const makeRegistration = async () => {
 
    const check = await checkAll ()
 
    if ( check )
	 {
	  if ( typeof ( getState ) !== "undefined" )
	   {
	    getState (1);
	   }
     }
   }

  return (
            
           <div className={classNames(styles.root)}>


               {
			     showWindow
				 &&
                 (
				   < Window message              = { windowMessage          }
				            onClickOnCloseButton = { handleCloseButtonClick } />
				 )				
		       }

                  

                           
               <div className={classNames(styles.form)}>

                 <div className={classNames(styles.line_1)}>
                  <p className={classNames(styles.subtitle)}>
                   { withOfferChoice ? "Étape 1 : " : "" } Création de mon compte
                  </p>
                 </div>
				 
                 <div className={classNames(styles.line_2)}>
               
			       <WidgetFrame name               = "Adresse E-mail"
                                error              = { messageEmail }
								labelTextPosition  = { isSmallScreen ? "left" : "right" }
								labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
								classNameName      = { styles.frame_label }
   							    classNameGrid      = { styles.grid_info } >

			         <Field type           = "email"
                            placeholder    = ""
                            value          = {email}
                            onChange       = {handleEmailChange} />              
                         
                   </WidgetFrame>
				   
                 </div>

                 <div className={classNames(styles.line_3)}>

			       <WidgetFrame name               = "Mot de passe"
                                error              = { messagePassword }
								labelTextPosition  = { isSmallScreen ? "left" : "right" }
								labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
								classNameName      = { styles.frame_label }
   							    classNameGrid      = { styles.grid_info } >

                     <Field type           = "password"
					        eyeVisible     = {true}
 			                eyeColor       = "#FFFFFF"
			                placeholder    = ""
                            value          = {password}
                            onChange       = {handlePasswordChange} />

                   </WidgetFrame>

                 </div>

                 <div className={classNames(styles.line_4)}>

			       <WidgetFrame name               = "Confirmation du mot de passe"
                                error              = { messagePassword2 }
								labelTextPosition  = { isSmallScreen ? "left" : "right" }
								labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
								classNameName      = { styles.frame_label }
   							    classNameGrid      = { styles.grid_info } >

                     <Field type           = "password"
					        eyeVisible     = {true} 
			                eyeColor       = "#FFFFFF"
                            placeholder    = ""
                            value          = {password2}
                            onChange       = {handlePassword2Change} />

                   </WidgetFrame>

                 </div>
				 
                 <div className={classNames(styles.line_5)}>
               
			       <WidgetFrame name               = "Prénom"
                                error              = { messageFirstname }
								labelTextPosition  = { isSmallScreen ? "left" : "right" }
								labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
								classNameName      = { styles.frame_label }
   							    classNameGrid      = { styles.grid_info } >

			         <Field type           = "text"
                            placeholder    = ""
                            value          = {firstname}
                            onChange       = {handleFirstnameChange} />              
                         
                   </WidgetFrame>
				   
                 </div>
				 
                 <div className={classNames(styles.line_6)}>
               
			       <WidgetFrame name               = "Nom"
                                error              = { messageLastname }
								labelTextPosition  = { isSmallScreen ? "left" : "right" }
								labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
								classNameName      = { styles.frame_label }
   							    classNameGrid      = { styles.grid_info } >

			         <Field type           = "text"
                            placeholder    = ""
                            value          = {lastname}
                            onChange       = {handleLastnameChange} />              
                         
                   </WidgetFrame>
				   
                 </div>
				 
				 <div className={classNames(styles.line_7)}>

                   <WidgetFrame name               = "Sexe"
                                error              = { messageSex }
								labelTextPosition  = { isSmallScreen ? "left" : "right" }
								labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
								classNameName      = { styles.frame_label }
	    						classNameGrid      = { styles.grid_info } >

                     <Select choices  = { [
                                            "Aucune réponse",
                                            "Homme",
                                            "Femme"
                                          ] }
                             value    = {sex}
                             onChange = {handleSexChange} />

                   </WidgetFrame>

                 </div>
				 
                 <div className={classNames(styles.line_8)}>

                   <WidgetFrame name               = "Date de naissance"
                                error              = { messageBirthday }
								labelTextPosition  = { isSmallScreen ? "left" : "right" }
								labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
								classNameName      = { styles.frame_label }
	    						classNameGrid      = { styles.grid_info } >

                     <DateSelector value    = {birthday}
						           onChange = {handleBirthdayChange} />

                   </WidgetFrame>

                 </div>
				 
                 <div className={classNames(styles.line_9)}>

                   <WidgetFrame error              = {messageRgpdConsent}
	    						classNameGrid      = {classNames(styles.grid_rgpd)} 
								labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_rgpd)}>
					 
					   En envoyant ce formulaire, j'accepte également les {" "}
					   <Link to={WWW_URLS.TermsOfService} 
					         target="_blank" 
						 	className = { classNames(styles.link) } >Conditions d’utilisation du site Internet</Link>. {" "}
					   Nous traitons vos données personnelles conformément à notre {" "}
                       <Link to={WWW_URLS.PersonnalDatasInformation} 
					         target="_blank" 
						  	 className = { classNames(styles.link) } >Politique de confidentialité Lovelight TV</Link>. {" "}
					   Vous avez le droit de refuser certaines activités de traitement de vos données personnelles. {" "}
					   Consultez la section « Vos droits » de la Politique de confidentialité pour en savoir plus. 

					 </WidgetFrameLabel>

                     <Toggle checked        = {rgpdConsent}
                     	     onChange       = {handleRgpdConsentChange}
							 form           = "checkbox" />
							
                   </WidgetFrame>
                              
                 </div>

                 <div className={classNames(styles.line_10)}>

                  <div className={classNames(styles.buttons)}>
				  
			       <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
				           onClick   = { handleValidationButtonClick } >
                    <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                    <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-red bg-slate-950 px-3 py-1 text-sm font-medium text-white backdrop-blur-3xl">
                     
					 { ( withOfferChoice ? "Etape suivante" : "Valider" ) }
					   
                    </span>
                   </button>
							 
				  </div>
                 </div>

               </div>

           </div>
           
         );
};


export default Registration;
