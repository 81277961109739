import { Action     , 
         ActionType , 
		 Payment    } from './actionTypes';


interface State 
 {
  payment: Payment;
 }

const initialState = 
 {
  payment: 
   {
    description:      "" ,
    amount:           0  ,
	date:             new Date() ,
    meansOfPayment:   "" ,
    status:           "" ,
	transactionId:    "" ,
	paymentReference: "",
   } 
 }

const PaymentManagmentReducer = (state: State = initialState, action: Action):State => 
 {
  switch(action.type) 
   {
    case ActionType.INIT_PAYMENT:             state.payment.description      = ""
                                              state.payment.amount           = 0
                                              state.payment.date             = new Date()
                                              state.payment.meansOfPayment   = ""
                                              state.payment.status           = ""
                                              state.payment.transactionId    = ""
                                              state.payment.paymentReference = ""
										      
                                              return state;

    case ActionType.SET_PAYMENT:              state.payment.description      = action.newDescription
                                              state.payment.amount           = action.newAmount
                                              state.payment.date             = action.newDate
                                              state.payment.meansOfPayment   = action.newMeansOfPayment
                                              state.payment.status           = action.newStatus
                                              state.payment.transactionId    = action.newTransactionId
                                              state.payment.paymentReference = action.newPaymentReference
										      
                                              return state;

    default:                                  return state;
   }
 }


export default PaymentManagmentReducer;
