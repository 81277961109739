import { Action     , 
         ActionType ,
		 Cart       ,
		 Item       }  from './actionTypes';


interface State 
 {
  cart: Cart;
 }

const initialState = 
 {
  cart: 
   {
    email:      "",
	purchaseId: 0,
	lastUpdate: null,
    items:      []  
   } 
 }

const CartManagmentReducer = (state: State = initialState, action: Action):State => 
 {
  switch(action.type) 
   {
    case ActionType.INIT_CART:                state.cart.email      = ""
										      state.cart.lastUpdate = null
										      state.cart.items      = []
										      
                                              return state;

    case ActionType.SET_CART:                 state.cart            = action.newCart
										      
                                              return state;
											  
    case ActionType.SET_EMAIL:                state.cart.email      = action.newEmail
										      state.cart.lastUpdate = new Date ()
										      
                                              return state;

    case ActionType.ADD_ITEM:                 const newItem: Item = {
	                                                productCode:        action.newProductCode ,
                                                    productVariantCode: action.newProductVariantCode ,
						                            priceCode:          action.newPriceCode ,
                                                    quantity:           action.newQuantity 
	                                            }
	
	                                          state.cart.items      = [...state.cart.items, newItem ]
										      state.cart.lastUpdate = new Date ()
										      
                                              return state;

    case ActionType.REMOVE_ITEM:              state.cart.items = state.cart.items.filter (
	
	                                              item => !( item.productCode        === action.productCode && 
												             item.productVariantCode === action.productVariantCode )
      
	                                          );
											  
                                              state.cart.lastUpdate = new Date();
      
	                                          return state;

    case ActionType.CHANGE_QUANTITY:          state.cart.items = state.cart.items.map ( 
	
	                                              item => item.productCode        === action.productCode && 
												          item.productVariantCode === action.productVariantCode
          
		                                                  ? { ...item, quantity: action.newQuantity }
                                                          : item
       
	                                          );
      
	                                          state.cart.lastUpdate = new Date();
      
	                                          return state;
											  
											  
    case ActionType.CHANGE_PRICE:             state.cart.items = state.cart.items.map (
	
	                                              item => item.productCode        === action.productCode && 
												          item.productVariantCode === action.productVariantCode
          
		                                                  ? { ...item, priceId: action.newPriceCode }
                                                          : item
                                              );
											  
                                              state.cart.lastUpdate = new Date();
      
	                                          return state;
   /*
    case ActionType.INIT_CATALOG:
      return {
        ...state,
        catalog: { ...state.catalog, categories: [] }
      };

    case ActionType.SET_CATALOG:
      return {
        ...state,
        catalog: action.newCatalog
      };
	*/								 
									 
    default:                              return state;
   }
 }


export default CartManagmentReducer;