import   React                ,  
       { useState             ,
	     useEffect            }  from 'react';

import { useNavigate          }  from 'react-router-dom';

import { Dispatch             }  from 'redux';
import { useDispatch          }  from 'react-redux';

import { format               }  from 'date-fns';
import { formatInTimeZone     }  from 'date-fns-tz'

import { LongText             }  from '../../../../widget/long_text/long_text';
import { Field                }  from '../../../../widget/field/field';
import { TextArea             }  from '../../../../widget/textarea/textarea';
import { Select               }  from '../../../../widget/select/select';
import { DateSelector         }  from '../../../../widget/date_selector/date_selector';
import { WidgetFrame          }  from '../../../../widget/widget_frame/widget_frame';
import { WidgetFrameLabel     }  from '../../../../widget/widget_frame/widget_frame_label';
import { Toggle               }  from '../../../../widget/toggle/toggle';
import { Window               }  from '../../../../widget/window/window';


import   classNames              from 'classnames';

import   styles                  from './hotline_question_details.module.scss';

import { Question             }  from '../../../../../datas/hotlineQuestionManagment/actionTypes';

import { useTypedSelector     }  from '../../../../../datas/useTypeSelector';
 
import { WWW_URLS             }  from '../../../../../network/urls';
 
import { postEvent            }  from '../../../../../network/api_events_stats';

import { patchHotlineQuestion }  from '../../../../../network/api_hotline';

export interface HotlineQuestionDetailsProps {
  question:           Question;
  hideDetails:        () => void;
  updateQuestionList: () => void;
}

export const HotlineQuestionDetails = ({ question           ,
                                         hideDetails        ,
										 updateQuestionList }: HotlineQuestionDetailsProps) => {

  const navigate  = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );

  const [ editMode , setEditMode ] = useState (false)

  const [ title              , setTitle              ] = useState (question.title);
  const [ device             , setDevice             ] = useState (question.device);
  const [ sentence           , setSentence           ] = useState (question.question);
  
  const [ messageTitle       , setMessageTitle       ] = useState ('');
  const [ messageDevice      , setMessageDevice      ] = useState ('');
  const [ messageQuestion    , setMessageQuestion    ] = useState ('');

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setTitle    ( e.target.value ) 
	
	if ( e.target.value === "" )
	      {
	       setMessageTitle ( "Vous devez saisir un titre" );
	      }
	 else {
	       setMessageTitle ( "" );
	      }
	
  };

  const handleDeviceChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setDevice    ( e.target.value ) 
  };
  
  const handleSentenceChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {

    setSentence    ( e.target.value ) 
	
	if ( e.target.value === "" )
	      {
	       setMessageQuestion ( "Vous devez saisir une question" );
	      }
	 else {
	       setMessageQuestion ( "" );
	      }
	
  };


  const handleCancelbutton = () => {
  
     setEditMode ( false )
  }

  const handleValidbutton = () => {
  
    let test = true
  
  	if ( title === "" )
	      {
	       setMessageTitle ( "Vous devez saisir un titre" );
		   test = false
  	      }
	 else {
	       setMessageTitle ( "" );
	      }

	if ( sentence === "" )
	      {
	       setMessageQuestion ( "Vous devez saisir une question" );
		   test = false
	      }
	 else {
	       setMessageQuestion ( "" );
	      }

    if ( test )
	 {
	   makeUpdate ()
	 }

  }

  const handleModifybutton = () => {
  
     setEditMode ( true )
  }

  const handleClosebutton = () => {
  
    hideDetails ();
  }
  
  
  const makeUpdate = async () => {
  
    console.log ("update question ", question.id)
  
	const response = await patchHotlineQuestion ( authentication.token ,
	                                              question.id          ,
	                                              title                ,
				 			                      device               ,
				  			                      sentence             )
	
	if ( response.code === 1)
          {	
	       updateQuestionList ();
		   hideDetails ();
          }
	 else {
	 
	      }
  }

 
  return (
            
    <div className    = { classNames(styles.root)} >

      <button
        style={{
          position:   'absolute',
          top:        '10px',
          right:      '10px',
          zIndex:     50, // Une valeur supérieure à celle de l'image
          background: 'transparent',
          border:     'none',
          cursor:     'pointer',
		  color:      'white'
        }}
        onClick={handleClosebutton}
      >
        <img src="https://lovelightfactory.fr/images/icons/close.png"
		     alt="croix" />
      </button>


				
	   <div className = { classNames(styles.grid)} >
			
	      <div className = { classNames(styles.grid_line_1)} >

			Question posée le { formatInTimeZone(question.submissionDate, 'Europe/Paris', 'dd/MM/yyyy à HH:mm:ss') }
				  
          </div>
			 
	      <div className = { classNames(styles.grid_line_2)} >

			<WidgetFrame name          = "Titre de la question :"
                         classNameGrid = {classNames(styles.grid_info)} >

                {
                  (
				    editMode
				  )
				  ?
				  (
                    <Field  type          = "text"
                            placeholder   = ""
						    value         = {title}
                            onChange      = {handleTitleChange} />
				  )
				  :
				  (
				    <>{question.title}</>
				  )
                }
				  
		    </WidgetFrame>
				  
          </div>

	      <div className = { classNames(styles.grid_line_3)} >

			<WidgetFrame name          = "Materiel concerné :"
                         classNameGrid = {classNames(styles.grid_info)} >

                {
                  (
				    editMode
				  )
				  ?
				  (
                    <Field  type          = "text"
                            placeholder   = ""
						    value         = {device}
                            onChange      = {handleDeviceChange} />
				  )
				  :
				  (
				    <>{question.device}</>
				  )
                }

		    </WidgetFrame>
				  
          </div>

	      <div className = { classNames(styles.grid_line_4)} >

			<WidgetFrame name          = "Question :"
                         classNameGrid = {classNames(styles.grid_info)} >

                {
                  (
				    editMode
				  )
				  ?
				  (
                    <TextArea value    = {sentence}
                              onChange = {handleSentenceChange} />
				  )
				  :
				  (
				    <>{question.question}</>
				  )
                }
				  
		    </WidgetFrame>
				  
          </div>

	      <div className = { classNames(styles.grid_line_5)} >

            { 
			  !editMode
			  
			  &&
			   
			  (
			    <>
				
                  {
                    (
				      question.status === "New"
				    )
				    ?
				    (
				      <>En cours de traitement</>
				    )
				    :
				    (
			          <>Traitée le { formatInTimeZone(question.responseDate as Date, 'Europe/Paris', 'dd/MM/yyyy à HH:mm:ss') } </>
				    )
                  }
				  
				</>
			  
			  )
            }
			  
          </div>

	      <div className = { classNames(styles.grid_line_6)} >

            { 
			  !editMode
			  
			  &&
			   
			  (
			    <>
                  {
                    (
				      question.status === "New"
				    )
				    ?
				    (
				      <></>
				    )
				    :
				    (
			          <>
					
					    <WidgetFrame name          = "Réponse :"
                                     classNameGrid = {classNames(styles.grid_info)} >

                           {question.response}
				  
  		                </WidgetFrame>
					
					  </>
				    )
                  }
			    </>
			  )
            }
				  
          </div>

	      <div className = { classNames(styles.grid_line_7)} >

            {
              (
	  	        editMode
			  )
			  ?
			  (
			    <>
			      <div className={classNames(styles.buttons)}>

                    <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                            onClick   = {handleCancelbutton}>
                      Annuler
                    </button>

					&nbsp;&nbsp; 
			 
                    <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                            onClick   = {handleValidbutton}>
                      Valider
                    </button>
			 
                  </div>				
				</>
			  )
			  :
			  (
			    <>
			      <div className={classNames(styles.buttons)}>

                    {
                      (
				         question.status === "New"
				      )
				      ?
				      (
				        <>
						
                          <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                  onClick   = {handleModifybutton}>
                            Modifier
                          </button>

					      &nbsp;&nbsp; 
					
						</>
				      )
				      :
				      (
			            <></>
				      )
                    }
			 
                    <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                            onClick   = {handleClosebutton}>
                      Fermer
                    </button>
			 
                  </div>
				</>
			  )
			}
				  
          </div>
			  
       </div>
			 
    </div>
           
   );
};


export default HotlineQuestionDetails;
