export enum ActionType
 {
  SET_UAINFOS                  = 'SET_UAINFOS'                  ,
  SET_COOKIESCONSENT           = 'SET_COOKIESCONSENT'           ,
  SET_ALLCOOKIESCONSENT        = 'SET_ALLCOOKIESCONSENT'        ,
  SET_FUNCTIONALCOOKIESCONSENT = 'SET_FUNCTIONALCOOKIESCONSENT' ,
  SET_ANALYSISCOOKIESCONSENT   = 'SET_ANALYSISCOOKIESCONSENT'   ,
  SET_MARKETINGCOOKIESCONSENT  = 'SET_MARKETINGCOOKIESCONSENT'  ,
  SET_LASTVERSIONDATE          = 'SET_LASTVERSIONDATE'
 }

export interface Client
 {
  userAgentInformations:    string,
  allCookiesConsent:        number,
  functionalCookiesConsent: number,
  analysisCookiesConsent:   number,
  marketingCookiesConsent:  number,
  lastVersionDate:          Date | null
 }

export interface actionSetUserAgentInformations
 {
  type:                        ActionType.SET_UAINFOS;
  newUserAgentInformations:    string;
 }

export interface actionSetCookiesConsent
 {
  type:                        ActionType.SET_COOKIESCONSENT;
  newAllCookiesConsent:        number;
  newFunctionalCookiesConsent: number;
  newAnalysisCookiesConsent:   number;
  newMarketingCookiesConsent:  number;
 }

export interface actionSetAllCookiesConsent
 {
  type:                        ActionType.SET_ALLCOOKIESCONSENT;
  newAllCookiesConsent:        number;
 }

export interface actionSetFunctionalCookiesConsent
 {
  type:                        ActionType.SET_FUNCTIONALCOOKIESCONSENT;
  newFunctionalCookiesConsent: number;
 }

export interface actionSetAnalysisCookiesConsent
 {
  type:                        ActionType.SET_ANALYSISCOOKIESCONSENT;
  newAnalysisCookiesConsent:   number;
 }

export interface actionSetMarketingCookiesConsent
 {
  type:                        ActionType.SET_MARKETINGCOOKIESCONSENT;
  newMarketingCookiesConsent:  number;
 }

export interface actionSetLastVersionDate
 {
  type:                        ActionType.SET_LASTVERSIONDATE;
  newLastVersionDate:          Date;
 }

export type Action = actionSetUserAgentInformations    |
                     actionSetCookiesConsent           |
                     actionSetAllCookiesConsent        |
					 actionSetFunctionalCookiesConsent |
					 actionSetAnalysisCookiesConsent   |
					 actionSetMarketingCookiesConsent  |
					 actionSetLastVersionDate;

export type DispatchTypeSetUserAgentInformations    = ( args: actionSetUserAgentInformations    ) => actionSetUserAgentInformations
export type DispatchTypeSetCookiesConsent           = ( args: actionSetCookiesConsent           ) => actionSetCookiesConsent
export type DispatchTypeSetAllCookiesConsent        = ( args: actionSetAllCookiesConsent        ) => actionSetAllCookiesConsent
export type DispatchTypeSetFunctionalCookiesConsent = ( args: actionSetFunctionalCookiesConsent ) => actionSetFunctionalCookiesConsent
export type DispatchTypeSetAnalysisCookiesConsent   = ( args: actionSetAnalysisCookiesConsent   ) => actionSetAnalysisCookiesConsent
export type DispatchTypeSetMarketingCookiesConsent  = ( args: actionSetMarketingCookiesConsent  ) => actionSetMarketingCookiesConsent
export type DispatchTypeSetLastVersionDate          = ( args: actionSetLastVersionDate          ) => actionSetLastVersionDate
