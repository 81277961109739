import   React       ,
       { useState    }  from 'react';

import { Dispatch                    }  from 'redux';
import { useDispatch                 }  from 'react-redux';

import { useNavigate }  from 'react-router-dom';
		 
import { Video       }  from '../../../../../datas/videoManagment/actionTypes';

import { setCurrentlyPlayingVideo }  from '../../../../../datas/videoManagment/actionCreators';
 import { useTypedSelector               }  from '../../../../../datas/useTypeSelector';


import { WindowVote  }  from './window_vote';

import   classNames     from 'classnames';

import   styles         from './video_zoom.module.scss';

import { WWW_URLS    }  from '../../../../../network/urls';
import { isTokenValid }  from '../../../../../network/utils'; 

import { postEvent   }  from '../../../../../network/api_events_stats';


// Définir le type pour les propriétés CSS personnalisées
interface CustomCSSProperties extends React.CSSProperties {
  '--topThumbnail': string;
  '--leftThumbnail': string;
  '--widthThumbnail': string;
  '--heightThumbnail': string;
  '--topDiv': string;
  '--leftDiv': string;
  '--widthDiv': string;
  '--heightDiv': string;
}

interface VideoZoomProps {
  
  video:           Video  ,
  widthThumbnail:  number ,
  heightThumbnail: number ,
  topThumbnail:    number ,
  leftThumbnail:   number ,
  hideZoom:        ()=>void,
  showSheet:       (video: Video) => void,
  showAlertWindow: () => void
  alertWindowVisible: boolean
  showAlertWindowDeprecatedToken: () => void;
  
}

export const VideoZoom = ( {  video           ,
                              widthThumbnail  ,
                              heightThumbnail ,
                              topThumbnail    ,
                              leftThumbnail   ,
							  hideZoom        ,
							  showSheet       ,
							  showAlertWindow ,
							  alertWindowVisible ,
							  showAlertWindowDeprecatedToken }: VideoZoomProps ) => {

  const navigate  = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();

  const { authentication } = useTypedSelector((state) => state.authenticationManagment );

  const urlImage  = `${WWW_URLS.Thumbnail}${video.imageFile}_BIG.webp`;

  const coeffW    = 1.3;
  const coeffH    = 1.3;
  const topDiv    = topThumbnail  - heightThumbnail * ( coeffH / 2.0 - 0.5 );
  var   leftDiv   = leftThumbnail - widthThumbnail  * ( coeffW / 2.0 - 0.5 );
  const heightDiv = heightThumbnail * coeffH;
  const widthDiv  = widthThumbnail  * coeffW;

  var   leftSlickTrack  = 58;
  var   rightSlickTrack = window.innerWidth - 58 - 13;
  
  const divSlickTrack = document.querySelector('.slick-slider');

  if (divSlickTrack) 
   {
    const rect = divSlickTrack.getBoundingClientRect();

    leftSlickTrack  = rect.left + 10;                   // On ajoute le padding pour que ça paraisse aligné 
    rightSlickTrack = leftSlickTrack + rect.width - 15; // On retire le padding pour que ça paraisse aligné 
   }

  leftDiv = ( leftDiv            < leftSlickTrack  ? leftSlickTrack             : leftDiv );
  leftDiv = ( leftDiv + widthDiv > rightSlickTrack ? rightSlickTrack - widthDiv : leftDiv );

  const [ showWindowVote , setShowWindowVote ] = useState ( false );
  const [ showPart2      , setShowPart2      ] = useState ( false ); // Nouvel état pour contrôler l'affichage de part_2


  const handleAnimationEnd = () => {
    setShowPart2(true); // Active l'affichage de part_2 après l'animation de zoom
  };

  const handleMouseLeave = () => {
  
    if ( !alertWindowVisible )
	 {
      setShowWindowVote (false);
      hideZoom ()
	 }
  };

  const handleClick = () => {
  
    if ( !alertWindowVisible )
	 {
      if ( !showWindowVote )
	   {
        showSheet (video);
	   }
	 }
  };

  const handleReadButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  
    let deprecatedToken: boolean = false;
  
  	if ( authentication.login !== "" )
	 {
	  if ( isTokenValid ( authentication.token ) === false )
	   {
	    deprecatedToken = true
	    showAlertWindowDeprecatedToken ()
	   }
     }
  
    if ( !deprecatedToken )
	 {
      const eventMessage: string = `Replay-VideoZoom-Button01:\"Lecture\":${video.title}`

      postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
	
      if ( !alertWindowVisible )
	   {
        if ( !showWindowVote )
	     {
	      if ( video.locked === false )
	            {
	             dispatch ( setCurrentlyPlayingVideo ( video ) );

                 navigate ("/video")
	            }
	       else {
	             showAlertWindow ()
	            }
	     }
       }
      event.stopPropagation();
	 }  
  };

  const handleSaveButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  
    if ( !alertWindowVisible )
	 {
	 }
	 
    event.stopPropagation();
  };

  const handlePlusButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  
    let deprecatedToken: boolean = false;
  
  	if ( authentication.login !== "" )
	 {
	  if ( isTokenValid ( authentication.token ) === false )
	   {
	    deprecatedToken = true
	    showAlertWindowDeprecatedToken ()
	   }
     }
  
    if ( !deprecatedToken )
	 {
      const eventMessage: string = `Replay-VideoZoom-Button02:\"Vote\":${video.title}`

      postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

      if ( !alertWindowVisible )
	   { 
        if ( !showWindowVote )
	     {
  	      setShowWindowVote ( true );
	     }
	   } 
	 
      event.stopPropagation();
	 }
  };

  const hideWindowsVote = () => {
  
   setShowWindowVote ( false );
  }

  const handleSheetButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  
    let deprecatedToken: boolean = false;
  
  	if ( authentication.login !== "" )
	 {
	  if ( isTokenValid ( authentication.token ) === false )
	   {
	    deprecatedToken = true
	    showAlertWindowDeprecatedToken ()
	   }
     }
  
    if ( !deprecatedToken )
	 {
 	  const eventMessage: string = `Replay-VideoZoom-Button03:\"+ d'infos\":${video.title}`

      postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
  
      if ( !alertWindowVisible )
  	   {
        if ( !showWindowVote )
	     {
          showSheet (video);
	     }
	   }
	 
      event.stopPropagation();
	 }
  };
  
const customStyles: CustomCSSProperties = {
  '--topThumbnail': topThumbnail + 'px',
  '--leftThumbnail': leftThumbnail + 'px',
  '--widthThumbnail': widthThumbnail + 'px',
  '--heightThumbnail': heightThumbnail + 'px',
  '--topDiv': topDiv + 'px',
  '--leftDiv': leftDiv + 'px',
  '--widthDiv': widthDiv + 'px',
  '--heightDiv': heightDiv + 'px',
  backgroundColor: '#000000',
  zIndex:          '200',
  position:        'absolute'
};

  return (
    
    <>
	
	
	<div onClick        = { handleClick }
	     onMouseLeave   = { handleMouseLeave } 
		 className      = { classNames(styles.root,styles.animatedZoom) }
		 style          = { customStyles }  
		 onAnimationEnd = { handleAnimationEnd } >


	{
	  showWindowVote &&
	  ( <WindowVote video      = {video} 
	                top        = {229}
                    left       = {53}
                    hideWindow = {hideWindowsVote} /> )
	
	}	
	
							
      <div className = {classNames(styles.grid)} >
	  
        <div className = {classNames(styles.part_1)}>
	      <img src       = { urlImage    }
		       alt       = { video.title }
		       className = { classNames(styles.image)} />
		
 		  {
	       video.locked &&
		    (
             <img style = {{ position: 'absolute',
			                 top:      '211px' ,
                             left:     '0px' }}
			  	  src   = "https://lovelightfactory.fr/images/icons/locked.svg"
 		          alt   = "locked" />
            )
	      }
		 
        </div>

		
		
		
 	    <div className = {classNames(styles.part_2, { [styles.show]: showPart2 } )}>
          <div className = {classNames(styles.description,styles.bg_gradient)}>
        
   		    <div className = {classNames(styles.line_1)}>
		      <div className = {classNames(styles.title, styles.text)}>
			    {video.shortTitle}
			  </div>
		    </div>
			
            <div className = {classNames(styles.line_2_left)}>

               <div className = {classNames(styles.buttons)}>

                 {
	               video.locked 
				   ?
		           (
			     <button className = "px-4 py-1 rounded-full bg-[#777777] font-bold text-sm text-black tracking-widest uppercase transform border border-black"
				         onClick   = {handleReadButtonClick}>
                   ►
                 </button>
			       )
				   :
				   (
			     <button className = "px-4 py-1 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                         onClick   = {handleReadButtonClick}>
                    ►
                 </button>
				   )
				 }  
    			 
				 &nbsp;&nbsp;
			   
			     {/*
	  		     <button className = "px-4 py-1 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                         onClick   = {handleSaveButtonClick}>
                    +
                 </button>

  			       &nbsp;&nbsp;
			     */}
				 
			     <button className = "px-4 py-1 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                         onClick   = {handlePlusButtonClick}>
                    &#128077;
					
				
                 </button>

                 &nbsp;&nbsp;

<button className="px-4 py-1 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200 min-w-[100px]"
        onClick={handleSheetButtonClick}>
    + d'infos
</button>


		      </div>
			   
		    </div>
			
            <div className = {classNames(styles.line_2_middle)}>

		    </div>

            <div className = {classNames(styles.line_2_right)}>
			   <div className = {classNames(styles.duration, styles.text)}>
			     {video.duration}
			   </div>
		    </div>
			
 	      </div>
 	    </div>
      </div>
    </div>
	
	</>
 
  );
};

export default VideoZoom;
