import   axios                         from 'axios';

import { format                     ,
         parse                      }  from 'date-fns';
		 
import { Video                      ,
         HighlightedVideo           }  from '../datas/videoManagment/actionTypes';

import { getUserAgentInformations   ,
         getAppInformations         }  from './utils';	   
		 
import { API_URLS                   }  from './urls';


export interface HighlightedVideoList {

  code:              number,
  comment:           string,
  highlightedVideos: HighlightedVideo[]
}

export const getHighlightedVideoList = async () : Promise<HighlightedVideoList>  => {

	const url  =  `${API_URLS.getHighlightedVideoList}`;
	
	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
          'User-Agent-Informations': `${userAgentInformations}`,
	      'App-Informations': `${appInformations}`,
    };

    var result:HighlightedVideoList = {
      code:              0  ,
	  comment:           "" ,
      highlightedVideos: []
    };

    try {

      const response = await axios.get ( url, { headers: headers } );

      const statut = response.status;

      if ( statut === 200  )
             {
              result = {
                code:               1,
                comment:            "Récupération des vidéos à l'affiche",
                highlightedVideos:  []
              };
			  
			  for ( let i=0; i<response.data.length; i++ )
			   {
			    
			   
			   
			    let temp:HighlightedVideo = {
				
                    id:                       response.data[i].id              ,
                    name:                     response.data[i].name            ,
                    active:                   response.data[i].active          ,
                    creationDate:             response.data[i].creationDate    ,
                    beginningDate:            response.data[i].beginningDate   ,
                    endingDate:               response.data[i].endingDate      ,
                    adText:                   response.data[i].adText          ,
                    adImageFile:              response.data[i].adImageFile     ,
					posterImageFile:          response.data[i].posterImageFile , 
                    trailerVideo:             response.data[i].trailerVideo    ,
                    videoId:                  response.data[i].videoId
                 };
				 
				result.highlightedVideos.push ( temp )
			   }
             }
        else {
              result = {
                code:                -101,
                comment:             `Erreur lors de la récupération des vidéos à l'affiche : ${response.status}`,
                highlightedVideos:   []
              };
             }
    }
    catch (error) {

      console.log (error)

      result = {
        code:                -201,
 	    comment:             "Erreur réseau",
        highlightedVideos:   []
      };
    }

    return result;
}
