import   React            , 
       { useEffect        }  from 'react';

import   classNames          from 'classnames';

import   styles              from './mandatory_informations.module.scss';

import { LegalNoticePanel }  from './blocks/legal_notice_panel/legal_notice_panel';

import { GlobalMenu       }  from '../../menu/global/global';
import { TopShifter       }  from '../../menu/top_shifter/top_shifter';
import { CookiesManager   }  from '../../cookie/manager';
import { Footer           }  from '../../menu/footer/footer';

import { useTypedSelector }  from '../../../datas/useTypeSelector';

import { postEvent        }  from '../../../network/api_events_stats';

export const LegalNotice = () => {

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );

  useEffect(() => {

    window.scroll(0, 0);

	postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

  }, [] );


  return (
  
        <>

          <GlobalMenu />
          <CookiesManager />
          <TopShifter />

          <div className={classNames(styles.root)}>

            <div className={classNames(styles.shift)}></div>

            <div className={classNames(styles.contain)}>

              <LegalNoticePanel/>
		
            </div>
            
          </div>

          <Footer />
        
        </>
            
  );
};

export default LegalNotice;
