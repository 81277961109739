import   React                     from 'react';

import   classNames                from 'classnames';

import { TypewriterEffectSmooth }  from "../../ui/typewriter-effect";

import   styles                    from './not_found.module.scss';

import { GlobalMenu             }  from '../../menu/global/global';
import { CookiesManager         }  from '../../cookie/manager';
import { Trigger                }  from '../../widget/trigger/trigger';
import { Footer                 }  from '../../menu/footer/footer';

import { INTERNAL_LINKS         }  from '../../../network/urls';

export const NotFound = () => {

  const words = [
    {
      text: "La",
      className: "text-red-500 dark:text-red-500",
    },
    {
      text: "page,",
      className: "text-red-500 dark:text-red-500",
    },
    {
      text: "que",
      className: "text-red-500 dark:text-red-500",
    },
    {
      text: "vous",
      className: "text-red-500 dark:text-red-500",
    },
    {
      text: "recherchez,",
      className: "text-red-500 dark:text-red-500",
    },
    {
      text: "n'existe",
      className: "text-red-500 dark:text-red-500",
    },
    {
      text: "pas.",
      className: "text-red-500 dark:text-red-500",
    },
  ];

  return (
 
    <>

      <GlobalMenu />
      <CookiesManager />

  	  <div className={classNames(styles.root)}>

    	<div className={classNames(styles.contain)}>

     	  <div className={classNames(styles.message)}>
           <TypewriterEffectSmooth words={words} />
          </div>

      	  <div className={classNames(styles.message2)}>
		    <br/>
             Vous pouvez cliquer sur le bouton ci-dessous pour retourner à la page d'accueil.
		    <br/>
		    <br/>
			
		    <Trigger link            = {INTERNAL_LINKS.Home}
		             name            = "Page d'accueil"
                     classNameNormal = {classNames(styles.button)} />
		
 	      </div>

        </div>

	  </div>
 
     <Footer />

    </>
	
  );
};

export default NotFound;
