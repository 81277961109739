import { ActionType                 ,
		 Cart                       ,
		 actionInitCart             ,
		 actionSetCart              ,
		 actionSetEmail             ,
		 actionAddItem              ,
		 actionRemoveItem           ,
		 actionChangeQuantity       ,
		 actionChangePrice          ,
         DispatchTypeInitCart       ,
		 DispatchTypeSetCart        ,
		 DispatchTypeSetEmail       ,
		 DispatchTypeAddItem        ,
		 DispatchTypeRemoveItem     ,
		 DispatchTypeChangeQuantity ,
		 DispatchTypeChangePrice    }  from './actionTypes';

export const initCart = () => 
 {
  const action: actionInitCart = 
   {
    type:     ActionType.INIT_CART
   }

  return ( dispatch: DispatchTypeInitCart ) => 
   {
    dispatch (action);
   }
 } 

export const setCart = (newCart: Cart) => 
 {
  const action: actionSetCart = 
   {
    type:     ActionType.SET_CART,
	newCart:  newCart
   }

  return ( dispatch: DispatchTypeSetCart ) => 
   {
    dispatch (action);
   }
 } 

export const setEmail = ( newEmail: string ) => 
 {
  const action: actionSetEmail = 
   {
    type:       ActionType.SET_EMAIL,
	newEmail:   newEmail
   }

  return ( dispatch: DispatchTypeSetEmail ) => 
   {
    dispatch (action);
   }
 } 

export const addItem = ( newProductCode:        string ,
                         newProductVariantCode: string ,
						 newPriceCode:          string ,
                         newQuantity:           number ) => 
 {
  const action: actionAddItem = 
   {
    type:                  ActionType.ADD_ITEM,
    newProductCode:        newProductCode,
    newProductVariantCode: newProductVariantCode,
	newPriceCode:          newPriceCode,
    newQuantity:           newQuantity 
   }

  return ( dispatch: DispatchTypeAddItem ) => 
   {
    dispatch (action);
   }
 } 
 
export const removeItem = ( productCode:        string ,
                            productVariantCode: string ) => 
 {
  const action: actionRemoveItem = 
   {
    type:               ActionType.REMOVE_ITEM,
    productCode:        productCode,
    productVariantCode: productVariantCode 
   }

  return ( dispatch: DispatchTypeRemoveItem ) => 
   {
    dispatch (action);
   }
 } 
 
export const changeQuantity = ( productCode:        string ,
                                productVariantCode: string ,
                                newQuantity:        number ) => 
 {
  const action: actionChangeQuantity = 
   {
    type:               ActionType.CHANGE_QUANTITY,
    productCode:        productCode,
    productVariantCode: productVariantCode,
    newQuantity:        newQuantity 
   }

  return ( dispatch: DispatchTypeChangeQuantity ) => 
   {
    dispatch (action);
   }
 } 
 
export const changePrice = ( productCode:        string ,
                             productVariantCode: string ,
                             newPriceCode:       string ) => 
 {
  const action: actionChangePrice = 
   {
    type:               ActionType.CHANGE_PRICE,
    productCode:        productCode,
    productVariantCode: productVariantCode,
    newPriceCode:         newPriceCode 
   }

  return ( dispatch: DispatchTypeChangePrice ) => 
   {
    dispatch (action);
   }
 } 
 
