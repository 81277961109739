import { Action     , 
         ActionType , 
		 Client     }  from './actionTypes';

interface State 
 {
  client: Client;
 }

const initialState = 
 {
  client: 
   {
	userAgentInformations:    "",
    allCookiesConsent:        -100,
    functionalCookiesConsent: -100,
    analysisCookiesConsent:   -100,
    marketingCookiesConsent:  -100, 
    lastVersionDate:          null	
   } 
 }

const ClientManagmentReducer = (state: State = initialState, action: Action):State => 
 {
  switch(action.type) 
   {
    case ActionType.SET_UAINFOS:                  state.client.userAgentInformations    = action.newUserAgentInformations

                                                  return state;
										 
    case ActionType.SET_COOKIESCONSENT:           state.client.allCookiesConsent        = action.newAllCookiesConsent
                                                  state.client.functionalCookiesConsent = action.newFunctionalCookiesConsent
												  state.client.analysisCookiesConsent   = action.newAnalysisCookiesConsent
												  state.client.marketingCookiesConsent  = action.newMarketingCookiesConsent
												  
                                                  return state;
										 
    case ActionType.SET_ALLCOOKIESCONSENT:        state.client.allCookiesConsent        = action.newAllCookiesConsent

                                                  return state;
										 
    case ActionType.SET_FUNCTIONALCOOKIESCONSENT: state.client.functionalCookiesConsent = action.newFunctionalCookiesConsent

                                                  return state;
										 
    case ActionType.SET_ANALYSISCOOKIESCONSENT:   state.client.analysisCookiesConsent   = action.newAnalysisCookiesConsent

                                                  return state;
										 
    case ActionType.SET_MARKETINGCOOKIESCONSENT:  state.client.marketingCookiesConsent  = action.newMarketingCookiesConsent

                                                  return state;
									 										 
    case ActionType.SET_LASTVERSIONDATE:          state.client.lastVersionDate          = action.newLastVersionDate

                                                  console.log ("SET_LASTVERSIONDATE")
												  console.log (action)
												  console.log (state.client)
                                                  return state;

    default:                                      return state;
   }
 }


export default ClientManagmentReducer;
