import   React            ,  
       { useEffect        }  from 'react';

import { useNavigate      }  from 'react-router-dom';

import { format                 }  from 'date-fns';
import { formatInTimeZone       }  from 'date-fns-tz'

import   classNames          from 'classnames';

import { Dispatch         }  from 'redux';
import { useDispatch      }  from 'react-redux';

import   styles              from './payment_informations.module.scss';

import { useTypedSelector }  from '../../../../../datas/useTypeSelector';

import { initUser }  from '../../../../../datas/userManagment/actionCreators';

import { INTERNAL_LINKS      }  from '../../../../../network/urls';

export const PaymentInformations = () => {

  const { user   } = useTypedSelector ( (state) => state.userManagment   );
  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

    useEffect(() => {

    if ( authentication.login === "" || 
         authentication.token === "" )
     {
	  dispatch ( initUser () )

      navigate (`${INTERNAL_LINKS.Login}`);
     }

  }, []); 

  const renderPaymentsList = (): JSX.Element => {

	console.log (user.payments)

    if  ( user.payments.length > 0 )
          {	
		   console.log ( user.payments )
		  
           return (
		            <>
			         { user.payments.map ( (payment, index) => (
               
			           <div key={index} className={classNames(styles.payments_row , payment.status === "Paid" ? styles.ok : styles.error  )}>
			   
			             <div className={classNames(styles.payments_date )} >
                          {formatInTimeZone(payment.date, 'Europe/Paris', 'dd/MM/yyyy')}
						 </div>
			             <div className={classNames(styles.payments_description )} >
						  { payment.description }
						 </div>
			             <div className={classNames(styles.payments_amount )} >
						  { ( payment.amount / 100.0 ).toFixed(2) } €
						 </div>
  			             <div className={classNames(styles.payments_means_of_payment )} >
						  { payment.meansOfPayment }
						 </div>
			             <div className={classNames(styles.payments_reference_number )} >
						  { payment.paymentReference }
						 </div>
                     
                       </div>
                 	 ))}
			        </>
				  )
	      }
     else {
           return (
		            <>
					  <div className={classNames(styles.infos)} >Aucun achat n'a encore été effectué</div>
		            </>
	              )
	      }
  }

  
  return (
            
           <div className={classNames(styles.root)}>

           {/*
               <p className={classNames(styles.title)}>
                 INFORMATIONS DE PAIEMENT
               </p>
              
			   <br/>
			  
			   <p className={classNames(styles.informations)}>
				    Mon mode de paiement :<br/>
					Pas de moyen de paiement enregistré.
			   </p>

			   <br/>
			   <hr/>
			   <br/>
               */}
			   <p className={classNames(styles.title)}>
				  LISTE DES PAIEMENTS EFFECTUES
			   </p>
			   
			   <br/>

			   <p className={classNames(styles.payments_list)}>
				 {renderPaymentsList()}
			   </p>

           </div>
           
         );
};


export default PaymentInformations;
