import   React                 , 
       { useEffect             ,
         useState              }  from 'react';

import { WindowCookiesManager  }  from './windows/window_cookies_manager';

import { checkCookiesExistence }  from '../../network/utils';

export const CookiesManager = () => {

  const [ showCookiesParameters , setCookiesParameters ] = useState ( false );
  const [ firstRender           , setFirstRender       ] = useState ( true  );

  useEffect(() => {

    if ( firstRender )
     {
      if ( document.cookie )
            {
              if ( checkCookiesExistence() )
                    {
                     setCookiesParameters (false);
                    }
               else {
                     setCookiesParameters (true);
                    }
            }
       else {
              setCookiesParameters (true);
            }

      setFirstRender (false);
     }
 
  }, [firstRender]); 
  
  const handleTriggerCookiesManagerClick = () => {
  
    setCookiesParameters ( !showCookiesParameters );
  };

  return (
   
           <>
		   
              { 
                showCookiesParameters && 
                ( <WindowCookiesManager onClickOnCloseButton = {handleTriggerCookiesManagerClick }/> )
              }

           </>
          
         );
		 
};

export default CookiesManager;

// https://www.boryl.fr/blog/google-analytics-4-cnil-rgpd/
// https://dev.to/ramonak/react-enable-google-analytics-after-a-user-grants-consent-5bg3
