import   React      , 
       { useState   , 
	     useRef     , 
		 useEffect  }  from 'react';

import   classNames    from 'classnames';

import   styles        from './carousel.module.scss';

type CarouselProps = {
  items:     JSX.Element[];
  itemWidth: number;
};

export const Carousel = ({ items     , 
                           itemWidth }: CarouselProps) => {
						   
  const [ currentIndex , setCurrentIndex ] = useState(0);
  const [ visibleItems , setVisibleItems ] = useState(1);
  const [ gap          , setGap          ] = useState(0);

  const [ touchStartX  , setTouchStartX  ] = useState<number | null>(null);
  const [ touchMoveX   , setTouchMoveX   ] = useState<number | null>(null);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
  
    const updateLayout = () => {
  
      if (wrapperRef.current) 
	   {
        let containerWidth = wrapperRef.current.parentElement?.offsetWidth || 0;
		
		if ( containerWidth > 20 ) 
		 {
		  containerWidth -= 20;   // On supprime le padding pour avoir la largeur interne
		 }
	
        const buttonWidth  = 30;
        const wrapperWidth = containerWidth - 2 * buttonWidth;
        const itemsToShow  = Math.max(1, Math.floor(wrapperWidth / itemWidth));

        let calculatedGap  = (wrapperWidth - itemsToShow * itemWidth) / itemsToShow;

		console.log ("containerWidth=",containerWidth)
		console.log ("buttonWidth=",buttonWidth)
		console.log ("wrapperWidth=",wrapperWidth)
		console.log ("itemWidth=",itemWidth)
		console.log ("itemsToShow=",itemsToShow)
		console.log ("calculatedGap=",calculatedGap)

        setVisibleItems(itemsToShow);
		
        setGap (calculatedGap > 0 ? calculatedGap : 0);
       }
    };

    updateLayout();

    const resizeObserver = new ResizeObserver(updateLayout);
	
    if (wrapperRef.current) 
	 {
      resizeObserver.observe(wrapperRef.current);
     }

    return () => {
	
      if (wrapperRef.current) 
	   {
        resizeObserver.unobserve(wrapperRef.current);
       }
    };
	
  }, [itemWidth, items.length]);

  const prevSlide = () => {
  
    setCurrentIndex ( (prevIndex) => {
      
	  const newIndex = prevIndex - visibleItems;
      return newIndex < 0 ? 0 : newIndex;
    });
	
  };

  const nextSlide = () => {
  
    setCurrentIndex ( (prevIndex) => {
	
      const newIndex = prevIndex + visibleItems;
      return newIndex > items.length - visibleItems ? items.length - visibleItems : newIndex;
    });
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchMoveX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
  
    if (touchStartX !== null && touchMoveX !== null) 
	 {
      const deltaX = touchStartX - touchMoveX;
	  
           if (deltaX > 50) 
	        {
             nextSlide();
            } 
	  else if (deltaX < -50) 
	        {
             prevSlide();
            }
     }
	 
    setTouchStartX(null);
    setTouchMoveX(null);
  };

  return (
  
    <div className = {classNames(styles.carouselContainer)}>
	
      <button onClick   = {prevSlide} 
	          className = {classNames(styles.carouselBtn, styles.prev)} >
		
		<img src = "/images/icons/left.png" 
		     alt = "previous"/>
			 
	  </button>

      <div className    = {classNames(styles.carouselWrapper)}
           ref          = {wrapperRef}
           onTouchStart = {handleTouchStart}
           onTouchMove  = {handleTouchMove}
           onTouchEnd   = {handleTouchEnd} >
		   
        <div className = {classNames(styles.carouselContent)}
             style     = {{ transform: `translateX(-${(itemWidth + gap) * currentIndex}px)` ,
                            width:     `${(itemWidth + gap) * items.length}px`              }} >
          {
		   items.map ( (item, index) => (
            
			<div key       = {index}
                 className = {classNames(styles.carouselItem)}
                 style     = {{ width:       `${itemWidth}px` ,
                                marginLeft:  `${gap / 2}px`   ,
                                marginRight: `${gap / 2}px`   }} >
              {item}
            </div>
			
            )
		   )
		  }
		  
        </div>
		
      </div>

      <button onClick   = {nextSlide} 
	          className = {classNames(styles.carouselBtn, styles.next)}>
			  
		<img src = "/images/icons/right.png" 
		     alt = "next" />
		
	  </button>
	  
    </div>
  );
};

export default Carousel;
