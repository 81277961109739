import   React             ,
       { PropsWithChildren , 
         ReactNode         }  from 'react';

export interface WidgetFrameLabelProps {
  children:   ReactNode;
  className?: string;
}

export const WidgetFrameLabel = ({ children  ,
                                   className }:  PropsWithChildren<WidgetFrameLabelProps> ) => {
							  
  return (
  
           <div className={className} >
				
            {
              React.Children.map(children, (child, index) => {
        
                return <>{child}</>;
              } ) 

            }
						  
           </div>
           
         );
};

export default WidgetFrameLabel;
