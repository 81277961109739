export enum ActionType
 {
  INIT_CART            = 'INIT_CART',
  SET_CART             = 'SET_CART',
  SET_EMAIL            = 'SET_EMAIL',
  ADD_ITEM             = 'ADD_ITEM',
  REMOVE_ITEM          = 'REMOVE_ITEM',
  CHANGE_QUANTITY      = 'CHANGE_QUANTITY',
  CHANGE_PRICE         = 'CHANGE_PRICE',
 }

export interface Item
 {
  productCode:        string ,
  productVariantCode: string ,
  priceCode:          string ,
  quantity:           number 
 }

export interface Cart
 {
  email:       string ,
  purchaseId:  number,
  lastUpdate?: Date|null,
  items:       Item[]
 }

export interface actionInitCart
 {
  type:       ActionType.INIT_CART
 }

export interface actionSetCart
 {
  type:       ActionType.SET_CART,
  newCart:    Cart
 }

export interface actionSetEmail
 {
  type:       ActionType.SET_EMAIL,
  newEmail:   string
 }

export interface actionAddItem
 {
  type:                  ActionType.ADD_ITEM,
  newProductCode:        string,
  newProductVariantCode: string,
  newPriceCode:          string,
  newQuantity:           number 
 }

export interface actionRemoveItem
 {
  type:                  ActionType.REMOVE_ITEM,
  productCode:           string,
  productVariantCode:    string 
 }

export interface actionChangeQuantity
 {
  type:               ActionType.CHANGE_QUANTITY,
  productCode:        string,
  productVariantCode: string,
  newQuantity:        number 
 }

export interface actionChangePrice
 {
  type:               ActionType.CHANGE_PRICE,
  productCode:        string,
  productVariantCode: string,
  newPriceCode:       string 
 }

export type Action = actionInitCart       |
                     actionSetCart        |
                     actionSetEmail       |
					 actionAddItem        |
					 actionRemoveItem     |
					 actionChangeQuantity |
					 actionChangePrice;

export type DispatchTypeInitCart       = ( args: actionInitCart       ) => actionInitCart
export type DispatchTypeSetCart        = ( args: actionSetCart        ) => actionSetCart
export type DispatchTypeSetEmail       = ( args: actionSetEmail       ) => actionSetEmail
export type DispatchTypeAddItem        = ( args: actionAddItem        ) => actionAddItem
export type DispatchTypeRemoveItem     = ( args: actionRemoveItem     ) => actionRemoveItem
export type DispatchTypeChangeQuantity = ( args: actionChangeQuantity ) => actionChangeQuantity
export type DispatchTypeChangePrice    = ( args: actionChangePrice    ) => actionChangePrice
					 
