import   React              ,
       { useEffect          ,
         useState           }  from 'react';

import { Link               ,
         useNavigate        }  from 'react-router-dom';

import   classNames            from 'classnames';

import   styles                from './part_03.module.scss';

import { InfiniteMovingCards }  from "../../../../ui/infinite-moving-cards";

import { useTypedSelector   }  from '../../../../../datas/useTypeSelector';

import { postEvent          }  from '../../../../../network/api_events_stats';

import { INTERNAL_LINKS     }  from '../../../../../network/urls';

export const Part03 = () => {

const testimonials = [
  {
    quote:
      "Cela faisait des années que nous espérions que l’un de nos médias traditionnels se lance dans la production audiovisuelle, ce sera un nouveau venu : la chaîne Lovelight ! Depuis, les projets s’enchaînent, le lancement de la plateforme Lovelight et bientôt la diffusion des premiers épisodes du Lovelight Challenge. Hâte de découvrir les prochains projet d’Alex, Alban, Magali et toute l’équipe ! Merci Lovelight",
    name: "Foucauld Prové",
    title: "SIGMA France",
  },
  {
    quote:
      "Je suis honoré d'avoir eu l'opportunité d'intervenir sur Lovelight en tant que représentant Lumix pour présenter les nouveaux boitiers que nous avons lancé ces derniers mois.Le contenu de Lovelight offre une véritable inspiration pour les passionnés de photographie comme moi. J'apprécie particulièrement la façon dont vous parvenez à combiner expertise technique et créativité artistique au travers de vos différentes émissions.",
    name: "Laurent Carcenat",
    title: "LUMIX",
  },
  {
    quote:
      "Ce fut pour moi un réel plaisir de participer aux débuts de Lovelight. C'était une très belle expérience qui m'a permis de mettre en lumière mon travail et mon début de carrière en tant que photographe professionnelle. Encore merci à toute l'équipe pour le super accueil et la bienveillance.",
    name: "Laurène Valroff",
    title: "Photographe",
  },
  {
    quote:
      "Toujours souriants et bienveillants, Alex et Alban se posent, vulgarisent et accompagnent les fidèles de Lovelight à mieux comprendre le processus de création d’une image. Via cette chaine, il ont su créer une nouvelle agora ou professionnels, experts, passionnés et autres curieux se croisent pour faire un bout de chemin ensemble. Dans cette déferlante technologique, ils donnent la parole aux fabricants, mon cœur de métier et raison de ma présence à leurs côtés.",
    name: "Roland SERBIELLE",
    title: "Nikon France",
  },
  {
    quote: "Lovelight, c'est la nouvelle chaine qui manquait pour aborder la photo dans  tous ses aspects, à la fois techniques et artistiques. Ceux qui animent cette chaine sont eux-mêmes des passionnés et ça se sent. Ils appuient toutes leurs émissions sur d'autres acteurs passionnés, faisant la synthèse du paysage photographique français actuel et qui nous laisse vraiment la parole. En plus d'être techniquement hyper au point, ils sont adorables.... !",
    name: "Sébastien Salamand dit Le Turk",
	title: "Artiste - Photographe",
  },
 {
    quote:
      "J’apprécie beaucoup le ton et la ligne éditorial de Lovelight, Alexandre et Alban abordent les sujets avec une grande expertise tout en les rendant accessibles à tous les niveaux de connaissances. La qualité de la production est incroyable, elle est digne des plus belles chaines de la télévision. Je recommande à tous ceux qui s’intéressent à la photographie de la regarder, c’est vraiment passionnant. Bravo à toute l’équipe Lovelight.",
	name: "Jacky CARRE",
    title: "Canon France",
  },
  {
    quote:
      "J'ai eu la chance d'assister à la naissance de Lovelight ! Au-delà de participer aux émissions et à l'aventure Lovelight, je suis aussi un de ses spectateurs. On y découvre des photographes et leur travail, des tutos pointus, des challenges, du matériel... Lovelight vient occuper une place essentielle dans le monde de la photographie francophone. En embrassant autant de sujets, Lovelight devient le média audiovisuel de la photographie. Alors tout simplement merci !",name: "Ilan DEHE",
	title: "Artisant Photographe",
  },
];



   return (

            <div className={classNames(styles.part3)}>

            <div className = { classNames ( styles.testimonials ) } >

	      <div className="h-[32rem] rounded-md flex flex-col antialiased bg-black dark:bg-black dark:bg-grid-black/[0.05] items-center justify-center relative">
            <InfiniteMovingCards items     = {testimonials}
                                 direction = "right"
                                 speed     = "slow"        />
          </div>

        </div>

            </div>

          );
};

export default Part03;

