import   React                                  , 
       { useEffect                              , 
	     useState                               , 
		 useRef                                 } from 'react';
		 
import { useNavigate                            } from 'react-router-dom';
import { useDispatch                            } from 'react-redux';

import   Hls                                      from 'hls.js';

import   classNames                               from 'classnames';

import   screenfull                               from 'screenfull';

import { format                                 } from 'date-fns';

import   styles                                   from './viewer.module.scss';

import { getCatalogInfos                        ,
         CatalogInfos                           ,
         BroadcastingChannelPackageInfos        ,
         getBroadcastingChannelPackageInfos     } from '../../../network/api_video';

import { setCatalog                             ,
         setBroadcastingChannelPackage          ,
         setCurrentlyPlayingBroadcastingChannel }  from '../../../datas/videoManagment/actionCreators';

import { Video ,
         BroadcastedVideo,
		 BroadcastingChannel,
		 BroadcastingChannelPackage,
		 CurrentlyPlayingBroadcastingChannel    }  from '../../../datas/videoManagment/actionTypes';
		 
import { useTypedSelector                       }  from '../../../datas/useTypeSelector';

import { Layer                                  }  from './layer';

import { postEvent                              }  from '../../../network/api_events_stats';

import { PlaylistViewer                         }  from './playlist_viewer';

import { Window                                 } from '../../widget/window/window';

import { WWW_URLS                               , 
         INTERNAL_LINKS                         }  from '../../../network/urls';

export const Broadcasting = () => {

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
	const { user   } = useTypedSelector ( (state) => state.userManagment   );

	const [ showPlayer      , setShowPlayer      ] = useState ( false );
	const [ showTitleBanner , setShowTitleBanner ] = useState ( false );
	const [ showPlaylist    , setShowPlaylist    ] = useState ( false );
	const [ showButtons     , setShowButtons     ] = useState ( false );
    const [ showStartSymbol , setShowStartSymbol ] = useState ( false );
    const [ showAlertWindow , setShowAlertWindow ] = useState ( false );
	
	let heartBeatCount: number = 0
	
    const startingDateRef = useRef<string>("");

    const videoRef = useRef<HTMLVideoElement | null>(null);

    const timeoutId_Buttons           = useRef<number | null>(null);
	
	const timeoutId_testConnection    = useRef<number | null>(null);
    const timeoutId_scheduleHeartBeat = useRef<number | null>(null);
/*	
const timeoutId_testConnection = useRef<ReturnType<typeof setTimeout> | null>(null);
const timeoutId_scheduleHeartBeat = useRef<ReturnType<typeof setTimeout> | null>(null);
*/
    //////////////////////////////////////////////////////////////////////////////////////////

    const [ viewerState , setViewerState ] = useState ( 0 );

    // 0  : état initial ( pas d'informations de diffusion )
    // 1  : informations sur le canal disponibles	
    // 2  : informations sur la première émission à diffuser déterminées

    //////////////////////////////////////////////////////////////////////////////////////////
	
  	// Information sur le canal diffusé (pour afficher le programme, récupérer les émissions avec leur date de début et leur durée

    var emptyChannel: BroadcastingChannel = {
        name:              "",
        broadcastedVideos: []
    };

    const [ channel , setChannel ] = useState ( emptyChannel )
	
	const showTitleOfCurrentVideo = (): JSX.Element => {	
		
		let indexOfCurrentVideo: number = -1;
		
		const currentDate:Date   = new Date();
        const currentTime:number = currentDate.getTime() / 1000

		for (let v = 0; v < channel.broadcastedVideos.length; v++) 
		 {
		  const broadcastDate_start =                       channel.broadcastedVideos[v].broadcastDate.getTime() / 1000;
          const broadcastDate_end   = broadcastDate_start + channel.broadcastedVideos[v].video.computedDuration;
		  
		  if ( currentTime < broadcastDate_end )   // Pas encore passé
		   {
		    if ( broadcastDate_start < currentTime ) // Déjà démarré
		          {
                   indexOfCurrentVideo = v;

			       break; // On a trouvé donc on s'arrête
			      }
			 else {
                   indexOfCurrentVideo = -1;
				        
				   break; // On a trouvé donc on s'arrête
				  }				  
		   }
		 }
		   
		if ( indexOfCurrentVideo !== -1 )
              {
			   console.log ( channel.broadcastedVideos[indexOfCurrentVideo].video.title )
			  
			   if ( channel.broadcastedVideos[indexOfCurrentVideo].video.title !== 'Jingle 2024' )
			         {
					  return ( 
					          <>
							   <div className={classNames(styles.title_banner)}>
								Vous regardez : <span className={classNames(styles.title_italic)}>{channel.broadcastedVideos[indexOfCurrentVideo].video.title}</span>
							   </div>
							  </>
						     )
					 }
			    else {
			          return <></>;
				     }
			  }
         else {
		       return <></>;
		      }
    }

    //////////////////////////////////////////////////////////////////////////////////////////

	
	/*************************************************************************************************************/
	
	const log = () => {
		console.log ( "----------------------------------------------------------------" );
		console.log ( "viewerState         : " , viewerState           );
		console.log ( "channel             : " , channel               );
		
		console.log ( "showPlayer          : " , ( showPlayer      ? "oui" : "non" ) );
		console.log ( "showTitleBanner     : " , ( showTitleBanner ? "oui" : "non" ) );
		console.log ( "showPlaylist        : " , ( showPlaylist    ? "oui" : "non" ) );
		console.log ( "showButtons         : " , ( showButtons     ? "oui" : "non" ) );
		console.log ( "showStartSymbol     : " , ( showStartSymbol ? "oui" : "non" ) );
		console.log ( "showAlertWindow     : " , ( showAlertWindow ? "oui" : "non" ) );
    }
	
    const initChannel = async () => {
	
	    try 
		 {
          var broadcastingChannelPackageInfos = await getBroadcastingChannelPackageInfos( ( authentication.login !== ""   && 
		                                                                                    authentication.token !== "" ) &&
                                                                                          ( user.status === "Premium" ) );

          console.log ("initChannel 1 ", broadcastingChannelPackageInfos)

          if (broadcastingChannelPackageInfos !== null) 
		        {
                 if (broadcastingChannelPackageInfos.broadcastingChannelPackage !== null) 
			           {
			            setChannel ( broadcastingChannelPackageInfos.broadcastingChannelPackage.channels[0] );

		                setViewerState ( viewerState + 1 )
						
                        console.log ("initChannel 2")
                       }
			      else {
                        console.log("broadcastingChannelPackage is null");
                       }
                } 
		   else {
                 console.log("broadcastingChannelPackageInfos is null");
                }
         } 
		catch (error) 
		 {
          console.error("Error in initChannel: ", error);
         }
    };
	
	const determineElementsToRender = () => {
	
					  // On affiche immédiatement le player avec la bannière pendant 10s
					  
		              setShowPlayer      ( true );
			          setShowStartSymbol ( true );
			          setShowButtons     ( true );
			          setShowTitleBanner ( true );
					  
                      if ( timeoutId_Buttons.current !== null ) 
					   {
                        clearTimeout ( timeoutId_Buttons.current );
                       }

                      timeoutId_Buttons.current = window.setTimeout(() => {
      
	                    setShowButtons     (false);
			            setShowTitleBanner (false);
                      }, 15000);
			  
		setViewerState ( viewerState + 1 )
		
		          console.log ("determineElementsToRender")

	}	

	const loadStream = () => {

	    const src: string = `${WWW_URLS.Live}`;

		          console.log ("loadStream", src)

		if ( videoRef.current ) 
		 {
	      if (Hls.isSupported()) 
		     {
		      const hls = new Hls ( { maxLiveSyncPlaybackRate: 1.0, } );

		      hls.on (Hls.Events.ERROR, (evt, data) => {
			     
			      if ( data.fatal ) 
				   {
				    hls.destroy();

				    if (data.details === 'manifestIncompatibleCodecsError') 
				          {
					       console.log ('stream makes use of codecs which are incompatible with this browser or operative system');
				          } 
				    else if (data.response && data.response.code === 404) 
				          {
					       console.log ('stream not found, retrying in some seconds');
				          } 
				    else  {
				       	   console.log (data.error + ', retrying in some seconds');
				       	   console.log (data);
				          }

			 	    setTimeout(() => loadStream(), 2000);
			       }
		      } );

				 
		      hls.on (Hls.Events.MEDIA_ATTACHED, () => {
			
			      hls.loadSource( src );
		      } );
			  
              hls.on ( Hls.Events.MANIFEST_PARSED, () => {
          
		          setViewerState ( viewerState + 1 )
			  } );
			  
              hls.attachMedia ( videoRef.current );
			  
	         } 
		else {
		      console.log ("A TRAITER")
		      /*
			  
		      if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) 
			   {

  		        fetch ('index.m3u8')
			
			      .then( () => {
				
				      videoRef.current.src = 'index.m3u8';
				      videoRef.current.play();
			      } );
	           }
			   */
			 }
	  }
    };

    const testConnection = () => {

	   if ( authentication.login === "" || authentication.token === "" )
	    {
 		 timeoutId_testConnection.current = window.setTimeout(() => {

		    handleMessageShowing ();

            }, 150000);

		}
   }

    const scheduleHeartBeat = () => {

		timeoutId_scheduleHeartBeat.current = window.setTimeout(() => {

            heartBeatCount++

            const eventMessage: string = `Broadcasting:\"Visionnage du live\":${startingDateRef.current}:${heartBeatCount}`

            postEvent ( "__HEART_BEAT_LIVE__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

            scheduleHeartBeat ();

        }, 60000);
		
    }

    useEffect(() => {

        // Cleanup function to clear timeouts on component unmount
        return () => {
            if (timeoutId_testConnection.current !== null) {
                clearTimeout (timeoutId_testConnection.current); 
            }
            if (timeoutId_scheduleHeartBeat.current !== null) {
                clearTimeout (timeoutId_scheduleHeartBeat.current);
            }
        };
    }, []);


    useEffect(() => {
	
	    console.log ("useEffect ",viewerState,showPlayer)
	
        const fetchData = async () => {
        
		    switch (viewerState) {
            
			    case 0 : //log();
				
				         postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login )

                         await initChannel();  
                         break;
						 
                case 1 : //log();
						 determineElementsToRender();
                         break;

                case 2 : //log();
				         loadStream();
						 testConnection ();
                         scheduleHeartBeat ();
                         break;
						 
			    default: //log();
				         break;
						 
            }
			
        };

        fetchData();
        
		
    }, [viewerState, showPlayer]);

    const switchToFullScreen = () => {
	
	    //console.log (" ====> Execution de switchToFullScreen")

        if (screenfull.isEnabled) 
		      {
               const element = document.querySelector('#viewer');
        
		       if (element) 
				     {
                      screenfull.request(element)
					   .then  ( () => { console.log('fullscreen mode accepted'); }, () => {console.log('fullscreen mode refused');} )
					   .catch ( (err) => { console.error('Error attempting to enable fullscreen mode:', err); });
                     } 
		        else {
                      console.error('Element #viewer not found');
                     }
              } 
	     else {
               console.log('Mode fullscreen automatique non autorisé');
              }
	}

    const playVideo = () => {
    
	    //console.log (" ====> Execution de playVideo")

	    if ( videoRef.current ) 
		 {
          videoRef.current.addEventListener ('ended', handleVideoEnded);

          videoRef.current.play()
             .then  ( ()      => {}, () => {})
             .catch ( (error) => {});
         }
    }

    const stopVideo = () => {
    
	    //console.log(" ====> Execution de stopVideo");

        if (videoRef.current) 
		 {
          videoRef.current.pause();
          videoRef.current.removeEventListener('ended', handleVideoEnded);
          videoRef.current.src = "";
         }

        setShowPlayer(false);
        setShowStartSymbol(false);
    };
    //////////////////////////////////////////////////////////////////////////////////////////

    /// Automatic actions

	const handleError = ( errorMessage: string ) => {
  
      console.log ( errorMessage );
    }
  
    const handleMessageShowing = () => {

	   setShowAlertWindow (true);
	   // Stopper la lecture du flux
	   stopVideo ();
	   
    }
	
	const handleVideoEnded = () => {

       // Remettre la Mire
	   // Envoyer un evénement
    }

    /// User actions

    const handleMouseMove = () => {

		setShowButtons     (true);
        setShowTitleBanner (true);
			   
        if ( timeoutId_Buttons.current !== null ) 
		 {
          clearTimeout ( timeoutId_Buttons.current );
         }

        timeoutId_Buttons.current = window.setTimeout(() => {
      
	        setShowButtons     (false);
			setShowTitleBanner (false);
        }, 15000);
    };

    const handleBackButtonClick = () => {

       // const eventMessage: string = `Broadcasting-Bouton01:\"Retour\":${startingDateRef.current}:${heartBeatCount}`

       // postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

        const eventMessage2: string = `${startingDateRef.current}:${heartBeatCount}`

        postEvent ( "__STOP_LIVE__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage2 , authentication.login )

		navigate(-1);
    };

    const handlePlaylistButtonClick = () => {

        const eventMessage: string = `Broadcasting-Bouton02:\"Voir le programme\":${startingDateRef.current}:${heartBeatCount}`

        postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

		setShowPlaylist ( true );
    };

    const handlePlaylistCloseButtonClick = () => {

		setShowPlaylist ( false );
    };

    const handleAlertWindowClosingWithValidButton = () => {

		setShowAlertWindow ( false );

		navigate ( `${INTERNAL_LINKS.Login}` )
    }

    const handleClickOnStartButton = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    
	    heartBeatCount = 0
		
		const startingDateStr = format ( new Date (), 'yyyy-MM-dd HH-mm-ss')

        startingDateRef.current = startingDateStr;  // Mettre à jour la référence

	
       // const eventMessage: string = `Broadcasting-Bouton03:\"Démarrer le live\":${startingDateStr}:${heartBeatCount}`

       // postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

        const eventMessage2: string = `${startingDateStr}:${heartBeatCount}`

        postEvent ( "__START_LIVE__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage2 , authentication.login )


		event.stopPropagation();
		
        switchToFullScreen ();
        setShowStartSymbol ( false );
		
	/*	setShowWaitingMessage ( true );

		const timeoutId_WaitingMessage = setTimeout(() => {

			setShowWaitingMessage ( false );

        }, 10000 );*/


        playVideo();
    }
	
    return (
    
		<div id="viewer" className   = {classNames(styles.root, ( !showButtons ? styles.hide_cursor : "" ) )} 
		                 onMouseMove = {handleMouseMove} >
		
		    {
			  showButtons &&
			  (
			   <>
			    <div className={classNames(styles.back_button)}>
				    <button onClick={handleBackButtonClick}>Retour</button>
			    </div>
			
			    <div className={classNames(styles.playlist_button)}>
				    <button onClick={handlePlaylistButtonClick}>Voir le programme</button>
			    </div>
               </>
			  )
			}
			
		    {/*
			  showWaitingMessage &&
			  (
			   <>
			    <div className={classNames(styles.waiting_message)}>
				    Veuillez patienter, votre programme va démarrer dans quelques instants
			    </div>
               </>
			  )
			*/}

			{
			  showPlaylist &&
			  (channel != null ) &&
			  (
			   <PlaylistViewer channel     = {channel} 
			                   closeButton = {handlePlaylistCloseButtonClick} />
			  )
			}

			{
	          showAlertWindow &&
	          ( < Window message         = { 
			                                 <>
											  <span className = {classNames(styles.bold_text)}>
											   Vous devez être connecté pour accéder à ce direct.
											  </span>
											  <br/>
											 </>
										   }
                   messageType           = "normal"
                   onClickOnValidButton  = {handleAlertWindowClosingWithValidButton}
                   validButtonName       = "Se connecter"   /> )
	        }
			
			{ showTitleBanner && ( showTitleOfCurrentVideo () ) }

			{
			  showPlayer 
			  ?
			  (	 
				  <div className={classNames(styles.player)}>

					  
					  
                      <div className={classNames(showStartSymbol ? styles.glass_1 : styles.glass_2)} onClick={handleClickOnStartButton}>
                          {
						   showStartSymbol && 
						   (
                             <img src       = "/images/play_symbol.png"
                                  alt       = "play button"
                                  className = {classNames(styles.play_button)} />
                           )
						  }
                      </div>
                        
                      <video ref={videoRef} />
						
                  </div>
			  )
			  :
			  (
				<img src       = "/images/mireLLTV.png" 
				     alt       = "Mire LLTV"
					 className = { classNames(styles.tv_test_pattern) }             />	 
			  )
			}

		</div>
  
	);
};

export default Broadcasting;

