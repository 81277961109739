import   React                            , 
       { useState                         ,
	     useEffect                        }  from 'react';
import   classNames                          from 'classnames';
import { useLocation                      ,
         useNavigate                      }  from 'react-router-dom';

import   styles                              from './account_managment.module.scss';

import { GlobalMenu                       }  from '../../menu/global/global';
import { CookiesManager                   }  from '../../cookie/manager';
import { TopShifter                       }  from '../../menu/top_shifter/top_shifter';
import { Footer                           }  from '../../menu/footer/footer';

import { PersonalInformations             }  from './blocs/personal_informations/personal_informations';
import { ConnectionInformations           }  from './blocs/connection_informations/connection_informations';
import { SubscriptionsAndServices         }  from './blocs/subscriptions_and_services/subscriptions_and_services';
import { PaymentInformations              }  from './blocs/payment_informations/payment_informations';
import { AccountClosure                   }  from './blocs/account_closure/account_closure';
import { SubscriptionTermination          }  from './blocs/subscription_termination/subscription_termination';
import { Hotline                          }  from './blocs/hotline/hotline';

import { LeafletCookiesInformation        }  from '../mandatory_informations/blocks/leaflet_cookies_information/leaflet_cookies_information';
import { LeafletPersonnalDatasInformation }  from '../mandatory_informations/blocks/leaflet_personnal_datas_information/leaflet_personnal_datas_information';
import { LeafletTermsOfService            }  from '../mandatory_informations/blocks/leaflet_terms_of_service/leaflet_terms_of_service';
import { LegalNoticePanel                 }  from '../mandatory_informations/blocks/legal_notice_panel/legal_notice_panel';

import { Tab                              }  from '../../widget/tab/tab';
import { Panel                            }  from '../../widget/tab/panel';
 
import { useTypedSelector                 }  from '../../../datas/useTypeSelector';
 
// import { checkScreenSize                  }  from '../../../utils/screen_size_tester';
 
import { postEvent                   }  from '../../../network/api_events_stats';
 
import { INTERNAL_LINKS                         }  from '../../../network/urls';
 
export const AccountManagment = () => {

  const location = useLocation();
  const navigate = useNavigate();
  
  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { user           } = useTypedSelector ((state) => state.userManagment );

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  const switchWidthLimit = 870;

  const handleResize = () => {
    setIsSmallScreen ( window.innerWidth <= switchWidthLimit );
  };

  useEffect(() => {

  }, [isSmallScreen]); 

  useEffect(() => {

	postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

    window.scroll(0, 0);

    if ( authentication.login === "" || 
         authentication.token === "" )
          {
           navigate (`${INTERNAL_LINKS.Login}`);
          }
	 else {
           window.addEventListener('resize', handleResize);

           handleResize();

           return () => {
             window.removeEventListener('resize', handleResize);
           };
          }
	
  }, []); 

/*  
  const handlePopState = (event: PopStateEvent) => {
	
      if ( authentication.login === "" || 
           authentication.token === "" )
       {
        navigate (`${INTERNAL_LINKS.Login}`);
       }
    };
*/

  const renderSmallSizeComponent = (): JSX.Element => {
 
    console.log ("Render small")
 
    const identifiant = location.hash.substring(1);
 
         if ( identifiant === "mon-compte" )
		  {
	       return <div className={classNames(styles.frame)}>
		  			<ConnectionInformations/>
					<br/>
					<hr/>
					<br/>
				    <PersonalInformations/>
					<br/>
					<hr/>
					<br/>
					<SubscriptionTermination/>
					<br/>
					<hr/>
					<br/>
					<AccountClosure/>
	              </div>
		  
		  }
		  
    else if ( identifiant === "abonnements-et-services" )
	      {
	       return <div className={classNames(styles.frame)}>
		   		   	<SubscriptionsAndServices/>
	              </div>
		  }
		  
    else if ( identifiant === "informations-de-paiement" )
	      {
	       return <div className={classNames(styles.frame)}>
				   <PaymentInformations/>
	              </div>
		  
		  }
		  
    else if ( ( user.status === "Standard" || user.status === "Premium" ) && ( identifiant === "hotline" ) )
	      {
	       return <div className={classNames(styles.frame)}>
		            <Hotline />
	              </div>
		  }
		
    else  {
	       return <div className={classNames(styles.frame)}>
		  			<ConnectionInformations/>
					<br/>
					<hr/>
					<br/>
				    <PersonalInformations/>
					<br/>
					<hr/>
					<br/>
					<SubscriptionTermination/>
					<br/>
					<hr/>
					<br/>
					<AccountClosure/>
	              </div> 
	      }
	
 

  }
  
  /*
  
  Si on met, entre <Tab ...> et </Tab>
  
    {
	 ( user.status === "Standard" || user.status === "Premium" )
	 ??
	 (
	   <Panel name="Hotline">
		<Hotline/>
	   </Panel>
	 )
	}

  ça plante
  
  TypeError: Cannot read properties of undefined (reading 'name')
    at https://custom-front.preprod.lovelightfactory.fr/static/js/main.e5225722.js:2:821993
    at il (https://custom-front.preprod.lovelightfactory.fr/static/js/main.e5225722.js:2:101516)
    at Sc (https://custom-front.preprod.lovelightfactory.fr/static/js/main.e5225722.js:2:121743)
    at https://custom-front.preprod.lovelightfactory.fr/static/js/main.e5225722.js:2:118333
    at w (https://custom-front.preprod.lovelightfactory.fr/static/js/main.e5225722.js:2:264749)
    at MessagePort.L (https://custom-front.preprod.lovelightfactory.fr/static/js/main.e5225722.js:2:265283)


   Si on met
            
	{
	  ( user.status === "Standard" || user.status === "Premium" )
	  ?
	  (
	   <Panel name="Hotline">
		<Hotline/>
	   </Panel>
	  )
	  :
	  (
	   <>
	   </>
	  )
	}

   ça crée un bouton "vide" qui pointe sur un panel "vide"
     
   => pour le moment, on créé deux version de tab
  */   
  
  
  const renderBigSizeComponent = (): JSX.Element => {
  
    const identifiant = location.hash.substring(1);
    let   activeChoiceNumber = 0
 
         if ( identifiant === "mon-compte"              ) { activeChoiceNumber = 0 }
    else if ( identifiant === "abonnements-et-services" ) { activeChoiceNumber = 1 }
    else if ( identifiant === "informations-de-paiement" ) { activeChoiceNumber = 2 }
	else if ( ( user.status === "Standard" || user.status === "Premium" ) && ( identifiant === "hotline" ) ) { activeChoiceNumber = 3 }
	else { activeChoiceNumber = 0 }
    
  
    if ( user.status === "Standard" || user.status === "Premium" )
	      {
           return   <>
             
			          <br/>

			          <Tab classNameNormalButton = { classNames(styles.trigger_normal) }
			               classNamePushedButton = { classNames(styles.trigger_pushed) } 
						   activeChoiceNumber    = { activeChoiceNumber } >
					
				        <Panel name="Mon compte">
				          <>
					        <ConnectionInformations/>
					        <br/>
					        <hr/>
					        <br/>
				            <PersonalInformations/>
					        <br/>
					        <hr/>
					        <br/>

					        {
					          ( user.status === "Standard" || user.status === "Premium" )
					          &&
					          ( user.nextStatus !== "Standard" )
					          &&
					          (
					           <>
					             <SubscriptionTermination/>
					             <br/>
					             <hr/>
					             <br/>
					           </>
					          )
					        }

					        <AccountClosure/>
				          </>
				        </Panel>
				  
				        <Panel name="Abonnements et services">
				          <SubscriptionsAndServices/>
				        </Panel>
				  
				        <Panel name="Informations de paiement">
				          <PaymentInformations/>
				        </Panel>

			            <Panel name="Hotline">
				          <Hotline/>
				        </Panel>

				        <Panel name="Mentions légales">
				          <LegalNoticePanel/>
				        </Panel>

				        <Panel name="CGU">
				          <LeafletTermsOfService/>
				        </Panel>

				        <Panel name="Confidentialité">
				          <LeafletPersonnalDatasInformation/>
				        </Panel>
				  
				        <Panel name="Cookies">
				          <LeafletCookiesInformation/>
				        </Panel>

			          </Tab>
			
                    </>			
          }
	 else {
           return   <>
             
			          <br/>

			          <Tab classNameNormalButton = { classNames(styles.trigger_normal) }
			               classNamePushedButton = { classNames(styles.trigger_pushed) }
						   activeChoiceNumber    = { activeChoiceNumber } >
					
				        <Panel name="Mon compte">
				          <>
					        <ConnectionInformations/>
					        <br/>
					        <hr/>
					        <br/>
				            <PersonalInformations/>
					        <br/>
					        <hr/>
					        <br/>

					        {
					          ( user.status === "Standard" || user.status === "Premium" )
					          &&
					          ( user.nextStatus !== "Standard" )
					          &&
					          (
					           <>
					             <SubscriptionTermination/>
					             <br/>
					             <hr/>
					             <br/>
					           </>
					          )
					        }

					        <AccountClosure/>
				          </>
				        </Panel>
				  
				        <Panel name="Abonnements et services">
				          <SubscriptionsAndServices/>
				        </Panel>
				  
				        <Panel name="Informations de paiement">
				          <PaymentInformations/>
				        </Panel>

				        <Panel name="Mentions légales">
				          <LegalNoticePanel/>
				        </Panel>

				        <Panel name="CGU">
				          <LeafletTermsOfService/>
				        </Panel>

				        <Panel name="Confidentialité">
				          <LeafletPersonnalDatasInformation/>
				        </Panel>
				  
				        <Panel name="Cookies">
				          <LeafletCookiesInformation/>
				        </Panel>

			          </Tab>
			
                    </>			
          }

  }


  return (

        <>
		  
          <CookiesManager />
          <GlobalMenu />
          <TopShifter />

          <div className={classNames(styles.root)}>

            <div className={classNames(styles.contain)}>

            { 
			  ( isSmallScreen ? renderSmallSizeComponent() : renderBigSizeComponent () ) 
			}

            </div>
            
          </div>

          <Footer />
        
        </>

           
         );


};

export default AccountManagment;
