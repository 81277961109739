import   React            ,
       { useState         }  from "react";

import { Link             }  from 'react-router-dom';

import   classNames          from 'classnames';

import   styles              from './footer.module.scss';

import { WindowCookiesManager                   }  from '../../cookie/windows/window_cookies_manager';

export const Footer = () => {

  const [ showCookiesParameters , setCookiesParameters ] = useState(false);

  const handleOpenCookiesManagerClick = () => {

    setCookiesParameters( true );
  };

  const handleCloseCookiesManagerClick = () => {

    setCookiesParameters( false );
  };

  return (

              <div className={classNames(styles.root)}>

         {
           showCookiesParameters &&

           ( <WindowCookiesManager onClickOnCloseButton = {handleCloseCookiesManagerClick }/> )
         }

              <div className={classNames(styles.footer)}>

               <div className={classNames(styles.position_1_1)}>

               {/*
                <Link to="#">
                    <button className={classNames(styles.button)}>FAQ</button>
                </Link>

                <br/>
               */}
                <Link to="/mentions-legales">
                    <button className={classNames(styles.button)}>Mentions légales</button>
                </Link>

               </div>

               <div className={classNames(styles.position_1_2)}>

                <Link to="/conditions-generales-d-utilisation">
                    <button className={classNames(styles.button)}>Conditions générales d'utilisation</button>
                </Link>

               </div>

               <div className={classNames(styles.position_1_3)}>

                 <button className = {classNames(styles.button)}
				         onClick   = {handleOpenCookiesManagerClick} >Préférence de cookies</button>


                <br/>

                <Link to="/notice-d-information-sur-les-donnees-personnelles">
                    <button className={classNames(styles.button)}>Confidentialité</button>
                </Link>

               </div>

               <div className={classNames(styles.position_1_4)}>

                <Link to="/notice-d-information-sur-les-cookies">
                    <button className={classNames(styles.button)}>Cookies</button>
                </Link>

               </div>

              </div>
              </div>


		 );
};

export default Footer;
