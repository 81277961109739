import { Action     , 
         ActionType ,
		 Question   }  from './actionTypes';


interface State 
 {
  questions: Question [];
 }

const initialState = 
 {
  questions:  []
 }

const HotlineQuestionManagmentReducer = (state: State = initialState, action: Action):State => 
 {
  switch(action.type) 
   {
    case ActionType.INIT_QUESTIONS:       state.questions      = []
										      
                                          return state;

    case ActionType.SET_QUESTIONS:        state.questions      = action.newQuestions
										      
                                          return state;
											  

    case ActionType.ADD_QUESTION:             /*
	                                          const newItem: Item = {
	                                                productCode:        action.newProductCode ,
                                                    productVariantCode: action.newProductVariantCode ,
						                            priceCode:          action.newPriceCode ,
                                                    quantity:           action.newQuantity 
	                                            }
	                                          */
											  
	                                      state.questions      = [...state.questions, action.newQuestion ]
										      
                                          return state;

									 
    default:                              return state;
   }
 }


export default HotlineQuestionManagmentReducer;